import React, { Component } from "react";
import { projectEdit, getSingleProjectA, userProfile } from "../../apidata/api";
import "../../assets/smalloader.css";
import axios from "axios";
import moment from "moment";

import Leftsidebar from "../SidesBars/Leftsidebar";

export default class ProjectEditMonetize extends Component {
  constructor() {
    super();
    this.state = {
      project: null,
      topic: "",
      department: "",
      school: "",
      year: "",
      summary: "",
      date: "",
      id: "",
      projectdoc: "",
      date: "",
      views: "",
      uploadby: "",
      userId: "",
      projectUserId: "",
      downloads: "",
      status: "",
      software: "",
      softUrl: "",
      price: "",
      isLoading: false,
      info: "",
      projectapproved: "",
      // userinfor
      subject: "",
      message: "",
      username: "",
      email: "",
    };
    this.handleStatus = this.handleStatus.bind(this);
    this.handleSoftware = this.handleSoftware.bind(this);
    this.handlePrice = this.handlePrice.bind(this);
    this.handleSoftwareUrl = this.handleSoftwareUrl.bind(this);
    this.updateHandleSummary = this.updateHandleSummary.bind(this);
    this.UpdateProject = this.UpdateProject.bind(this);
    this.handleRequestProjectverification = this.handleRequestProjectverification.bind(
      this
    );
  }

  async componentDidUpdate() {}
  async componentDidMount() {
    const token = await JSON.parse(localStorage.getItem("token"));
    if (token) {
      //call a function in api file
      const project = await getSingleProjectA(this.props.match.params.id);

      this.setState({
        topic: project.topic.toUpperCase(),
        school: project.school.toUpperCase(),
        department: project.department.toUpperCase(),
        year: project.year,
        id: project._id,
        projectdoc: project.file,
        summary: project.summary,
        uploadby: project.uploadedby,
        projectUserId: project.userId,
        views: project.views,
        downloads: project.downloads.length,
        status: project.status,
        price: project.price,
        projectapproved: project.projectapproved,
      });
    }
    // const project= await this.getSingleProject(this.props.match.params.id)
    // this.setState({project})
    // console.log(this.state.project + 'ok')
    // }
    else {
      //alert('You need to login to view project Details')
      this.props.history.push("/signin");
    }
  }

  handleStatus(e) {
    this.setState({ status: e.target.value });

    console.log(this.state.status);
  }
  handleSoftware(e) {
    this.setState({ software: e.target.value });
  }
  handleSoftwareUrl(e) {
    this.setState({ softUrl: e.target.value });
  }
  async handlePrice(e) {
    this.setState({ price: Number(e.target.value) });
    console.log(this.state.price);
  }
  updateHandleSummary(e) {
    this.setState({ summary: e.target.value });
  }

  async UpdateProject(e) {
    e.preventDefault();
    // if(this.state.status=='paid'&&this.state.software == "yes" && this.state.softUrl !== "" ) {
    //   this.setState({ price: 4000 });
    // } else if (this.state.status=='paid'){
    //   this.setState({ price: 2000 });
    // }
    if (this.state.status === "" || this.state.price === "") {
      this.setState({ info: "select project  status and set project price" });
    } else {
      this.setState({ isLoading: true });

      try {
        let id = this.state.id;
        const updateproject = {
          status: this.state.status,
          software: this.state.software,
          softUrl: this.state.softUrl,
          price: this.state.price,
          summary: this.state.summary,
          projectapproved: "pending",
        };
        axios
          .put(`/update/project/${id}`, updateproject)

          .then((response) => {
            console.log(response);
            this.setState({ info: response.data.message });
            this.setState({ isLoading: false });
            // setTimeout(()=>{
            //   this.props.history.push('/userdash')
            // },1000)
            this.componentDidMount();
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            this.setState({ info: err.message });
            console.log(err);
          });
      } catch (error) {
        this.setState({ isLoading: false });
        return error.message;
      }
    }
  }

  //projectverification
  async componentWillMount() {
    let id = await JSON.parse(localStorage.getItem("userId"));
    if (id) {
      const user = await userProfile(id);

      if (user) {
        await this.setState({
          username: user.name,
          userId: user._id,
          email: user.email,
        });
      }
    }
  }
  async handleRequestProjectverification(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    console.log("cliiiiiikkkkk");

    try {
      const { username, email, topic, id } = this.state;

      const messages = {
        username: username,
        email: email,
        subject: "REQUEST FOR PROJECT VERIFICATION",
        message: `${username} is requesting verification for the project "${topic}" with ID ${id}`,
      };
      axios
        .post(`/api/v1/contactus`, messages)

        .then((response) => {
          // this.setState({ info: response.data.message });
          this.setState({ isLoading: false });
          response.data.message === "message sent successfully"
            ? this.setState({
                info:
                  "Your request sent successfull check your project status in your profile after some minutes",
              })
            : this.setState({ info: response.data.message });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          this.setState({ info: err.message });
          console.log(err);
        });
    } catch (error) {
      this.setState({ isLoading: false });
      return error.message;
    }
  }

  render() {
    const {
      topic,
      school,
      year,
      department,
      userId,
      projectdoc,
      uploadby,
      summary,
      softUrl,
      projectUserId,
      downloads,
      status,
      software,
      price,
      projectapproved,
      info,
      isLoading,
    } = this.state;

    return (
      <div>
        <div id="sideBarHome">
          <Leftsidebar />
        </div>
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-3" id="hidesideBarHome">
              <Leftsidebar />
            </div>

            <div className="container col-lg-6  col-12 col-md-4 mt-5 ">
              <div className="card  " id="addproject">
                <h3 className="card-header   text-center"> Project Details</h3>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    Title:<strong>{topic}</strong>
                  </li>
                  <li>
                    {" "}
                    School:<strong>{school}</strong>
                  </li>
                  <li>
                    Department:<strong>{department}</strong>
                  </li>
                  <li>
                    Year: <strong>{moment(year).format("DD/MM/YY")}</strong>
                  </li>
                  <li>
                    No of Download:<strong>{downloads}</strong>
                  </li>
                  <li>
                    uploadby:<strong>{uploadby}</strong>
                  </li>
                  <li>
                    project document:{" "}
                    <a
                      href={`${projectdoc}`}
                      download="download"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download
                    </a>
                  </li>
                  <li>
                    Project Status:<strong>{status}</strong>
                  </li>
                  <li>
                    Prices:
                    <strong>
                      <del>N</del>
                      {price}
                    </strong>
                  </li>
                  <li>
                    Monetize Approved :<strong>{projectapproved}</strong>
                  </li>
                </ul>
                {this.state.projectapproved !== "" ? (
                  <h5 className=" text-center">
                    {" "}
                    You project monetize status is{" "}
                    <span className="alert alert-info ">
                      {projectapproved}{" "}
                    </span>
                  </h5>
                ) : null}

                {/* <!--Form with header--> */}
                {/* {this.state.status=='paid'? null: */}
                <div className="card-body px-lg-5 pt-0  ">
                  {/* <!--Body--> */}
                  {}
                  <div className="form-group mt-3">
                    <p> Your Project Monetize Status</p>
                    <select
                      name=""
                      id=""
                      className="form-control"
                      onChange={this.handleStatus}
                      value={status}
                    >
                      {/* <option value="">Select Monetize Status</option> */}

                      <option value="request for paid">Paid</option>
                    </select>
                  </div>

                  <div className="form-group ">
                    <p>
                      Did your project has software implementation{" "}
                      <span
                        style={{ cursor: "pointer", color: "blue" }}
                        title="Did your project has software implementation? a project in computer science and other science department usually has software implementation of the project."
                      >
                        {" "}
                        &#10067;
                      </span>
                    </p>
                    <select
                      name=""
                      id=""
                      className="form-control"
                      onChange={this.handleSoftware}
                      value={software}
                    >
                      <option value="no">No</option>
                      <option value="yes">Yes</option>
                    </select>
                  </div>
                  {this.state.software == "yes" ? (
                    <div className="form-group">
                      <input
                        type="text"
                        id="form2"
                        value={softUrl}
                        onChange={this.handleSoftwareUrl}
                        className="form-control"
                        name="school"
                        placeholder="github/online drive URL/link to your project software"
                      />
                    </div>
                  ) : null}

                  <div className="form-group">
                    <p> Project Abstract and introduction</p>
                    <textarea
                      type="text"
                      id="form10"
                      className="md-textarea form-control"
                      rows="2"
                      name="summary"
                      value={summary}
                      onChange={this.updateHandleSummary}
                    />
                    {/* <label htmlFor="form10">Summary</label> */}
                  </div>
                  <div className="form-group mt-3">
                    <p> How much do you want to sell your project Work?</p>
                    <input
                      type="text"
                      className="form-control"
                      id="form2"
                      value={price}
                      onChange={this.handlePrice}
                      placeholder="name your price advice anything btw 500-20,000"
                    />
                    {/* <select
                      name=""
                      id=""
                      className="form-control"
                      onChange={this.handlePrice}
                      value={price}
                    >
                     

                      <option value="500">N500</option>
                      <option value="1000">N1000</option>
                      <option value="2000">N2000</option>
                      <option value="3000">N3000</option>
                      <option value="4000">N4000</option>
                      <option value="5000">N5000</option>
                      <option value="6000">N6000</option>
                      <option value="7000">N7000</option>
                      <option value="8000">N8000</option>
                      <option value="9000">N9000</option>
                      <option value="10000">N10000</option>
                      <option value="11000">N11000</option>
                      <option value="12000">N12000</option>
                      <option value="12000">N13000</option>
                      <option value="14000">N14000</option>
                      <option value="15000">N15000</option>
                      <option value="16000">N16000</option>
                      <option value="17000">N17000</option>
                      <option value="18000">N18000</option>
                      <option value="19000">N19000</option>
                      <option value="20000">N20000</option>
                    </select> */}
                  </div>
                  {/* {this.state.price === 1 ? (
                        <div className="form-group">
                          <input
                            type="text"
                            id="form2"
                            value={price}
                           onChange={this.handlePrice}
                            className="form-control"
                        
                            placeholder="Name how much you want to monetize your project"
                          />
                        </div>
                      ) : null} */}
                  <div className="text-center">
                    {info !== "" && (
                      <div className="alert alert-info">
                        {info} <br />
                        {info ===
                          "Your project Has not been verified you can't Monetize yet" && (
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={this.handleRequestProjectverification}
                          >
                            Request for project verification
                          </button>
                        )}
                      </div>
                    )}

                    {/* {this.state.isLoading === true ? (
                      <div className="text-center" id="loader" />
                    ) : (
                      <button
                        // id="drop"
                        type="button"
                        id="btnaddProject"
                        className="btn btn-primary btn-lg mdi mdi-cloud-upload mt-"
                        onClick={this.UpdateProject}
                      >
                        {" "}
                        &nbsp; Submit
                      </button>
                    )} */}

                    {isLoading ? (
                      <div className="text-center" id="loader" />
                    ) : info ===
                        "Your project Has not been verified you can't Monetize yet" ||
                      info ===
                        "Your request sent successfull check your project status in your profile after some minutes" ||
                      info ===
                        "Project updated and Awaiting monetize approval" ? (
                      <button
                        type="button"
                        id="btnaddProject"
                        disabled
                        className="btn btn-primary btn-lg mdi mdi-cloud-upload mt-"
                        onClick={this.UpdateProject}
                      >
                        {" "}
                        &nbsp; Submit
                      </button>
                    ) : (
                      <button
                        type="button"
                        id="btnaddProject"
                        className="btn btn-primary btn-lg mdi mdi-cloud-upload mt-"
                        onClick={this.UpdateProject}
                      >
                        {" "}
                        &nbsp; Submit
                      </button>
                    )}
                  </div>
                </div>
                {/* } */}
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>
    );
  }
}
