import React, { Component } from "react";
//import the library
import PaystackButton from "react-paystack";
// import {usePaystackPayment, PaystackButton, PaystackConsumer } from "react-paystack";
// import Fpaystack from './fpaystack'
// import Leftsidebar from "../SidesBars/Leftsidebar";
import axios from "axios";
import { userProfile, projectDownloads } from "../../apidata/api";

export default class Paystack extends Component {
  constructor() {
    super();
    this.state = {
      //     reference: (new Date()).getTime(),
      // email: "kondi@example.com",
      // amount: 20000,
      // publicKey: "pk_test_115a637c0e9f81332b1029a263115d3e92a4a201",
      text: "Paystack Button Implementation",
      key: "pk_live_098817b7578e810da7ba7337c62fb7533fd569ef",
      // "pk_test_115a637c0e9f81332b1029a263115d3e92a4a201", //PAYSTACK PUBLIC KEY
      email: "", // customer email
      amount: Number, //equals NGN100,
      name: String,
      project: String,
      user: String,
      amountpaid: Number,
      id: "",
      message: "",
      status: "",
      reference: "",
      response: "",
      redirecturl: "",
      trans: "",
      trxref: "",
      amountearned: Number,
      projectUserId: "",
      topic: "",
      projectdoc: "",
      date: Date,
    };
    this.updateBuyer = this.updateBuyer.bind(this);
    this.getPercent = this.getPercent.bind(this);
  }

  getPercent = (userp, amountpaid) => {
    return Math.floor((userp / 100) * amountpaid);
  };

  async componentDidMount() {
    //get login user and get the profile
    const userId = await JSON.parse(localStorage.getItem("userId"));
    const user = await userProfile(userId);
    this.setState({ name: user.name, user: user._id, email: user.email });
    //get project price and convert to kobo for paystack to use
    let amountp = this.props.price * 100;
    let amount = Number(amountp);
    //set the data to state
    this.setState({
      amount: amount,
      amountpaid: Number(this.props.price),
      id: this.props.id,
      project: this.props.id,
      projectUserId: this.props.projectUserId,
      topic: this.props.topic,
      projectdoc: this.props.projectdoc,
    });

    console.log(this.getPercent(80, this.state.amountpaid));
    let amountearned = this.getPercent(80, this.state.amountpaid);
    this.setState({ amountearned: amountearned });
  }

  //save user earn
  async updateUserEarn() {
    let id = this.state.projectUserId;
    let userearn = {
      earn: this.state.amountearned,
      customer: this.state.user,
    };
    await axios
      .put(`/user/earn/${id}`, userearn)
      .then((response) => {
        console.log("resEarn");
        console.log(response);

        this.setState({ info: response.data.message });
        // this.setState({ isLoading: false });
      })
      .catch((err) => {
        // this.setState({ isLoading: false });
        this.setState({ info: err.message });
        console.log(err);
      });
  }
  //update project earn
  async updateProjectEarn() {
    let id = this.state.project;
    let projectearn = {
      earn: this.state.amountearned,
      buyer: this.state.user,
    };
    await axios
      .put(`/project/earn/${id}`, projectearn)
      .then((response) => {
        console.log("resEarn");
        console.log(response);

        this.setState({ info: response.data.message });
        // this.setState({ isLoading: false });
      })
      .catch((err) => {
        // this.setState({ isLoading: false });
        this.setState({ info: err.message });
        console.log(err);
      });
  }

  //saving buyer info
  async updateBuyer({ status, message, reference, trans }) {
    let id = this.state.id;
    console.log(this.state);
    const projectbuyer = {
      name: this.state.name,
      project: this.state.project,
      user: this.state.user,
      amountpaid: this.state.amountpaid,
      status: status,
      message: message,
      reference: reference,
      trans: trans,
    };
    axios
      .post(`/buyproject/${id}`, projectbuyer)

      .then((response) => {
        console.log(response);
        this.setState({ info: response.data.message });
        // this.setState({ isLoading: false });
      })
      .catch((err) => {
        // this.setState({ isLoading: false });
        this.setState({ info: err.message });
        console.log(err);
      });
  }
  callback = (response) => {
    if (response) {
      this.updateBuyer({
        status: response.status,
        message: response.message,

        reference: response.reference,
        response: response.response,
        trans: response.trans,
        trxref: response.trxref,
      });
      this.setState({
        status: response.status,
        message: response.message,
        redirecturl: response.redirecturl,
        reference: response.reference,
        response: response.response,
        trans: response.trans,
        trxref: response.trxref,
      });
      this.updateProjectEarn();
      this.updateUserEarn();
      console.log("suceessssin updates");
    }

    console.log(response);
    console.log("suceesssss"); // card charged successfully, get reference here
  };

  close = () => {
    console.log("Payment closed");
  };

  getReference = () => {
    //you can put any unique reference implementation code here 408cvv 408 408 408 408 408 1 card
    let text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";

    for (let i = 0; i < 15; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  };
  async DownloadAfterPay() {
    const projectDownload = await projectDownloads(this.props.id);
    this.setState({ downloads: projectDownload.length });
    console.log(projectDownload);
  }

  render() {
    const { price } = this.state;
    return (
      <div className="mt- text-center container">
        {/* <div className="row"> */}
        {/* <div className="col-lg-4">
          <Leftsidebar/>

        </div> */}
        {/* <div className="col-lg-4 mt-5"> */}
        <div className="car">
          <div className="card-heade">
            {/* <h4> Project Payment Page  </h4> */}
          </div>

          <div className="card-body">
            {this.state.status === "success" ? (
              <div>
                {" "}
                <p> Payment Success Download below</p>
                <a href={`${this.state.projectdoc}`} target="_blank">
                  <button
                    className="btn btn-primary"
                    onClick={this.DownloadAfterPay.bind(this)}
                  >
                    Download Your Project
                  </button>
                </a>
              </div>
            ) : (
              <div>
                {/* paystack button */}
                <p>
                  {" "}
                  You are Paying {this.state.amountpaid} for a project Documents
                  Title {this.state.topic}
                </p>
                <PaystackButton
                  text="Proceed To Make Payment"
                  className="payButton btn btn-primary"
                  callback={this.callback}
                  close={this.close}
                  disabled={false}
                  // {/*disable payment button*/}
                  embed={false}
                  // {/* //  payment embed in your app instead of a pop up */}
                  reference={this.getReference()}
                  email={this.state.email}
                  amount={this.state.amount}
                  paystackkey={this.state.key}
                  tag="button"
                  // {/*it can be button or a or input tag
                />
              </div>
            )}
          </div>

          {this.state.status !== "" ? (
            <ul className="alert alert-info">
              {" "}
              Payment response<li>{this.state.message}</li>
              <li>{this.state.status}</li>
              <li>{this.state.reference}</li>
            </ul>
          ) : null}
          {this.state.status === "success" ? (
            <div>
              {" "}
              Thanks You You can Click the button below to download <br></br>
              <strong>
                <a href={`${this.state.projectdoc}`} target="_blank">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={this.DownloadAfterPay.bind(this)}
                  >
                    Download Your Project
                  </button>
                </a>{" "}
              </strong>
            </div>
          ) : null}
        </div>
      </div>
      // <div className="col-lg-4">

      // </div>
      // </div>
      // </div>
    );
  }
}
