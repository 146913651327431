import React, { Component } from "react";

import Projects from "./listProjects/projects";
import "../assets/style.css";

import LeftSidebar from "./SidesBars/Leftsidebar";
import RightSidebar from "./SidesBars/rightSidebar";
import Hmenu from '../Pages/listProjects/Hmenu';
import Search from "../Pages/listProjects/easysearch";
// import SearchBox from "./searchResult/searchResult";
// import SearchResult from './searchResult/searchResult'


export default class Userhome extends Component {
  constructor() {
    super();
    this.state = {
      
      id: "",
      name: "",
      userAvater: ""
    };
  }

  async componentWillMount() {
    
    // const user = await userProfile();
    // console.log(user.message + "  ookkkkk");
    // if (user.message === "success") {
    //   // if(user.user==)

    //   await this.setState({
    //     name: user.name.toLowerCase(),
    //     userAvater: user.avater
    //   });

    // setTimeout()

    const id = await window.localStorage.getItem("userId");
    if (id) {
      await this.setState({ id: id });
     
    } else {
      this.props.history.push("/");
    }
  }
  async componentDidMount() {
    const token = await JSON.parse(localStorage.getItem("token"));
    const id = await JSON.parse(localStorage.getItem("userId"));

    fetch(`/user/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(result => {
      
        this.setState({
          id: result.info._id, name:result.info.name
        });
      })
      .catch(err => console.log(err));
    //logout user after some minute
    setTimeout(() => {
      alert("Re log in");
      localStorage.clear();
    }, 36000000);
  }

  render() {
    const {  name,  } = this.state;


    return (
      <div>
       

        {/* <div className="alert alert-info">
          Welcome {name} much{" "}
          <span style={{ color: "red", fontSize: "20px" }}>&hearts;</span>
        </div> */}
        <div className="mt-5" id="sideBarHome" >
              <LeftSidebar />
            </div>
        <div className="container">
                 <Hmenu/>
          <div className="row  ">
            
           

            <div className="col-lg-9 ">
              
        
              <Projects  name= {name}/>
            </div>

            <div
              className="col-lg-3 mt-5"
              style={{
                position: "",
                left: ""
              }}
            >
              <Search  />
             
              <RightSidebar  />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
