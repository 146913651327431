import React, { Component } from 'react'
import { MDBRow, MDBCol, MDBView, MDBCard, MDBCardBody, MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import SideBarNav from '../layout/sideNavigation'
import './admin.css'
import moment from 'moment'
import {Link} from 'react-router-dom'


import { getAllproject } from '../../components/apidata/api';

export default class AllProject extends Component {
    constructor() {
        super()
        this.state = {
            allproject: []

        }
    }




    async componentWillMount() {
        const projects = await getAllproject()
   console.log(projects)
        if (projects) {
            this.setState({ allproject: projects })
        }
    }
    render() {
        return (
            <div>
                < div className="flexible-content" >
                    <SideBarNav />
                    <div id="content"
                        className="p-2" >
                        <MDBRow>
                            <MDBCol md="12">
                                <MDBCard>
                                    <MDBView className="gradient-card-header blue darken-2">
                                        <h4 className="h4-responsive text-white text-center"> AllProject</h4>
                                    </MDBView>
                                  


                                        <MDBTable scrollY maxHeight='400px'  hover className='text-dark'>
                                        <MDBTableHead color="blue lighten-4">
                                                <tr className='bg-white text-dark font-weight-bold'>
                                                    <th>S/N</th>
                                                    <th>topic</th>
                                                    <th>school</th>
                                                    <th>depart</th>
                                                    <th>uploadby</th>
                                                    <th>year</th>
                                                    <th>No od Dw</th>
                                                    <td>abstracts</td>
                                                    <td>Download link</td>
                                                    <td>action</td>

                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>

                                                { this.state.allproject ? this.state.allproject.map((project, index) => {
                                                    const { topic,
                                                        school,
                                                        year,
                                                        department,
                                                        userId,
                                                        projectdoc,
                                                        uploadedby,
                                                        summary,
                                                        _id,
                                                        downloads}=project
                                                    

                                                   
                                                    
                                                    return (
                                                      <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{topic}</td>
                                                        <td>{school}</td>
                                                        <td>{department}</td>
                                                        <td>{uploadedby}</td>
                                                        <td>
                                                          {moment(year).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </td>
                                                        <td>
                                                          {downloads.length}
                                                        </td>
                                                        <td>{summary}</td>
                                                        <td>
                                                          {" "}
                                                          <a
                                                            class="btn btn btn-white"
                                                            href={`${projectdoc}`}
                                                          >
                                                            {" "}
                                                            download
                                                          </a>
                                                        </td>
                                                        <td>
                                                          <td>
                                                            <Link
                                                              className="btn btn-sm btn-warning"
                                                              to={`/admin/view/project/${_id}`}
                                                            >
                                                              view
                                                            </Link>{" "}
                                                          </td>
                                                        </td>
                                                      </tr>
                                                    ); 
                                                  
                                                }) : <h4> No project yet</h4>
                                                }
                                            </MDBTableBody>
                                        </MDBTable>
                                    </MDBCard>
                                
                            </MDBCol>
                        </MDBRow>
                    </div>
                </div>

            </div>
        )
    }
}



