import React, { Component } from "react";
import {
  MDBRow,
  MDBCol,
  MDBView,
  MDBCard,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";
import SideBarNav from "../layout/sideNavigation";
import "./admin.css";
import moment from "moment";

import {
  AdminData,
  getRequestMonetizeProject,
} from "../../components/apidata/api";
import Axios from "axios";

export default class PendingProject extends Component {
  constructor() {
    super();
    this.state = {
      allproject: [],
      isLoading: false,
      id: "",
      reason: "",
      topic: "",
      userid: "",
      name: "",
    };
  }

  async componentDidMount() {
    const projects = await getRequestMonetizeProject();
    console.log(projects);
    if (projects) {
      this.setState({ allproject: projects });
    }
    const id = await JSON.parse(localStorage.getItem("adminuserId"));
    const token = JSON.parse(localStorage.getItem("admintoken"));
    if (token) {
      const admin = await AdminData(id);

      if (admin) {
        this.setState({
          name: admin.name,
        });
      }
    }
  }
  async handleApproved(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    const id = this.state.id;

    const approved = {
      approvedby: this.state.name,
      approved: true,
      status: "paid",
      projectapproved: "approved",
      notification: `${this.state.topic}  was  approved for monetize `,
      userid: this.state.userid,
    };

    Axios.put(`/project/approve/${id}`, approved)
      .then((response) => {
        console.log(response);
        this.setState({ info: response.data.message });
        this.setState({ isLoading: false });
        alert(response.data.message);
        this.componentDidMount();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        this.setState({ info: err.message });
        console.log(err);
      });
  }
  async handleDisApprove(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    const id = this.state.id;

    const disapproved = {
      approved: false,
      approvedby: this.state.name,
      status: "free",
      projectapproved: "decline",
      price: 0,
      notification: `${this.state.topic}  was  disapproved for monetize  due to ${this.state.reason}`,
      userid: this.state.userid,
    };
    Axios.put(`/project/disapprove/${id}`, disapproved)
      .then((response) => {
        console.log(response);
        this.setState({ info: response.data.message });
        this.setState({ isLoading: false });
        alert(response.data.message);
        this.componentDidMount();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        this.setState({ info: err.message });
        console.log(err);
      });
  }
  handleChange = (e) => {
    this.setState({ id: e.target.value });
  };
  async sendID(e) {
    let topic = document.getElementById("topic").value;
    let userid = document.getElementById("userid").value;
    this.setState({ id: e.target.value, topic: topic, userid: userid });
    console.log(this.state.id + "  id   " + this.state.topic);
  }
  async handleReason(e) {
    this.setState({ reason: e.target.value });
    console.log(this.state.reason);
  }

  render() {
    return (
      <div>
        <div className="flexible-content">
          <SideBarNav />
          <div id="content" className="p-2">
            <MDBRow>
              <MDBCol md="12">
                <MDBCard>
                  <MDBView className="gradient-card-header blue darken-2">
                    <h4 className="h4-responsive text-white text-center">
                      {" "}
                      PendingMonetizeProject
                    </h4>
                  </MDBView>

                  <MDBTable
                    scrollY
                    maxHeight="400px"
                    hover
                    className="text-dark"
                  >
                    <MDBTableHead>
                      <tr className="bg-white text-dark font-weight-bold">
                        <th>S/N</th>
                        <th>topic</th>
                        <th>school</th>
                        <th>depart</th>
                        <th>uploadby</th>
                        <th>year</th>
                        <th>No od Dw</th>
                        <td>abstracts</td>
                        <td>price</td>
                        <td>Download link</td>
                        <td>status</td>
                        <td>monetizestatus</td>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {this.state.allproject ? (
                        this.state.allproject.map((project, index) => {
                          const {
                            topic,
                            school,
                            year,
                            department,
                            userId,
                            projectdoc,
                            uploadedby,
                            summary,
                            views,
                            status,
                            projectUserId,
                            approved,
                            price,
                            downloads,
                            projectapproved,
                            _id,
                          } = project;
                          // approved === true &&
                          if (
                            projectapproved == "pending" &&
                            approved !== true
                          ) {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {" "}
                                  <input
                                    type="hidden"
                                    id="topic"
                                    value={topic}
                                  />
                                  {topic}
                                </td>
                                <td>{school}</td>
                                <td>{department}</td>
                                <td>{uploadedby}</td>
                                <td>{moment(year).format("DD/MM/YYYY")}</td>
                                <td>{downloads.length}</td>
                                <td>{summary}</td>
                                <td>{price}</td>
                                <td>
                                  {" "}
                                  <a
                                    class="btn btn btn-white"
                                    href={`${projectdoc}`}
                                  >
                                    {" "}
                                    download
                                  </a>
                                </td>
                                <td>{status}</td>
                                <td>
                                  {this.state.isLoading == true ? (
                                    <div className="text-center" id="loader" />
                                  ) : (
                                    <div>
                                      <input
                                        type="hidden"
                                        value={userId}
                                        id="userid"
                                      />
                                      <span>CLICK THE ID TWICE</span>
                                      <input
                                        className="btn btn-sm btn-primary"
                                        type="text"
                                        value={_id}
                                        onChange={this.handleChange}
                                        onClick={this.sendID.bind(this)}
                                      />
                                      <button
                                        onClick={this.handleApproved.bind(this)}
                                        className="btn btn-sm btn-primary"
                                      >
                                        {" "}
                                        approved
                                      </button>
                                      <br />

                                      <span>CLICK THE ID TWICE</span>
                                      <input
                                        className="btn btn-sm btn-primary"
                                        type="text"
                                        value={_id}
                                        onChange={this.handleChange}
                                        onClick={this.sendID.bind(this)}
                                      />
                                      <input
                                        type="text"
                                        value={this.state.reason}
                                        onChange={this.handleReason.bind(this)}
                                        placeholder="reason for dis approve"
                                      />
                                      <button
                                        className="btn btn-sm btn-danger"
                                        onClick={this.handleDisApprove.bind(
                                          this
                                        )}
                                      >
                                        {" "}
                                        Decline
                                      </button>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        })
                      ) : (
                        <h4 className="text-danger"> No Request yet</h4>
                      )}
                    </MDBTableBody>
                  </MDBTable>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </div>
        </div>
      </div>
    );
  }
}
