

import React from "react";
import ContentLoader from "react-content-loader";

const Loader = (props) => (
  <div className="container" style={{width:"80%"}}>  
  <ContentLoader
    speed={1}
    width={400}
    height={160}
    viewBox="0 0 400 180"
    backgroundColor="#4F8ACA"
    foregroundColor="#ecebeb"
    {...props}
    className="mt-5"
  >
    <rect x="6" y="8" rx="3" ry="3" width="675" height="8" />
    <rect x="6" y="86" rx="4" ry="4" width="200" height="8" />
    <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
    <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
    <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
    <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
    <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
    <circle cx="20" cy="20" r="20" />
  </ContentLoader>
  </div>

);

export default Loader;