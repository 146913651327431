import React, { Component } from "react";
import "./Addproject.css";
import "../../assets/smalloader.css";

import axios from "axios";
import Leftsidebar from "../SidesBars/Leftsidebar";

import RecentProject from "../SidesBars/RecentProject";
import NGSchool from "./schools.json";
import Depts from "./abudepartment.json";

class Addproject extends Component {
  constructor(props) {
    super();
    this.state = {
      topic: "",
      // department: "",
      // school: "",
      year: "",
      summary: "",
      file: "",
      filesupport: "",
      id: "",
      // projectdoc:'',
      fileSize: "",
      notif: "",
      isLoading: false,
      error: false,
      info: "",
      institutions: [],
      institution: "",
      Depts: [],
      dept: "",
    };
    this.handleTopic = this.handleTopic.bind(this);
    this.handleDepartment = this.handleDepartment.bind(this);
    // this.handleSchool = this.handleSchool.bind(this);
    this.handleYear = this.handleYear.bind(this);
    this.handleSummary = this.handleSummary.bind(this);

    // this.handleProjectDoc =this.handleProjectDoc.bind(this)
  }

  async componentDidMount() {
    let schools = await NGSchool;

    let Dept = await Depts;

    this.setState({ institutions: schools, Depts: Dept });
    // console.log(typeof this.state.institutions )
    const token = await localStorage.getItem("token");

    if (!token) {
      this.props.history.push("/");
    }
  }
  componentWillMount() {
    const id = localStorage.getItem("userId");
    this.setState({ id: id });
  }

  async handlePost(id) {
    this.setState({ isLoading: true });
    if (
      !this.state.topic ||
      !this.state.year ||
      !this.state.dept ||
      !this.state.institution ||
      !this.state.summary ||
      !this.state.file
    ) {
      this.setState({ info: "fill  empty field", isLoading: false });
    } else if (
      this.state.filesupport === "file type not supported" ||
      this.state.fileSize > 5
    ) {
      this.setState({
        info:
          "select only pdf, msword documment, zip or rar file not more than 5mb ",
        isLoading: false,
      });
    } else {
      const formdata = new FormData();
      formdata.append("file", this.state.file);
      formdata.append("year", this.state.year);
      formdata.append("school", this.state.institution);
      formdata.append("summary", this.state.summary);
      formdata.append("topic", this.state.topic);
      formdata.append("department", this.state.dept);

      axios
        .post(`/post/project/${id}`, formdata)
        .then((response) => {
          this.setState({ isLoading: false });
          this.setState({ info: response.data.message });
          if (response.data.message === "project added successfully") {
            this.setState({ info: "upload successful" });
            setTimeout(() => {
              this.props.history.push("/home");
            }, 1000);
          }
          console.log(response);
        })

        .catch((err) => {
          console.log(err);

          this.setState({
            isLoading: false,
            info:
              "error occured try reload ,check upload rule or your internet",
          });
        });
    }
  }
  handleProject(e) {
    e.preventDefault();
    this.handlePost(this.props.match.params.id);
  }

  handleTopic(e) {
    this.setState({ topic: e.target.value });
  }
  handleDepartment(e) {
    this.setState({ dept: e.target.value });
    console.log(this.state.dept);
  }
  // handleSchool(e) {
  //   this.setState({ school: e.target.value });
  // }
  handleYear(e) {
    this.setState({ year: e.target.value });
  }
  handleSummary(e) {
    this.setState({ summary: e.target.value });
  }
  async handleFile(e) {
    this.setState({
      file: e.target.files[0],
      fileSize: (e.target.files[0].size / 1024 / 1024).toFixed(2),
    });
    let file = e.target;
    if (/\.(docx|rar|pdf|zip|doc)$/i.test(file.files[0].name) === false) {
      await this.setState({ filesupport: "file type not supported" });
    } else {
      await this.setState({ filesupport: "file type supported" });
    }

    // function validate() {
    //   var file = form.file.value;
    //   var reg = /(.*?)\.(jpg|bmp|jpeg|png)$/;
    //   if (!file.match(reg)) {
    //     alert("Invalid File");
    //     return false;
    //   }
    // }

    // console.log(e.target.files[0]);
  }

  async handleInstitution(e) {
    this.setState({ institution: e.target.value });
  }

  render() {
    const { topic, dept, year, summary, fileSize, filesupport } = this.state;

    if (this.state.error) {
      return <h1>Something went wrong, please try again.</h1>;
    }
    return (
      <div>
        {/* <UserHeader/> */}
        <div className=" mt-5" id="sideBarHome">
          <Leftsidebar />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 mt-5" id="hidesideBarHome">
              <Leftsidebar />
            </div>
            <div className="container col-lg-5  col-12 col-md-4 mt-5 ">
              {/* <!--Form with header--> */}
              <div className="card    mt-3" id="addprojec">
                {/* <!--Header--> */}
                <div className="card-header   text-center ">
                  <h3>
                    <i className="mdi mdi-note-plus" /> Upload Project
                  </h3>
                </div>

                {this.state.notif === "empty field" ? (
                  <div className="alert alert-danger">{this.state.notif}</div>
                ) : (
                  <div
                    className="alert alert-danger"
                    style={{ display: "none" }}
                  >
                    {this.state.notif}
                  </div>
                )}

                <div className="card-body  pt-0  ">
                  {/* <!--Body--> */}
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      id="form3"
                      name="topic"
                      className="form-control"
                      value={topic}
                      onChange={this.handleTopic}
                      placeholder="Project topic"
                    />
                    {/* <label htmlFor="form3"> */}

                    {/* <i className="mdi mdi-format-title"></i> Topic</label> */}
                  </div>
                  <div className="form-group">
                    <select
                      type="text"
                      id="form2"
                      className="form-control"
                      name="department"
                      value={dept}
                      onChange={this.handleDepartment}
                      placeholder="Project Deparment"
                    >
                      <option key="no-Dpt">
                        ---Select Project Deparment---
                      </option>{" "}
                      {this.state.Depts
                        ? this.state.Depts.map((depts, index) => {
                            let { dept } = depts;
                            return (
                              <option key={index} value={dept}>
                                {dept}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                  {/* <div className="form-group">
                    <input
                      type="text"
                      id="form2"
                      className="form-control"
                      name="school"
                      value={school}
                      onChange={this.handleSchool}
                      placeholder="Institution"
                    />
                  </div>{" "} */}
                  <div className="form-group">
                    <select
                      name=""
                      id=""
                      className="form-control"
                      placeholder="select institution"
                      onChange={this.handleInstitution.bind(this)}
                      value={this.state.institution}
                    >
                      <option key="no-Uni">
                        ---Select Higher Institution---
                      </option>
                      {this.state.institutions
                        ? this.state.institutions.map((ins, index) => {
                            let { name } = ins;
                            return (
                              <>
                                {" "}
                                <option key={index} value={name}>
                                  {name}
                                </option>
                              </>
                            );
                          })
                        : null}
                    </select>
                  </div>
                  <span>Project Year</span>
                  <div className="form-group">
                    <input
                      type="date"
                      id="form3"
                      className="form-control"
                      title="Project Year"
                      value={year}
                      onChange={this.handleYear}
                      placeholder="Project Year"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      type="text"
                      id="form10"
                      className="md-textarea form-control"
                      rows="2"
                      name="summary"
                      value={summary}
                      onChange={this.handleSummary}
                      placeholder="&#x270d;Project Abstract Summary"
                    />
                    {/* <label htmlFor="form10">Summary</label> */}
                  </div>
                  <div className="form-group">
                    <p>
                      {" "}
                      Upload only .pdf .zip .docx .rar files &amp; max of 5MB
                      <br />{" "}
                      {fileSize !== "" ? (
                        <span className="alert alert-info">
                          file size= {fileSize}MB
                        </span>
                      ) : null}
                      {filesupport === "file type not supported" ? (
                        <span className="alert alert-danger">
                          {filesupport}
                        </span>
                      ) : null}{" "}
                      {filesupport === "file type supported" ? (
                        <span className="alert alert-success">
                          {filesupport}
                        </span>
                      ) : null}
                    </p>

                    <input
                      id="file"
                      type="file"
                      // accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                      className="form-control"
                      onChange={this.handleFile.bind(this)}
                    />
                  </div>
                  {filesupport === "file type not supported" &&
                  filesupport !== "" ? (
                    <div className="alert alert-warning">
                      select pdf file,msword documment, zip or rar file{" "}
                    </div>
                  ) : null}
                  {fileSize > 5.0 ? (
                    <div className="alert alert-warning">
                      file max size is 5mb{" "}
                    </div>
                  ) : null}
                  {/* <div className="md-form"> */}
                  {/* <i className="fa fa-user prefix"></i> */}
                  {/*             
            <label className="mdi mdi-file-export text-left text-muted">Upload project file</label> */}
                  {/* <input style={{display: 'inherit'}} 
            type="file" 
            className="form-control" 
            name="projectdoc"
            
            onChange={this.handleProjectDoc} 
            ref={inputFile=>this.inputFile=inputFile}/>
             <button  className="btn btn-grey" 
             onClick={()=>this.inputFile.click()}>Choose Project Document</button> */}
                  {/* <p className="help-block text-left"> upload a zip doc or pdf file</p> */}
                  {/* </div> */}
                  <div className="text-center">
                    {this.state.info !== "" ? (
                      <div className="alert alert-info">{this.state.info}</div>
                    ) : null}
                    {this.state.isLoading === true ? (
                      (document.getElementById("btnaddProject").style.display =
                        "none") && <div className="text-center" id="loader" />
                    ) : (
                      <button
                        type="button"
                        id="btnaddProject"
                        className="btn btn-primary btn-lg mdi mdi-cloud-upload mt-"
                        onClick={this.handleProject.bind(this)}
                      >
                        {" "}
                        &nbsp; Post
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className=" container col-lg-3 col-12 mt-5 sidebar-fixed ">
              <RecentProject className="mt-3" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Addproject;
