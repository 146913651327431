import React, { Component } from 'react'

import {Link} from 'react-router-dom'

export default class donation extends Component {
    render() {
      const token = localStorage.getItem('token')
        return (
          <div>
          
         
          <div className="container text-center mt-5" style={{ width: "100%" }}>
            <div>
              {" "}
              <Link
                className="navbar-brand mr-1 mt-5"
                to='#'
                style={{ fontSize: "1.2em", color: "022B69", fontWeight: "bold" }}
              >
                Suppport ProjectVersity
          </Link>
            </div>

            <div className="text-center mt-2">
              <h5>Support making project documents available for students at ease</h5>
              <div className="">
                {" "}
                <a
                  href="https://dashboard.flutterwave.com/donate/v0gb3e6ktfwf"
                  className="btn btn-primary" target='_blank'
                >
                  {" "}
                  &#36; Dollar Donation &nbsp;
                </a>{" "}
                <a
                  href="https://paystack.com/pay/projectbuk"
                  target='_blank'
                  className="btn btn-primary"
                >
                  {" "}
                  ₦ Naira Donation
                </a>
              </div>
            </div>
          </div>
           </div>
        );
    }
}
