import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getDepartmentCat } from "../../apidata/api";
import './MenuDepartment.css'
export default class MenuDepartment extends Component {
  constructor() {
    super();
    this.state = {};
  }
  // async handleDepartmentProps(props) {
  //     // let dpro = await getDepartmentCat(this.props.match.params.dp);
  //     // let depart = await this.props.match.params.dp
  //     // this.setState({ dproject: dpro, dpart: depart });
  //     // console.log(dpro + depart)
  //     await this.props.Dept()
  //     await this.props.Dept()

  // }
  render() {
    const style = {
      position: "fixed",
      zIndex: "1",
      boxShadow: "0px 2px 5px gray",
      // width:'50%'
    };
    return (
      <div className="menu" id="menu">
        <div>
          <p style={style} className=" bg-white p-2">
            <Link to="#" onClick={this.props.Acct}>
              {" "}
              Accountancy
            </Link>{" "}
            ||
            <Link to="#" onClick={this.props.Arc}>
              {" "}
             Architecture
            </Link>
            ||
            <Link onClick={this.props.Bio} to="#">
             Biological Science
            </Link>
            ||{" "}
            <Link to="#" onClick={this.props.CS}>
              Computer Science{" "}
            </Link>
            ||
            <Link to="/listdepartment" onClick={this.props.Upload}>
              More Departments Projects{" "}
            </Link>
            ||
            
          
            <span className="dropdown">
              <Link
                to="/moreprojects"
                className=" dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Projects
              </Link>
              <span
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <a className="dropdown-item" href="/moreprojects">
                  all projects
                </a>
                
              </span>
            </span>
          </p>
        </div>
      </div>
    );
  }
}
