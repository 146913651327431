import React, { Component } from "react";

import "./VisitorNavbar.css";

import { Link } from "react-router-dom";
import SearchBox from "../../../Pages/searchResult/SearchBox";

class VisitorNavbar extends Component {
  constructor() {
    super();
    this.state = {
      msg: "",
      logOut: ""
    };
    this.handleLogout = this.handleLogout.bind(this);
  }
  async handleLogout() {
    await localStorage.removeItem("token");
    await window.localStorage.clear();
    await this.setState({ usertoken: null });
  }

  async componentDidMount() {
    //   const user =await getOneUserA(this.props.match.params.id)
    //   if(user.message==='Request failed with status code 401'){
    //       this.setState({msg:user.message})
    //     alert('You have to log re-Login')
    //     this.props.history.push('/signin')
    // }
  }

  render() {
    // const token = window.localStorage.getItem("token");
    // if (token) {
    //   return <UserNav />;
    // }

    return (
      <div className="Navtext">
        <nav
          className="navbar navbar-expand-lg  navbar navbar-fixed py-0"
          style={{
            color: "#ffffff",
            backgroundColor: "#022B69",
            width: "100%",
            position: "fixed",
            zIndex: "2",
            top:"0%",
           
          }}
        >
          {/* <!-- Breadcrumb--> */}
          <Link
            className="navbar-brand mr-1"  id="brandname"
            to="/"
            
          >
            ProjectVersity&#x26d6;
          </Link>

          <button
            className="navbar-toggler bg-white"
            type="button"
            // data-toggle="collapse"
            data-toggle="dropdown"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" style={{ color: "blue" }}>
              &#9776;
            </span>
          </button>
          <div
            className="collapse navbar-collapse navfloatR"
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav ">
              {/* <nav className="navbar navbar-light justify-content-between ">

                <form className="form-inline" >
                  <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                  <button className="btn btn-outline-primary  btn-sm my-sm-0" type="submit">Search</button>
                </form>
              </nav> */}
              <SearchBox/>
              <li className="nav-item active ml-5" >
                <Link className="nav-link  " to="/aboutus">
                  About Us <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link "  to="/exploreprojects">
                  Projects
                </Link>
              </li>
             
              <li className="nav-item">
                <Link className="nav-link " to="/blogs">
                  Blog
                </Link>
              </li>
              <li className="nav-item mr-sm-1">
                <Link
                  to="/signin"
                  className=" nav-link     p-2"
                  style={{ fontWeight: "normal", textTransform: "capitalize" }}
                >
                  {" "}
                Join
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default VisitorNavbar;
