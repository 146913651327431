import React, { Component } from "react";
import { getDepartmentCat } from "../../apidata/api";
import { Link } from "react-router-dom";
import LeftSidebar from "../SidesBars/Leftsidebar";
import { MDBListGroup, MDBListGroupItem, MDBContainer } from "mdbreact";
import MenuDepartment from "./MenuDepartment";
import "./MenuDepartment.css";
import Loading from "../../Layout/Loader/Loader";

export default class ProjectDepartment extends Component {
  constructor() {
    super();
    this.state = {
      dproject: [],
      dpart: "",
      check: false,
      department: "",
      topic: "",
      cs: "",
      searchText: "",
      loading: true,
    };
  }

  async componentDidMount() {
    let dpro = await getDepartmentCat(this.props.match.params.dp);
    let depart = await this.props.match.params.dp;
    this.setState({ dproject: dpro, dpart: depart, loading: false });
  }
  async handleSearch(e) {
    this.setState({ searchText: e.target.value });
  }

  async handleDepartmentCat() {
    let depart = await this.props.match.params.dp;

    let dpro = await getDepartmentCat(depart);

    this.setState({ dproject: dpro, dpart: depart, check: true });
  }

  async handleCS() {
    let depart = await this.props.match.params.dp;
    this.setState({ cs: "computer " });
    let dpro = await getDepartmentCat(this.state.cs);

    this.setState({ dproject: dpro, dpart: depart, check: true });
  }

  async handleArc(e) {
    let depart = await this.props.match.params.dp;
    this.setState({ cs: "architecture" });
    let dpro = await getDepartmentCat(this.state.cs);
    this.setState({ dproject: dpro, dpart: depart, check: true });
  }
  async handleBio() {
    let depart = await this.props.match.params.dp;
    this.setState({ cs: "biological science" });
    let dpro = await getDepartmentCat(this.state.cs);
    this.setState({ dproject: dpro, dpart: depart, check: true });
  }
  async handleAcct() {
    let depart = await this.props.match.params.dp;
    this.setState({ cs: "accountancy" });
    let dpro = await getDepartmentCat(this.state.cs);
    this.setState({ dproject: dpro, dpart: depart, check: true });
  }

  render() {
    const dproject = this.state.dproject.filter((project) => {
      return (
        project.topic
          .toLowerCase()
          .search(this.state.searchText.toLowerCase()) !== -1 ||
        project.department
          .toLowerCase()
          .search(this.state.searchText.toLowerCase()) !== -1
      );
      //  indexOf(this.state.searchText.toLowerCase())!==-1;
    });
    const { dpart, cs } = this.state;

    const p = {
      position: "fixed",
      //  left:'800px',
      top: "8%",
      zIndex: "-1",
      //  width: '300px'
    };
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div>
        <div className="mt-5" id="sideBarHome">
          <LeftSidebar />
        </div>

        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-2" id="hidesideBarHome">
              <LeftSidebar />
            </div>
            <div className="col-lg-9 mt- container-fluid ">
              <div className="row ">
                <div className="col-lg-9 mt-2">
                  <MenuDepartment
                    Dept={this.handleDepartmentCat.bind(this)}
                    Acct={this.handleAcct.bind(this)}
                    Bio={this.handleBio.bind(this)}
                    CS={this.handleCS.bind(this)}
                    Arc={this.handleArc.bind(this)}
                  />
                </div>
                {/* search box */}
                <div className="col-lg-3 mt-5">
                  {/* <form className="form-group  "  id='searchhidmobile' >
                  <div className="form-group my-0" >
                    <input className="form-control " type="text"
                  onChange={this.handleSearch.bind(this)} value={this.state.searchText} placeholder="Search project topics" aria-label="Search" />
              </div>
            </form> */}
                </div>
              </div>
              <div>
                <form className="form-group mt-5 " id="searchhidmobile">
                  <div className="form-group my-0">
                    <input
                      className="form-control "
                      type="text"
                      onChange={this.handleSearch.bind(this)}
                      value={this.state.searchText}
                      placeholder="Search project topics"
                      aria-label="Search"
                    />
                  </div>
                </form>
              </div>

              <h6
                className="text-left mt-1  "
                id="mobiled"
                style={{
                  backgroundColor: "#2196f3",
                  color: "white",
                  width: "auto",
                }}
              >
                {cs.toLocaleUpperCase() || dpart.toLocaleUpperCase()} DEPARTMENT
              </h6>

              <div className="">
                {dproject ? (
                  dproject.map((pro, index) => {
                    let {
                      topic,
                      summary,
                      _id,
                      views,
                      downloads,
                      isVerified,
                      department,
                    } = pro;

                    return (
                      <div key={index}>
                        <MDBListGroup>
                          <MDBListGroupItem
                            id="recentprojectlist"
                            style={{
                              color: "#022b69",
                              cursor: "pointer",
                              margin: "0px",
                            }}
                            className="list-inline-item"
                          >
                            <span
                              id="recentProjectTopic"
                              className="card-header-title text-left  "
                              style={{
                                color: "#000000",
                                margin: "0px",
                                fontWeight: "bold",
                                textTransform: "uppercase",
                                padding: "0px",
                                wordWrap: "break-word",
                              }}
                            >
                              <Link to={`/projectD/${_id}`} id="toopicc">
                                {topic}
                              </Link>{" "}
                              {isVerified === true ? (
                                <span
                                  className="fa fa-check-circle"
                                  aria-hidden="true"
                                  style={{ color: "green" }}
                                ></span>
                              ) : null}{" "}
                            </span>{" "}
                            <br />
                            <span>
                              <p>
                                {" "}
                                <span style={{ textTraform: "uppercase" }}>
                                  {summary.substring(0, 200)}..
                                </span>
                              </p>
                            </span>
                          </MDBListGroupItem>
                          <hr style={{ padding: 0, margin: 3, width: "1px" }} />
                        </MDBListGroup>
                      </div>
                    );
                  })
                ) : (
                  <h4>no project in this department</h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
