import React, { Component } from 'react'
import './eror404.css'
import {Link} from 'react-router-dom'
import Notfind from '../../assets/img/notfind.svg'
import SearchBox from '../searchResult/SearchBox'


export default class error404 extends Component {
    async componentDidMount(){
        
    }
    render() {
   
               return (
                 <div>
                    
                 
                 <div className="container" >
                   <div className="text-center mt-5">
                    
                     <div 
                       className="navbar-brand mt-3"
                       to='/'
                       style={{ fontSize: "2em", color: "022B69", fontWeight: "bold" }}
                     >
                      
          </div>
                     <h5 className="text-center mt-5">
                       {" "}
                      It Seems The Page you are looking for is Not Found
                     </h5>
                     <div className='text-center searchboxx mt-5' >
                       <SearchBox />
                     </div>
                     <img
                     className='mt-5'
                       src={Notfind}
                       alt="404 image"
                       style={{ height: "20%", width:'40%' }}
                     />
                   </div>
                 </div>
                 </div>
               );
    }
}
