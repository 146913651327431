import React, { Component } from "react";
import "../assets/style.css";

import { Link } from "react-router-dom";

import "./userdashboard.css";

import Leftsidebar from "./SidesBars/Leftsidebar";
import { userProfile, DeleteProject } from "../apidata/api";
import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBModalFooter,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";

export default class Userdashboard extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      avater: "",
      name: "",
      project: [],
      earn: Number,
      username: "",
      info: "",
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      modal6: false,
    };
  }
  toggle = (nr) => () => {
    let modalName = "modal" + nr;
    this.setState({
      [modalName]: !this.state[modalName],
    });
  };

  async componentDidMount() {
    const id = JSON.parse(localStorage.getItem("userId"));
    const user = await userProfile(id);
    const earn = user.earn.reduce((a, b) => a + b, 0);
    this.setState({
      id: user._id,
      project: user.project,
      earn: earn,
      visitId: id,
      username: user.name,
    });
  }

  // async handleDelete() {
  //       if(window.confirm('are you sure you want to delete')){
  //     const btnn = document.getElementById("delete");
  //     const pid = btnn.value
  //     console.log(pid)
  //     const deleteProject = await DeleteProject(pid)

  //     this.componentDidMount()
  //     alert(deleteProject)
  //       }

  // }

  async handleDelete(projectId) {
    if (window.confirm("Are you sure you want to delete?")) {
      const deleteProject = await DeleteProject(projectId);
      this.componentDidMount();
      alert(deleteProject);
    }
  }

  render() {
    const { id, name, project, earn } = this.state;
    const cardStyle = {
      padding: "5px",
      boxShadow: "0.5px 0.5px 4px grey",

      marginBottom: "30px",

      // color: 'rgb(3, 10, 73)',

      textAlign: "center",

      ImagesSTyle: {
        width: "90%",
        height: "150px",
        borderRadius: "45%",
      },
      buttonStyle: {
        boxShadow: "none",

        color: "#2196F3",
        borderRadius: "0px",
      },
    };

    return (
      <div>
        <div className="mt-5" id="sideBarHome">
          <Leftsidebar />{" "}
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 mt-5" id="hidesideBarHome">
              <Leftsidebar className="mt-5" />
            </div>
            <div className=" container col-lg-9  col-12  col-md-12 mt-5">
              <nav className="nav flex mt-3 ">
                <div className="col-lg-4 col-md-4  col-12" style={cardStyle}>
                  <p className="pp">You have {project.length} project</p>
                  <MDBBtn
                    color="primary"
                    size="md"
                    onClick={this.toggle(1)}
                    style={cardStyle.buttonStyle}
                  >
                    My Project
                  </MDBBtn>
                </div>

                <div className="col-lg-4 col-md-4 col-12" style={cardStyle}>
                  <div className="dropdown">
                    <p className="pp"> Contact Support Team</p>
                    <Link
                      to="#"
                      className="btn btn-primary dropdown-toggle nav-link "
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={cardStyle.buttonStyle}
                    >
                      Support
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link
                        className="dropdown-item text-center  "
                        to="/complain"
                      >
                        Report Issue
                      </Link>
                      <Link
                        className="dropdown-item text-center  "
                        to="/contactus"
                      >
                        Contact Support team
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12" style={cardStyle}>
                  <p className="pp">
                    You've Earn <del>N</del>
                    {earn}
                  </p>

                  <Link
                    className="nav-link btn btn-primary  "
                    style={cardStyle.buttonStyle}
                    to="/user/cashout"
                  >
                    <span className="fa fa-money"></span> Cashout
                  </Link>
                </div>

                <div className="col-lg-4 col-md-4 col-12" style={cardStyle}>
                  <Link
                    className="nav-link btn btn-primary  "
                    style={cardStyle.buttonStyle}
                    to={`/profile/${id}`}
                  >
                    Profile
                  </Link>
                </div>

                <div className="col-lg-4 col-md-4 col-12" style={cardStyle}>
                  <Link
                    className="nav-link   btn btn-primary "
                    style={cardStyle.buttonStyle}
                    to={`addproject/${id}`}
                  >
                    Upload project
                  </Link>
                </div>
                <div className="col-lg-4 col-md-4 col-12" style={cardStyle}>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="btn btn-primary dropdown-toggle nav-link"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={cardStyle.buttonStyle}
                    >
                      Setting
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link
                        to={`/updateprofile/${id}`}
                        className="text-center dropdown-item"
                      >
                        Update profile
                      </Link>
                      <Link
                        className="dropdown-item text-center  "
                        to={`/updateprofile/${id}`}
                      >
                        Change password
                      </Link>
                    </div>
                  </div>
                </div>
              </nav>

              {/* modal for project */}
              <MDBModal isOpen={this.state.modal1} toggle={this.toggle(1)}>
                <MDBModalHeader toggle={this.toggle(1)}>
                  Your project{" "}
                </MDBModalHeader>
                <MDBModalBody>
                  <p style={{ fontStyle: "bold" }}>
                    You have {project.length} project
                  </p>
                  {project ? (
                    project.map((project, index) => {
                      const {
                        topic,
                        summary,
                        _id,
                        userId,
                        file,
                        approved,
                        price,
                        status,
                        projectapproved,
                      } = project;
                      return (
                        <div key={index}>
                          <p className="well">
                            {index + 1}:-&nbsp;{" "}
                            <Link
                              to={`/projectD/${_id}`}
                              className="text-capitalize"
                            >
                              {topic}{" "}
                            </Link>
                            <br />
                            <small className=" ml-2 text-muted">
                              {" "}
                              {summary.substring(0, 50)}{" "}
                              <Link to={`/projectD/${_id}`}>..details</Link>
                            </small>
                            {userId == id && approved == false ? (
                              <div>
                                {status === "request for paid" ||
                                projectapproved === "pending" ? (
                                  <div className="alert alert-success">
                                    Your Project Monetize is Awaiting Approval
                                  </div>
                                ) : (
                                  <div>
                                    <Link
                                      to={`/editmonetize/${_id}`}
                                      className="btn btn-sm btn-primary"
                                    >
                                      Monetize
                                    </Link>

                                    {/* <button
                                      id="delete"
                                      value={_id}
                                      className="btn btn-sm btn-danger"
                                      onClick={this.handleDelete.bind(this)}
                                    >
                                      delete
                                    </button> */}

                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() => this.handleDelete(_id)}
                                    >
                                      delete
                                    </button>

                                    <a
                                      href={`${file}`}
                                      className=" btn btn-sm btn-primary"
                                      download
                                      target="_blank"
                                    >
                                      Download
                                    </a>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div>
                                {approved == true &&
                                projectapproved == "approved" ? (
                                  <div>
                                    <p className="alert alert-success">
                                      <span>Monetize Project Approved ==</span>{" "}
                                      <del>N</del>
                                      {price}
                                    </p>

                                    <button
                                      id="delete"
                                      value={_id}
                                      className="btn btn-sm btn-danger"
                                      onClick={this.handleDelete.bind(this)}
                                    >
                                      delete
                                    </button>

                                    <a
                                      href={`${file}`}
                                      className=" btn btn-sm btn-primary"
                                      download
                                      target="_blank"
                                    >
                                      Download
                                    </a>
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </p>
                          <hr />
                        </div>
                      );
                    })
                  ) : (
                    <h1>No Project Yet</h1>
                  )}

                  {/* my project modal */}
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn
                    className="btn btn-sm"
                    color="secondary"
                    onClick={this.toggle(1)}
                  >
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModal>

              {/* modal for notification */}
              {/* <MDBModal toggle={this.toggle(2)} isOpen={this.state.modal2} position="right">
                                <MDBModalHeader toggle={this.toggle(2)}> </MDBModalHeader>
                                <MDBModalBody className="text-center">
                                    <Complain/>
                                    <MDBBtn color="secondary" onClick={this.toggle(2)}>Close</MDBBtn>
                                </MDBModalBody>
                            </MDBModal> */}

              {/* modal for cashout */}

              <MDBModal isOpen={this.state.modal3} toggle={this.toggle(3)}>
                <MDBModalHeader toggle={this.toggle(3)} className="text-center">
                  {" "}
                  <span className="fa fa-money">Cash Out</span>{" "}
                </MDBModalHeader>
                <MDBModalBody>
                  <h2>Notifications</h2>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn
                    className="btn btn-sm"
                    color="secondary"
                    onClick={this.toggle(3)}
                  >
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
