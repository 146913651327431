import React, { Component } from 'react'
import Axios from 'axios'
import '../../assets/smalloader.css'


export default class ChangePass extends Component {
  constructor(){
    super()
    this.state={
      isLoading:'',
      updateinfo:'',
      oldPassword:'',
      newPassword:'',
      confirmnewp:''

    }
    this.handleUpdateNewPass=this.handleUpdateNewPass.bind(this)
    this.handleUpdateOldPass = this.handleUpdateOldPass.bind(this)
    this.handleUpdateNewPassConfirm= this.handleUpdateNewPassConfirm.bind(this)
    this.handleSubmitPassUpdate= this.handleSubmitPassUpdate.bind(this)
  }

       async handleUpdateOldPass(e){
         this.setState({oldPassword:e.target.value})
       }
       async handleUpdateNewPass(e){
         this.setState({newPassword:e.target.value})
       }
       async handleUpdateNewPassConfirm(e){
         this.setState({confirmnewp:e.target.value})
       }


  async handleSubmitPassUpdate(){
   if(!this.state.oldPassword ||!this.state.newPassword||!this.state.confirmnewp){
     this.setState({ updateinfo: `fill empty field` })
   }else if (this.state.newPassword !== this.state.confirmnewp) {
      this.setState({ updateinfo: `password not match` })
    }else{
   
    let id =JSON.parse(localStorage.getItem("userId"))
    const userpass={
      oldPassword: this.state.oldPassword,
      newPassword:this.state.newPassword,

    }
    
   await Axios.put(`/user/changepassword/${id}`, userpass)
    .then(res=>{
      console.log(res)
      this.setState({updateinfo:res.data.message})

      if (res.data.message === 'Password changed'){
         setTimeout(()=>{ 
            alert("password change re-login")
           localStorage.removeItem("token");
           window.localStorage.clear();
              window.location.href = "/"; 
        
        }, 2000)
      }

      


    }) 
    .catch(err=>{
      console.log(err)

    })
    }

  }

  render() {
    const {updateinfo}= this.state
    const displayChangePass = () => {

      document.getElementById("changepasswrapper").style.display = "block"
      document.getElementById("btnChangePass").style.display = "none"
    }
    return (
      <div>
        


        <button id="btnChangePass" className="btn btn-sm btn-primary " onClick={displayChangePass}>change Password</button>

        <div id="changepasswrapper" style={{ display: 'none' }} >

        <div className="form-group" >

          <input type="tel" className="form-control" id="inputPhone" placeholder="Old Password" onChange={this.handleUpdateOldPass} />
        </div>

        <div className="form-group">

          <input type="tel" className="form-control" id="inputPhone" placeholder="New Password" onChange={this.handleUpdateNewPass} />
        </div>
        <div className="form-group">

          <input type="tel" className="form-control" id="inputPhone" placeholder="Confirm Password" onChange={this.handleUpdateNewPassConfirm} />
        </div>


        {/*display info  */}
        {this.state.updateinfo
          !== '' ? <div className="alert alert-warning">{updateinfo} </div> : null}

        {/* show loading */}
        <div >
          {this.state.isLoading === true ?
            (document.getElementById('btnupdate').style.display = 'none') &&
            <div className='text-center' id="loader" ></div> :
            <button type="button" id="btnupdate" className="btn btn-primary btn-sm" onClick={this.handleSubmitPassUpdate} >Change Password</button>

          }
        </div>
        </div>
      </div>
    )
  }
}

