import React, { Component } from 'react'
import Leftsidebar from '../SidesBars/Leftsidebar'
import { getDistinctDepartment, getDepartmentCat} from '../../apidata/api'
import {Link} from 'react-router-dom'
import Loading from '../../Layout/Loader/Loader'

export default class DepartmentList extends Component {
    constructor() {
        super();
        this.state = {
            departmentlists: [],
            loading: true
        };
    }
    async componentDidMount(){
        const departmentlist= await getDistinctDepartment()
        console.log("dep " + departmentlist)
        departmentlist.sort((a, b) => a.localeCompare(b))
        this.setState({departmentlists:departmentlist, loading: false})
    }
    render() {
        const {departmentlists}= this.state
        return (
            <div>
                 <div className=' mt-5' id="sideBarHome">
                <Leftsidebar/>
                    </div>
                <div className=' container row'>
                    <div className='col-lg-3 mt-5' id="hidesideBarHome">
                <Leftsidebar/>
                    </div>
                <div className=" col-lg-8 mt-5">
                    <div className="row">
                        <div className=" col-12 mt-3 ">
                            <h3 className="text-center bold"> Department List</h3>
                            {this.state.loading && <h5><Loading/></h5>}
                             {departmentlists ? departmentlists.map((dp,index)=>{
                          
                               return(
                                   <div className="container">
                                <ul key={index} style={{ listStyleType: 'none' }} className="list-group itt"><li className="list-group-item text-left"> 
                                       <Link to={`/department/${dp}`}>{dp.toUpperCase()} </Link></li></ul>
                                   </div>
                               )
                             }): <h4>No department yet</h4>}
                        </div>
                    </div>
                </div>
                
            </div>
                </div>
        )
    }
}
