import React , { Component } from 'react';
import { MDBListGroup, MDBListGroupItem, MDBContainer } from "mdbreact";
import { getAllproject} from '../../apidata/api'
import './recentProject.css'
import {Link} from 'react-router-dom'

export default class RecentProjectLanding extends Component {
         constructor() {
    super();
    this.state = {
    
      recentProject: [],
      
      
    };
}

    async componentDidMount() {
    try {
      const allproject = await getAllproject()
          const recentproject= allproject.slice(0,8)
      localStorage.setItem("projects", JSON.stringify(allproject))
   
      this.setState({
        recentProject: recentproject,
        loading: false,
      });
      const token = localStorage.getItem("token");
      this.setState({ token: token });
    } catch(error) {
      let projects = localStorage.getItem("projects")
      this.setState({allproject: JSON.parse(projects), mode: 'offline'})
    }
  }
    render() {
        const {recentProject} = this.state
        
  return (
       

                  <div style={{backgroundColor:'##E9EBEE'}}>
    <MDBContainer>
        <h3 className='text-center p-2  caption' style={{fontWeight:'bold', textTransform:'uppercase'}}>Recents Projects Docs</h3>
        {recentProject ? 
                recentProject.map((pro, index) => {
                  const { topic,department,school,views ,downloads,_id ,isVerified} = pro;
                  return(
      <MDBListGroup>
       
             
        <MDBListGroupItem id="recentprojectlist" style={{color:'022b69', fontWeight:'bold',cursor:'pointer',margin:'0px'}}>
           <Link to={`/projectD/${_id}`} id="toopi" style={{color:'#022b69'}}>
          <span id="recentProjectTopic">{topic.toUpperCase()} </span> {isVerified === true ? (
                          <span
                            className="fa fa-check-circle"
                            aria-hidden="true"
                            style={{color:'green'}}
                          >
                          
                          </span>
                        ) :null}<br />
        <span >
          <small>{department.toUpperCase()}&nbsp;| &nbsp;<span style={{textTraform:'uppercase'}}>{school.toUpperCase()}</span> <i className="mdi mdi-cloud-download pr-1"></i>
                {downloads.length} &nbsp;
                <i className="mdi mdi-eye-outline pr-1"> </i>
                {views.length}</small>
        
        </span></Link>
         </MDBListGroupItem>
         
            
          <hr  style={{padding:0,margin:3,width:'1px'}}/>
      </MDBListGroup>
)
  }) :<div>nothing</div>}
             <p className='text-center bold bg-white'> <Link to="/moreprojects"> LOAD MORE PROJECT</Link></p>
           
    </MDBContainer>
  
    </div>)

}
}
