import React, { Component } from 'react'
import SideBarNav from "../layout/sideNavigation";
import "./admin.css";
import moment from "moment";
import {
    MDBRow,
    MDBCol,
   
    
} from "mdbreact";


import { getAllComplains } from "../../components/apidata/api";
export default class AllComplain extends Component {
    constructor() {
        super()
        this.state = {
            allcomplain: []

        }
    }

    async componentWillMount() {
        const complain = await getAllComplains()
            complain.sort().reverse();
       
        if (complain) {
            this.setState({ allcomplain: complain})
        }
    }
   
    render() {
        const aa = {
            backgroundColor:'#fff',
            borderRadius:' 20px'
        }
        return (
            <div>
                <div className="flexible-content">
                    <SideBarNav />
                    <div id='content' style={aa} className="p-2">
                        <MDBRow>
                            <MDBCol md="12"> 
                            <h4 className="">Complains/Issues</h4>
                            {this.state.allcomplain ? this.state.allcomplain.map((compl, index)=>{

                                 const{username, complain , subject, date}=compl
                                 return(
                                     <p className="grey p-2">
                                         <h6><strong>Subject:</strong>&nbsp;&nbsp;{subject}</h6>
                                         <strong>Complain:</strong>&nbsp;&nbsp; {complain}
                                         <br></br>
                                          BY &nbsp;&nbsp;{username} on {moment(date).format("DD/MM/YY")}
                                     </p>
                                 )
                            }):<p>No complain</p>}
                            
                            
                            
                            </MDBCol>
                            </MDBRow>
                            </div>
                            </div>
                
            </div>
        )
    }
}
