import React, { Component } from 'react'
import { getAllproject, getDepartmentCat } from '../../apidata/api';

import Pagination from './paginate'
import VisitorNavbar from '../../Layout/Header/Navbar/VisitorNavbar';
import AllProjectList from '../listProjects/allprojectslist'

export default class VisitorProject extends Component {
    constructor() {
        super()
        this.state = {
          id: "",
          topic: "",
          department: "",
          school: "",
          year: "",
          summary: "",
          allproject: [],
          token: "",
          searchText: "",
          views: "",
          currentPage: 1,
          postsPerPage: 12,
        
        };}


    async componentWillMount() {
        const id = await JSON.parse(localStorage.getItem("userId"));
        this.setState({ id: id })


    }
    
    async componentDidMount() {
        const allproject = await getAllproject()
        this.setState({
            allproject: allproject
        })


        const token = localStorage.getItem('token')
        this.setState({ token })


    }
    async VerifyLogin() {
        let token = localStorage.getItem('token')
        console.log(token)
        if(!token ) {
            alert('You have to Login before view the project details ')
        this.props.history.push('/signin')
        }
    }
    render() {

      
        return (
            <div>
                <VisitorNavbar/>
                <AllProjectList/>
            </div>
        )
    }
}
