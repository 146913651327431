import React, { Component } from "react";
import {
  userProfile,
  getUserApprovedCashout,
  getUserPendingCashout,
} from "../../../apidata/api";
import { Link } from "react-router-dom";

import moment from "moment";
import axios from "axios";
import Leftsidebar from "../../SidesBars/Leftsidebar";
import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBModalFooter,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
export default class Cashout extends Component {
  constructor() {
    super();
    this.state = {
      // pendingcash
      pamount: "",
      pdate: "",
      pacctname: "",
      pacctno: "",
      pbank: "",
      pstatus: "",
      modal: false,
      info: "",
      approvedcashouts: [],
    };
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  async componentDidMount() {
    const id = JSON.parse(localStorage.getItem("userId"));
    const user = await userProfile(id);

    //    summ all earn
    const earn = user.earn.reduce((a, b) => a + b, 0);
    //  sum all payout
    const payout = user.payout.reduce((a, b) => a + b, 0);
    this.setState({
      id: user._id,
      project: user.project,
      earn: earn,
      visitId: id,
      username: user.name,
      payout: payout,
      cashhistory: user.cashout,
    });
    const pendingcashout = await getUserPendingCashout(user._id);
    this.setState({
      pacctname: pendingcashout.acctname,
      pacctno: pendingcashout.acctno,
      pbank: pendingcashout.bankname,
      pamount: pendingcashout.cashoutamount,
      pdate: pendingcashout.date,
      pstatus: pendingcashout.cashoutstatus,
    });
    console.log(pendingcashout);
    const approvedCashout = await getUserApprovedCashout(user._id);
    approvedCashout.sort().reverse();
    this.setState({ approvedcashouts: approvedCashout });
  }

  async handleCashoutRequest() {
    this.setState({ isLoading: true });

    try {
      let id = this.state.id;
      const cashourequest = {
        acctname: this.state.acctname,
        acctno: this.state.acctno,
        bankname: this.state.bankname,
        // user: this.state.id,
        cashoutamount: this.state.earn,
      };
      axios
        .post(`/user/requestcashout/${id}`, cashourequest)

        .then((response) => {
          console.log(response);
          this.setState({
            info:
              response.data.message +
              " Your account will be credited within the next 24hrs. if you dont receive your fund within this hours chat our support team",
          });
          this.setState({ isLoading: false });
          this.componentDidMount();
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          this.setState({ info: err.message });
          console.log(err);
        });
    } catch (error) {
      this.setState({ isLoading: false });
      return error.message;
    }
  }

  handleAcctName(e) {
    this.setState({ acctname: e.target.value });
  }
  handleAcctNo(e) {
    this.setState({ acctno: e.target.value });
  }
  handleBank(e) {
    this.setState({ bankname: e.target.value });
  }

  render() {
    const {
      id,

      earn,
      pamount,
      pacctname,
      pacctno,
      pbank,
      pdate,
      payout,
      pstatus,
    } = this.state;
    const w = {
      width: "300px",
    };
    return (
      <div>
        <div className="mt-5" id="sideBarHome">
          <Leftsidebar />
        </div>
        <div className="container mt-5">
          {/* cashout */}
          <div className="row">
            <div className="col-lg-3" id="hidesideBarHome">
              <Leftsidebar />
            </div>

            <div className="col-lg-9   mt-5">
              <div className="row  ">
                <div className=" col-md-3 col-4 card  alert alert-success text-center">
                  <h5 className="card-header"> Current Earn</h5>

                  <h3>
                    <del>N</del>
                    {earn}
                  </h3>

                  {pstatus === "pending" ? (
                    <button className="mt-5 btn btn-sm btn-warning" disabled>
                      You've pending Cashout
                    </button>
                  ) : (
                    <button
                      className="mt-5 btn btn-sm btn-info "
                      onClick={this.toggle}
                      // style={{backgroundColor:"#022B69",color:'white'}}
                    >
                      Proceed to cashout
                    </button>
                  )}
                </div>

                <div className="col-md-3 col-4 card   alert alert-warning ">
                  <h6 className="card-header text-center">Pending Cashout</h6>
                  {pstatus ? (
                    <ul style={{ listStyleType: "none" }}>
                      <li>Date: {moment(pdate).format("DD/MM/YY")}</li>
                      <li>
                        Amount: <del>N</del>
                        {pamount}
                      </li>

                      <li>Acctname: {pacctname}</li>
                      <li>Acctno: {pacctno}</li>
                      <li>Bank: {pbank}</li>
                      <button className="btn btn-sm btn-warning">
                        {" "}
                        cashout on pending
                      </button>
                    </ul>
                  ) : (
                    <p className=" alert alert-info">No Pending Cashout</p>
                  )}
                </div>

                <div className="col-md-3 col-4 card text-center   alert alert-success ">
                  <h5 className="card-header"> Total Payouts </h5>
                  <h3>
                    <del>N</del>
                    {payout}
                  </h3>
                </div>
              </div>

              {/* row */}
              <div className="  mt-2" id="cashout">
                {/* <div className="card col-md-5 ">
                               <h5>cashout detail</h5>
                             </div> */}

                <div className="col-md-12 card col-12">
                  <h5 className="card-header text-center"> Cashout History</h5>
                  {this.state.approvedcashouts
                    ? this.state.approvedcashouts.map((approved, index) => {
                        const {
                          acctname,
                          acctno,
                          bankname,
                          cashoutamount,
                          user,
                          cashoutstatus,
                          date,
                          userName,
                        } = approved;

                        return (
                          <div key={index}>
                            <ul style={{ listStyleType: "none" }}>
                              <p>
                                ON {moment(date).format("DD/MM/YYYY")} Sum Of{" "}
                                <del>N</del>
                                <span
                                  style={{ fontWeight: "bold", color: "red" }}
                                >
                                  {" "}
                                  <del>N</del>
                                  {cashoutamount}
                                </span>{" "}
                                &nbsp;was paid to the below account detail
                                <div className="alert alert-success">
                                  {" "}
                                  <li>Acct Name:&nbsp;{acctname}</li>
                                  <li>Acct No:&nbsp;{acctno}</li>
                                  <li> Bank:&nbsp;{bankname}</li>
                                </div>
                                cashout was requested by{" "}
                                <Link to={`/profile/${user}`}>{userName}</Link>
                              </p>
                              <hr />
                            </ul>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>

            <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
              <MDBModalHeader toggle={this.toggle}>
                <h4>Cashout Details</h4>{" "}
              </MDBModalHeader>
              <MDBModalBody>
                {earn < 500 ? (
                  <div>
                    {" "}
                    <p className="alert alert-info">
                      You must earn minimum <del>N</del>500 before cashout.
                    </p>
                  </div>
                ) : (
                  <div>
                    <h5 className="card-header text-center">
                      Cash Out Bank Detail{" "}
                    </h5>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="account Name  e.g John Doe"
                        onChange={this.handleAcctName.bind(this)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="account Number  e.g-0000303333"
                        onChange={this.handleAcctNo.bind(this)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Bank e.g Gtbank"
                        onChange={this.handleBank.bind(this)}
                      />
                    </div>
                    <div className="form-inline alert alert-default">
                      <input type="checkbox" className="form-control" /> &nbsp;I
                      apply for Cashout of= <del>N</del>
                      {earn}
                    </div>

                    {this.state.info !== "" ? (
                      <div className="text-center alert alert-info">
                        {this.state.info}
                      </div>
                    ) : (
                      <div className="form-group">
                        <button
                          className="btn  btn-primary"
                          onClick={this.handleCashoutRequest.bind(this)}
                        >
                          {" "}
                          Request Cashout for <del>N</del>
                          {earn}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  className="btn btn-sm"
                  color="danger"
                  onClick={this.toggle}
                >
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          </div>
        </div>{" "}
      </div>
    );
  }
}
