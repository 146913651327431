import React, { Component } from 'react'
// import UserHeader from '../../Layout/Header/Navbar/usersHeader'
import {getUserData} from '../../apidata/api'
import '../../assets/smalloader.css'
import axios from 'axios'
import avata from '../../assets/img/undraw_profile.svg'
import {userProfile} from '../../apidata/api'
import {Link} from "react-router-dom"
import ChangePass from './ChangePass'
import Leftsidebar from "../SidesBars/Leftsidebar"
import './updateProfile.css'
import Loading from '../../Layout/Loader/Loader'
export default class updateProfile extends Component {
   constructor(){
       super()
       this.state={
      name:'',
      email:'',
      school:'',
      department:'',
      phone:'',
      city:'',
      bio:'',
      id:'',
      avaterchange:null,
      avater:'',
      updateinfo:'',
      uploadinfo:'',
      isLoading:false,
      Uploadloading: Boolean,
      loading:true
       }

   
    //bind the fuction to constructor
    this.updateProfileButton=this.updateProfileButton.bind(this)
    this.handleUpdateEmail= this.handleUpdateEmail.bind(this)
    this.handleUpdateName =this.handleUpdateName.bind(this)
    this.handleUpdateDepartment =this.handleUpdateDepartment.bind(this)
    this.handleUpdatePhone =this.handleUpdatePhone.bind(this)
    this.handleUpdateSchool= this.handleUpdateSchool.bind(this)
    this.handleUpdateBio= this.handleUpdateBio.bind(this)
    this.handleUpdateCity = this.handleUpdateCity.bind(this)
    this.handleUploadPic =this.handleUploadPic.bind(this)
    this.handleUserPic = this.handleUserPic.bind(this)

   }

//update profile fuction  
   async updateProfileButton(e){

    e.preventDefault()
  this.setState({isLoading:true})
  

//   const id = JSON.parse(localStorage.getItem('updateuserId'))
  const {id}= this.state
  console.log(id)
  const token = await JSON.parse(localStorage.getItem('token'))
 console.log(token)


  await fetch(`/user/update/${id}`, {
      method:'PUT',
      headers:{ 
          'Accept':'application/json',
          'Content-Type':'application/json',
          'Authorization': token
      },
  body:JSON.stringify({
      name:this.state.name,
      email:this.state.email,
      school:this.state.school,
      department:this.state.department,
      phone:this.state.phone,
      bio:this.state.bio,
      city:this.state.city

      })
    })


    .then(res=>res.json())
    .then(res=>{console.log(res)
          this.setState({isLoading:false})
        if(res.success===true){
            this.setState({updateinfo:res.message})
            // this.props.history.push('/userprofile')
           }else{         this.setState({updateinfo:res.message})
  }    
    })
    .catch(err=>{
        this.setState({isLoading:false})
        console.log(err)})

 

}
//upload avater
// upload user pics
handleUploadPic(e){
    e.preventDefault()
    this.setState({Uploadloading:true})

     if( this.state.avaterchange==undefined||this.state.avaterchange==null ){
         this.setState({ Uploadloading:false,uploadinfo:'no image selected'})
         
       
     } else{
   
     
     const userpic = new FormData() 
     userpic.append('avatar',this.state.avaterchange)
     const {id} = this.state//JSON.parse(localStorage.getItem('userId'))
        
    console.log(userpic)
     axios.put(`/user/updatepic/${id}`, userpic)
     
      
     // .then(res=>res.json())
        .then(res=>{console.log(res)
            

          if (res.data.message ==="Profile photo updated"){
              this.componentDidMount()
         this.setState({
             uploadinfo:res.data.message,
             avater:res.data.userImgUrl
         })
              this.setState({ Uploadloading: false })
     }
         
         
         
     })
 
        .catch(err=>{console.log(err)
            
            this.setState({ Uploadloading: false,uploadinfo:`${err.message}  checkOnly JPEG, PNG Or JPG allow`})})
              
 }
}



//UPDATE STATE ONCHANGE fuction
            handleUpdateName(e){
                this.setState({name:e.target.value})
            }
            handleUpdateEmail(e){
                this.setState({email:e.target.value})
            }
            handleUpdatePhone(e){
                this.setState({phone:e.target.value})
            }
            handleUpdateSchool(e){
            this.setState({school:e.target.value})
            }
            handleUpdateDepartment(e){
                this.setState({department:e.target.value})
                console.log(this.state.department)
            }
            handleUpdateBio(e){
                this.setState({bio:e.target.value})
                }
            handleUpdateCity(e){
                    this.setState({city:e.target.value})
                    }  
        
                    handleUserPic(e){
                       
                        this.setState({
                            avaterchange:e.target.files[0]
                           
                        })
                        // console.log(e.target.files[0])
                    }
      
   async componentDidMount(){
     const user = await userProfile(this.props.match.params.id)
     
     this.setState({ name: user.name, email: user.email, id: user._id, school: user.school, phone: user.phone, department: user.department, bio: user.bio, gender: user.gender, city: user.city, avater: user.avater, info: user.message ,loading:false})
            const token = JSON.parse(localStorage.getItem('token'))
           
            
            
        if(!token){
        this.props.history.push('/')
                  }
}

  render() {
    const {name,email,id,updateinfo,phone,school,department,city,bio,avater ,uploadinfo} =this.state
  const displayChangePix= ()=>{
 
      document.getElementById("changepicwrapper").style.display="block"
      document.getElementById("btnChangePics").style.display ="none"
  }
  if (this.state.loading) {
    return <Loading />;
  }
    return (
      <div>
        <div className="mt-5" id="sideBarHome">
              <Leftsidebar  />
            </div>
      <div className='container'>
            
        <div className=" row  pt-5">
                {/* <h5 className="alert alert grey"><Link to={`/profile/${id}`}> Back to Profile </Link>| <Link to="/home">Home</Link> | <Link to={`/profile/${id}`}>Profile</Link>| <Link to="/userdash">Dashboard</Link> </h5> */}
              
                <div className="sideupprof  col-lg-3" id="hidesideBarHome">              
                 <Leftsidebar /></div>
           {/* edit form */}
            
           <div className=" col-lg-9">
                <div className="form row" >


             {/* pics start upload profile pic */}
            
            <div class="md-form col-md-4  col-lg-4 mt-0 text-center">
            <div className="p-2">
              <h5 className="well ">Profile Picture</h5>
              <hr/>
                <div className="mb-4">
                 
                  {avater === undefined ? (<img src={avata} className="circle mb-2" style={{ width: "200px", height: "250px", borderRadius: "50%" }} />) : (<img src={avater} className="circle mb-2" style={{ width: "250px", height: "250px", borderRadius: "50%" }} />)}
               
                
                {/* change profile pic buton */}
                
                  <button  id="btnChangePics" className="btn btn-sm btn-primary " onClick={displayChangePix}>change profile picture</button>

                  {/* select pix */}
                 <section className="changepicwrapper" id ="changepicwrapper" style={{display:'none'}}>
                 
                        <input  style={{display:'block'}}
                            type="file" 
                            className="form-control mt-5" 
                            name="userpics"
                            onChange={this.handleUserPic} 
                            title= 'select picture to upload'
                            // ref={inputFile=>this.inputFile=inputFile}
                            
                            /> 
                            {/* make buton like input file */}
                            {/* <button  className="btn btn-light"  
                            onClick={()=>this.inputFile.click()}>select avater</button>
                        */}
                            {/*display info  */}
                    {this.state.uploadinfo
                    !=='' ?    <div id='infoo' className="alert alert-success">{uploadinfo} </div>:null}
            

                                
                        {/* show loading */}
                        <div style={{margin:'auto', width:'50%'}}>
                                {this.state.Uploadloading===true ? <div className="text-center ring-loader">uploading</div>:<button id="btnuploado" className="btnuploado btn btn-primary btn-sm" onClick={this.handleUploadPic}>uploadnow</button>
                            }
                            </div>
                    </section>


           
                </div>
            
            </div>
            </div>

    {/* pics end */}

                
                <form className="col-md-4 col-lg-4">
              <h5 className="well p-2 "> User Profile Details</h5>
              <hr />

                <div className="form-group ">
                
                    <input type="text" className="form-control" id="Name" placeholder="Name" value={name} onChange={this.handleUpdateName} />
                </div>
                <div className="form-group">
                    <input type="email" className="form-control" id="inputEmail4" placeholder="Email" value={email} onChange={this.handleUpdateEmail}/>
                </div>
                
                
                
                <div className="form-group">
                
                <input type="tel" className="form-control" id="inputPhone" placeholder="Tel Phone Number" value={phone}  onChange={this.handleUpdatePhone}/>
                </div>
                <div className="form-group">

                <input type="text" className="form-control" id="inputSchool" placeholder="School" value={school} onChange={this.handleUpdateSchool}/>
                </div>
            
                
                <div className="form-group">
                    <input type="text" className="form-control" id="department" placeHolder="department" value={department} onChange={this.handleUpdateDepartment}/>
                </div>
                <div className="form-group">
                    
                        <input type="text" className="form-control" id="city" placeholder="city" value={city} onChange={this.handleUpdateCity} />
                </div>
                <div className="form-group">
                        
                        <textarea className="form-control" placeholder="Bio" value={bio} onChange={this.handleUpdateBio}></textarea>
                </div>
                
                

            
            
                {/*display info  */}
            {this.state.updateinfo
            !=='' ?    <div className="alert alert-success">{updateinfo} </div>:null}
           
           {/* show loading */}
            <div  style={{margin:'auto' ,width:'50%'}}>
                        { this.state.isLoading===true  ?
                        (document.getElementById('btnupdate').style.display='none') &&
                        <div className='text-center' id="loader" ></div>:
                        <button type="button" id="btnupdate" className="btn btn-sm btn-primary" onClick={this.updateProfileButton}>Save Update</button>

                    }
                    </div>
                    
           

            
        </form>

   <div className="col-lg-3 text-center">
                        <h5 className="well p-2 ">Change Password</h5>
                        <hr />
       <ChangePass/>
                 </div>

</div>
                </div>





{/* edit from end */}

</div>


      </div>
      </div>
    )
  }
}
