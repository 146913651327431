import React, { Component } from 'react';

import { Link } from 'react-router-dom'
import './allprojectslist.css'
// import UserHeader from '../../Layout/Header/Navbar/usersHeader'


import '../../assets/style.css'


import { getAllproject} from '../../apidata/api';

import Pagination from './paginate'
import Leftsidebar from '../SidesBars/Leftsidebar';
import Loading from '../../Layout/Loader/Loader'


class AllProjects extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      topic: "",
      department: "",
      school: "",
      year: "",
      summary: "",
      allproject: [],
      token: "",
      searchText: "",
      views: "",
      currentPage: 1,
      postsPerPage: 8,
      token: "",
      loading: true,
      mode: "online"
      
    };
    this.handleSearch = this.handleSearch.bind(this);
  }
  
  handleSearch(e) {
    this.setState({ searchText: e.target.value });
  }

  async componentWillMount() {
    const id = await JSON.parse(localStorage.getItem("userId"));
    this.setState({ id: id });
  }

  async componentDidMount() {
    try {
      const allproject = await getAllproject();
      localStorage.setItem("projects", JSON.stringify(allproject))
      this.setState({
        allproject: allproject,
        loading: false,
      });
      const token = localStorage.getItem("token");
      this.setState({ token: token });
    } catch(error) {
      let projects = localStorage.getItem("projects")
      this.setState({allproject: JSON.parse(projects), mode: 'offline'})
    }
  }

  render() {
    const { token, id } = this.state;
    const style = {
      position: "fixed",
      zIndex: "1",
    };

    // get current posts
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFisrtPost = indexOfLastPost - this.state.postsPerPage;
    const currentPosts = this.state.allproject.slice(
      indexOfFisrtPost,
      indexOfLastPost
    );

    //change page
    const paginate = (pageNumber) => {
      this.setState({ currentPage: pageNumber });
    };

    currentPosts.filter((project) => {
      //let search = this.state.searchText
      return (
        project.topic
          .toLowerCase()
          .search(this.state.searchText.toLowerCase()) !== -1 ||
        project.department
          .toLowerCase()
          .search(this.state.searchText.toLowerCase()) !== -1
      );
    });

    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div>
        <div className="mt-5">
          {/* {this.state.token ? <UserHeader/>:<div></div>} */}

          {token ? (
            <div className="mobilesideba">
              {" "}
              <Leftsidebar />
            </div>
          ) : null}

          <div className=" container "></div>
          {/*   */}

          <hr className="hr" />

          <div className="mt ">
            {/* carddeck */}
            <div>
              {this.state.mode === 'offline' ? <div>You are in offline mode</div> : null}
            </div>
            <div className="card-columns mt-5  mobilelist">
              {currentPosts ? (
                currentPosts.map((pro, index) => {
                  const { _id, topic, summary, isVerified } = pro;

                  return (
                    // className="col-lg-3 col-sm-6 col-xm-12  col-md-4"
                    <div
                      className=" col-lg-12 col-sm-12 col-xm-12  col-md-12"
                      key={index}
                    >
                      <div
                        className=" card card-cascade"
                        style={{
                          marginBottom: "20px",
                          width: "100%",
                          boxShadow: "",
                        }}
                      >
                        {/* <!-- Card title --> */}
                        <div className=" p-2 ">
                          {/* <!-- Title --> */}
                          <h6
                            className=" text-left  "
                            style={{
                              color: "#000000",
                              margin: "0px",
                              fontWeight: "bold",
                              textTransform: "uppercase",
                              padding: "0px",
                              wordWrap: "break-word",
                            }}
                          >
                            {" "}
                            <Link to={`/projectD/${_id}`} style={{ color: "#000000"}}>{topic} </Link>{" "}
                            {isVerified === true ? (
                              <span
                                className="fa fa-check-circle"
                                aria-hidden="true"
                                style={{color:'green'}}
                              ></span>
                            ) : null}
                          </h6>
                          {/* <!-- Text --> */}
                        </div>

                        {/* <!-- Card content --> */}
                        <div className="p-2 text-left">
                          {/* <!-- Text --> */}
                          {/* {summary.substring(0, 50) */}

                          <p className="card-text " style={{color:'gray', fontSize:'14px'}}>
                            {summary.substring(0, 150)}..
                            <Link to={`/projectD/${_id}`}>
                              {/* <button className="btn  btn-sm btn-outline " style={{ 'backgroundColor': '#fff', border: '1px  groove gray', color: '#000' }}> detail</button> */}
                              Learn More
                            </Link>
                          </p>
                        </div>
                        {/* <!-- Card content --> */}

                      
                      </div>
                      {/* <!-- Card --> */}
                    </div>
                  );
                })
              ) : (
                <h1>no project available</h1>
              )}
            </div>
            {/* carddeck end */}
            <br />
            <div className='container'>
            <Pagination
              postsPerPage={this.state.postsPerPage}
              totalPosts={this.state.allproject.length}
              paginate={paginate}
            />
          </div>
          </div>

          {/* row */}

          {/* main */}
        </div>
      </div>
    );
  }
}

export default AllProjects