import React, { Component } from 'react';
import './Signin.css'
// import '../../assets/mdb/css/style.css'
import {   MDBModalFooter, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import ProcessLoader from "../../assets/ProcessLoader"
import Useloader from '../../assets/useloader'
import {Link, Redirect} from 'react-router-dom';
import axios from 'axios'


class Signin extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      isLoading: false,
      info: "",
      emailforgetpass: "",
      forgetinfo: "",
      error: false,
      token: false,
      modal: false,
      isProcess:false,
      isPasswordShown: false,
    };

    this.handleEmail = this.handleEmail.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleEmailForgetPass = this.handleEmailForgetPass.bind(this);
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };
  //    with axios u dont need headers and res.json bt its needed for fetch
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    fetch("/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      }),
    })
      .then((res) => res.json())

      .then((res) => {
        this.setState({ isLoading: false });
        this.setState({ info: res.message });
       

        if (res.code === "OK") {
          window.localStorage.setItem("userId", JSON.stringify(res.id));
          window.localStorage.setItem("token", JSON.stringify(res.token));
          this.setState({ token: true });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  handleEmail(e) {
    this.setState({ email: e.target.value });
  }
  handlePassword(e) {
    this.setState({ password: e.target.value });
  }
  handleEmailForgetPass(e) {
    this.setState({ emailforgetpass: e.target.value });
  }

  async handleSubmitForgetPass() {
    this.setState({isProcess:true})
    if (!this.state.emailforgetpass) {
      this.setState({ forgetinfo: "enter  your email", isProcess: false});
    } else {
      const mail = {
        email: this.state.emailforgetpass,
      };
      axios
        .post("/forgot/password", mail)
        .then((res) => {
          this.setState({ forgetinfo: res.data.message, isProcess: false });
         
         
        })

        .catch((err) => {
          console.log(err);
           this.setState({ forgetinfo: err, isProcess: false });
         
        });
    }
  }

  render() {
     const { isPasswordShown } = this.state;
    if (!this.state.token) {
      return (
        <div>
          {/* /login */}

          {/* <!-- Material form login --> */}
          <div className="card mt-5" id="signin">
            <h3 className="card-header  dark-text text-center py-4">
              <strong className="mdi mdi-account-key">Sign-in</strong>
            </h3>

            {/* <!--Card content--> */}
            <div className="card-body px-lg-5 pt-0">
              {/* <!-- Form --> */}
              <div className="text-center" style={{ color: "#757575" }}>
                {/* <!-- Email --> */}
                <div className="md-form mt-5">
                  <input
                    type="email"
                    id="materialLoginFormEmail"
                    className="form-control"
                    value={this.state.email}
                    onChange={this.handleEmail}
                  />
                  <label
                    htmlFor="materialLoginFormEmail"
                    className="fa fa-envelope"
                  >
                    {" "}
                    Email
                  </label>
                </div>

                {/* <!-- Password --> */}
                <div className="md-form mt-2">
                  <input
                    type={isPasswordShown ? "text" : "password"}
                    id="materialLoginFormPassword"
                    className="form-control"
                    // value={this.state.password}
                    onChange={this.handlePassword}
                  />
                  
                  <label
                    htmlFor="materialLoginFormPassword"
                    className="fa fa-lock"
                  >
                    {" "}
                    Password{" "}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                    <span>
                      <i
                        style={{ fontSize: "auto", cursor: "pointer" }}
                        onClick={this.togglePasswordVisiblity}
                        className="fa"
                      >
                        &#xf06e;
                    </i>
                     
                    </span>{" "}
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{" "}
                  <small>
                    {" "}
                    <Link
                      to="#"
                      onClick={this.toggle}
                      style={{ textAlign: "right" }}
                    >
                      FORGOT PASSWORD
                    </Link>
                  </small>
                </div>
                {this.state.info === "" || this.state.info === undefined ? (
                  <p
                    className="alert alert-warning"
                    style={{ display: "none" }}
                  >
                    {this.state.info}
                  </p>
                ) : (
                  <p className="alert alert-danger">{this.state.info}</p>
                )}
                {this.state.isLoading === true ? <Useloader /> : null}

                {/* <!-- Sign in button --> */}
                <button
                  id="loginbtn"
                  className="btn btn-outline-blue btn-rounded btn-block my-4 waves-effect z-depth-0 mt-5 mdi mdi-account-check"
                  type="submit"
                  onClick={this.handleSubmit}
                >
                  {" "}
                  Sign in
                </button>

                {/* <!-- Register --> */}
                <p>
                  Not a member?
                  <Link to="/signup"> Register</Link>
                </p>
              </div>
              {/* <!-- Form --> */}
            </div>
          </div>
          {/* <!-- Material form login --> */}

          <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
            <MDBModalHeader toggle={this.toggle}>
              <h4> FORGOT PASSWORD </h4>{" "}
            </MDBModalHeader>
            <MDBModalBody>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter your email"
                  value={this.state.emailforgetpass}
                  onChange={this.handleEmailForgetPass}
                />
              </div>

              {this.state.forgetinfo !== "" ? (
                <div className="alert alert-warning">
                  {this.state.forgetinfo}{" "}
                </div>
              ) : null}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                className="btn btn-sm"
                color="secondary"
                onClick={this.toggle}
              >
                Close
              </MDBBtn>
              <button
                className="btn btn-sm btn-primary"
                onClick={this.handleSubmitForgetPass.bind(this)}
              >
              
                {this.state.isProcess === true ? (
                  <ProcessLoader />
                ) : (
                  <span> Send Me password</span>
                )}
              </button>
            </MDBModalFooter>
          </MDBModal>
        </div>
      );
    } else {
      return <Redirect to="/home" />;
    }
    // if(this.state.isLoading){
    //       return(<Loader/>)
    // }

    // return(

    // )
  }
}


export default Signin