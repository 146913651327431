import React, { Component } from 'react';

import {Link} from 'react-router-dom'
import './projects.css'
// import UserHeader from '../../Layout/Header/Navbar/usersHeader'
import { MDBListGroup, MDBListGroupItem, MDBContainer } from "mdbreact";
import  '../../assets/style.css'


import { getAllproject  } from '../../apidata/api';
import Hmenu from './Hmenu';
import Pagination from './paginate'
import Loading from '../../Layout/Loader/Loader'
import SearchBox from '../searchResult/SearchBox';


class Projects extends Component{

    constructor(){
      super()
      this.state={
        id:'',
        topic:'',
        department:'',
        school:'',
        year:'',
        summary:'',
        allproject:[],
        token:'',
        searchText:'',
        views:'',
        currentPage: 1,
        postsPerPage: 9,
        loading: true
        
      }
      this.handleSearch= this.handleSearch.bind(this)
    }

     handleSearch(e){
       this.setState({searchText:e.target.value})
       console.log(this.state.searchText)
       

     }

   async componentWillMount(){
     const id = await JSON.parse(localStorage.getItem("userId"));
     this.setState({id:id})
     
     
   }
       async componentDidMount(){
         const allproject = await getAllproject()
         this.setState({
           allproject: allproject,
           loading: false
         })
        

        const token= localStorage.getItem('token')
        this.setState({token})

        
       }

    render(){

 const { id}= this.state
     

      // get current posts
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage
    const indexOfFisrtPost = indexOfLastPost - this.state.postsPerPage
    const currentPosts = this.state.allproject.slice(indexOfFisrtPost, indexOfLastPost)

    //change page
    const paginate = (pageNumber) => {
      this.setState({currentPage: pageNumber})
    }

       currentPosts.filter((project)=>{
      //let search= this.state.searchText
      return (project.topic.toLowerCase().search(this.state.searchText.toLowerCase()) !== -1 || project.department.toLowerCase().search(this.state.searchText.toLowerCase()) !== -1 )
     //  indexOf(this.state.searchText.toLowerCase())!==-1;
    })

    if(this.state.loading) {
      return <Loading />
    }
    return(    
      <div className="container">
    <Hmenu  />

    <div className="col-lg- mt-5">
      {/* {<Search /> } */}



       {/* <form className="form-group ml-5 mt-5 " >
        <div className="form-group my-0">
          <input className="form-control " type="text"
            onChange={this.handleSearch} value={this.state.searchText} placeholder="Search project topics" aria-label="Search" />
        </div>
      </form> */}


    </div>
    {/*   */}
   
<div  className="row adsearch">  


<div className="col-lg-2"></div>
      <div className='col-lg-6 mt-5 col-md-6 col-6' >
        {/* <Link to={`addproject/${id}`}> <button className="btn  btn-primary text-center" style={{ width: '100%' }}> Add Project Documentation</button></Link> */}
         <SearchBox/>
     
       
      </div>
      
       <div className='mt-5  col-lg-2  col-md-6 col-6 '> 
        {/* <form className="form-group my-2" >
        
          <input className="form-control " type="text"
            onChange={this.handleSearch} value={this.state.searchText} placeholder="Search project topics" aria-label="Search" />
     
      </form> */}
     
      </div>
      
      </div>

    {/* <hr  className="hr"/> */}
    
    <div className="justify-content-ce madd">
      {/* <Link to={`addproject/${id}`}>   <button className="btn btn-sm btn-primary" >Add Project</button></Link> */}
       <SearchBox style={{ width:'300px'}}/>
    </div>
      <div className="mt ">
        {/* carddeck */}
       
       
        <div className=" mt-2  mproject"  >
        

{ currentPosts ?
  currentPosts.map((pro ,index)=>{
    const { _id, topic, summary,views,downloads,isVerified,department ,school}=pro
   
  return (
    // className="col-lg-3 col-sm-6 col-xm-12  col-md-4"
    
    <div
      className="card-colum col-lg-12 col-sm-12 col-xm-12  col-md-4"
      key={index}
    >
      <div
        className="car card-cascad"
        style={{ marginBottom: "20px", width: "100%"}}
      >
         <MDBListGroup>

        <MDBListGroupItem id="recentprojectlist" style={{color:'#022b69',margin:'0px'}}>
          <span id="recentProjectTopic" className="card-header-title text-left  "
            style={{
             margin: "0px",
              fontWeight: "bold",
              textTransform: "uppercase",
              padding: "20px auto",
              wordWrap: "break-word",
            }}><Link to={`/projectD/${_id}`} id="toopicc">
              {topic}
            </Link>{" "}
            {isVerified === true ? (
              <span className="fa fa-check-circle"
                                aria-hidden="true"
                                style={{color:'green'}}></span>
            ) : null} </span><br/> 
        <span >
          <p> <span style={{textTraform:'uppercase',color:'grey',fontSize:'16px'}}>{summary.substring(0, 200)}..</span> <br />
           <small style={{textTransform:'capitalize'}}>  &nbsp;{department}  &nbsp; <i className="mdi mdi-cloud-download pr-1"></i>
          {downloads.length}  &nbsp;
                <i className="mdi mdi-eye-outline pr-1"> </i>
                {views.length}</small> 
          </p>
         
       
        </span>
                   </MDBListGroupItem>
         
              </MDBListGroup>
        
       
      </div>
      {/* <!-- Card --> */}
    </div>
  );
  }) :<h1>no project available</h1>

}
<div className='container'>
           <Pagination  
            postsPerPage={this.state.postsPerPage} 
            totalPosts={this.state.allproject.length} 
            paginate={paginate}
            />
            </div>

</div>
{/* carddeck end */}
 
 </div>

 
    
 {/* row */}

 {/* main */}
    </div>

      
    
)

    }   
}

export default Projects