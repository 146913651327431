import React, { Component } from "react";
import {
  MDBRow,
  MDBCol,
  MDBView,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";
import SideBarNav from "../layout/sideNavigation";
import "./admin.css";
import moment from "moment";
import axios from "axios";

import { getApprovedCashout } from "../../components/apidata/api";

export default class CashoutTable extends Component {
  constructor() {
    super();
    this.state = {
      cashout: [],
      id: "",
      isLoading: false,
    };
  }

  async componentDidMount() {
    const paidcashouts = await getApprovedCashout();
  
    if (paidcashouts) {
      this.setState({ paidcashout: paidcashouts });
    }
  }

  
  render() {
    return (
      <div>
        <div className="flexible-content">
          <SideBarNav />
          <div id="content" className="p-2">
            <MDBRow>
              <MDBCol md="12">
                <MDBCard className="mt-5">
                  <MDBView className="gradient-card-header blue d">
                    <h4 className="h4-responsive text-white"> Paid Cashout DATA</h4>
                  </MDBView>
                  <MDBCardBody>
                    <MDBTable
                      striped
                      scrollY
                      maxHeight="400px"
                      hover
                      className="text-dark"
                    >
                      <MDBTableHead>
                        <tr>
                          <th>S/N</th>
                          <th>date</th>
                          <th>userID</th>
                          <th>Name</th>
                          <th>acctname</th>
                          <th>acctno</th>
                          <th>bankname</th>
                          <th>cashout Amount</th>
                          <th>Status</th>
                          <th>approved</th>
                          <th>approved by</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {this.state.paidcashout ? (
                          this.state.paidcashout.map((cashout, index) => {
                            const {
                              user,
                              _id,
                              date,
                              userName,
                              cashoutamount,
                              acctname,
                              acctno,
                              bankname,
                              cashoutstatus,
                              cashoutapproved,
                              approvedby
                            } = cashout;
                            if (
                              cashoutstatus === "approved" &&
                              cashoutapproved === true
                            ) {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{moment(date).format("DD/MM/YY")}</td>
                                  <td>{user}</td>
                                  <td>{userName}</td>
                                  <td>{acctname}</td>
                                  <td>{acctno}</td>

                                  <td>{bankname}</td>
                                  <td>{cashoutamount}</td>
                                  <td>{cashoutstatus}</td>
                                      <td>{approvedby}</td>
  
                                </tr>
                              );
                            }
                          })
                        ) : (
                          <p>NO CASHOUT RECORDS</p>
                        )}
                      </MDBTableBody>
                    </MDBTable>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </div>
        </div>
      </div>
    );
  }
}
