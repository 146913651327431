import React, { Component } from 'react'
import axios from 'axios'
import { userProfile } from '../apidata/api'
import VisitorNavbar from '../Layout/Header/Navbar/VisitorNavbar'
import UsersNavBarHeader from '../Layout/Header/Navbar/UsersNavBarHeader'

export default class Contactus extends Component {
    constructor() {
        super()
        this.state = {
            subject: '',
        message: '',
            info: '',
            isLoading: false,
            username: '',
           
            email:''
        }
    }

    async componentWillMount() {
        let id = await JSON.parse(localStorage.getItem("userId"))
        if (id) {
            const user = await userProfile(id)

            if (user) {
                await this.setState({ username: user.name, userId: user._id , email:user.email})
            }
        }
        
    }
    async handleMessageUs(e) {
        e.preventDefault()
        this.setState({ isLoading: true });
        console.log( this.state.userId,
             this.state.username,
          this.state.email,
         this.state.subject,
         this.state.message)

        try {


            const messages = {
           
                username: this.state.username,
                email:this.state.email,
                subject: this.state.subject,
                message: this.state.message
            };
            axios.post(`/api/v1/contactus`, messages)

                .then(response => {
                    console.log(response)
                    this.setState({ info: response.data.message })
                    this.setState({ isLoading: false });
                    
                })
                .catch(err => {
                    this.setState({ isLoading: false });
                    this.setState({ info: err.message })
                    console.log(err)
                })
        } catch (error) {
            this.setState({ isLoading: false });
            return error.message
        }

    }

    async handleName(e) {
        this.setState({ username: e.target.value })
    }
    async handleEmail(e) {
        this.setState({ email: e.target.value })
    }
    async handleSubject(e) {
        this.setState({ subject: e.target.value })
    }
    async handleMessageDetail(e) {
        this.setState({ message: e.target.value })
    }
    render() {
        let tokennn = localStorage.getItem('token')
        const{username , email} =this.state
        return (
            <div>
                {tokennn ? <UsersNavBarHeader/> : <VisitorNavbar/>}
           
            <div className="container mt-5">
                <div className="row mt-5">
                    <div className="col-lg-4 p-5">
                       <div>
                 <img src="https://kadrikondi.github.io/img/konditie.jpg" alt="" style={{width:'300px',borderRadius:'25%'}} />
                 <ul>
                     < li > <a href = "https://kadrikondi.github.io" > Idris Abdulkadri </a></li >
                     <li>Co-founder</li>
                   
                         <div class="mb-4 pb-2">
          <a href = "https://github.com/kadrikondi/" >
              <button type="button" class="btn btn-outline-primary btn-floating">
                <i class="fa fa-github fa-lg"></i>
              </button>
               </a>
              <a href = "https://www.facebook.com/abdulkadri.idris" >
              <button type="button" class="btn btn-outline-primary btn-floating">
                <i class="fa fa-facebook-f fa-lg"></i>
              </button>
               </a>
          < a href = "https://twitter.com/kadrikondi" >
              <button type="button" class="btn btn-outline-primary btn-floating">
                <i class="fa fa-twitter fa-lg"></i>
              </button>
               </a>
                     <a href = "https: //www.linkedin.com/in/abdulkadri-idris-91838812a/" >
              
              <button type="button" class="btn btn-outline-primary btn-floating">
                <i class="fa fa-linkedin fa-lg"></i>

              </button>
                </a>
            </div>
                    

                 </ul>

                       </div>

                    </div>
                    <div className="col-lg-4 col-md-6 p-5 mt-2">
                      
                        <div>
                 <img src = "https://otitoju.github.io/images/john.jpg"
                 alt = ""
                 style = {
                     {
                         width: '300px',
                         height:'300px',
                         borderRadius: '25%',
                     }
                 }/>
                 <ul>
                     <li> <a href ="https://otitoju.github.io/" > 
                     Otitoju John Oluwapelumi </a>
</li>
                     <li>Co-founder</li>
                   
                         <div class="mb-4 pb-2">
         
              <a href = "https://github.com/otitoju/" >
              <button type="button" class="btn btn-outline-primary btn-floating">
                <i class="fa fa-github fa-lg"></i>
              </button>
               </a>
          < a href = "https://twitter.com/otitojuJohnolu1/" >
              <button type="button" class="btn btn-outline-primary btn-floating">
                <i class="fa fa-twitter fa-lg"></i>
              </button>
               </a>
                     < a href = "https://www.linkedin.com/in/john-otitoju-00669b186" >
              
              <button type="button" class="btn btn-outline-primary btn-floating">
                <i class="fa fa-linkedin fa-lg"></i>

              </button>
                </a>
            </div>
                    

                 </ul>

                       </div>
                    
                    </div>
                </div>
            </div>
            </div>
        )
    }
}
