import React, { Component } from "react";
import "./projectD.css";
import Axios from "axios";
import moment from "moment";

import { Link } from "react-router-dom";
import {
  getSingleProjectA,
  projectViews,
  projectDownloads,
 
  getSingleBuyer,
} from "../../apidata/api";
import Comments from "./Comment";

//import the library
import Loading from "../../Layout/Loader/Loader";
import VisitorNavbar from "../../Layout/Header/Navbar/VisitorNavbar";

class VisitProjectD extends Component {
  constructor() {
    super();
    this.state = {
      project: null,
      topic: "",
      department: "",
      school: "",
      year: Date,
      summary: "",
      date: Date,
      id: "",
      projectdoc: "",
      buyUser: "",
      buyProject: "",
      buyStatus: "",

      views: "",
      uploadby: "",
      userId: "",
      projectUserId: "",
      downloads: "",
      status: "",
      approved: Boolean,
      softUrl: "",
      price: "",
      modal: false,
      loading: true,
      isVerified:Boolean
    };
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  async componentWillMount() {
    const userId = await JSON.parse(localStorage.getItem("userId"));
    this.setState({ userId: userId });

    // const projectviews = await projectViews(this.props.match.params.id)
    // this.setState({views: projectviews.length})
  }
  //geting data from api
  async componentDidMount() {
    const userId = await JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      let buy = await getSingleBuyer(this.props.match.params.id, userId);

      await this.setState({
        buyUser: buy.user,
        buyProject: buy.project,
        buyStatus: buy.status,
      });
    }

    const projectviews = await projectViews(this.props.match.params.id);

    this.setState({ views: projectviews.length });

    const token = await JSON.parse(localStorage.getItem("token"));
    if (!token) {
      //call a function in api file
      const project = await getSingleProjectA(this.props.match.params.id);

     await  this.setState({
        topic: project.topic.toUpperCase(),
        school: project.school.toUpperCase(),
        department: project.department.toUpperCase(),
        year: project.year,
        id: project._id,
        projectdoc: project.file,
        summary: project.summary,
        uploadby: project.uploadedby,
        projectUserId: project.userId,
        views: project.views,
        downloads: project.downloads.length,
        status: project.status,
        approved: project.approved,
        softUrl: project.softUrl,
        price: project.price,
        projectapproved: project.projectapproved,
        loading: false,
        isVerified:project.isVerified
      });
    }
    // const project= await this.getSingleProject(this.props.match.params.id)
    // this.setState({project})
    // console.log(this.state.project + 'ok')
    // }
    else {
      //alert('You need to login to view project Details')    
       const project = await getSingleProjectA(this.props.match.params.id);
      this.props.history.push(`/projectD/${project._id}`);
    }
  }

  async Downloads() {
    const projectDownload = await projectDownloads(this.props.match.params.id);
    this.setState({ downloads: projectDownload.length });
  }
  async ClosePayment() {
    this.toggle();
    this.componentDidMount();
  }
  async VerifyLogin() {
    let token = await localStorage.getItem('token')

   return token ? null || !token : alert('You have to Login  First')
    
  }
  render() {
    const {
      topic,
      school,
      year,
      department,
      userId,
     isVerified,
      uploadby,
      summary,
      views,
      projectUserId,
     
      downloads,
      status,
     
      price,
     
    } = this.state;
  
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div>
        <VisitorNavbar />
        <div className="containe" style={{ fontFamily: "roboto" }}>
          <div className="row">
            <div className="col-lg-2 mt-5"></div>

            <div className="mx- col-lg-8 col-12 mt-5">
              <div className="  card  mt-5 ">
            

                <div className="card-body">
                  <ul className="list-group" style={{ listStyleType: "none" }}>
                    <li className="list-group-ite">
                      {" "}
                      <h5>
                        {" "}
                        Project Topic:<strong>{topic}</strong> &nbsp;
                  {isVerified === true ? (
                    <span
                      className="fa fa-check-cirlce"
                      aria-hidden="true"
                      style={{color:'green'}}
                    >
                     
                    </span>
                  ) : (
                   null
                  )}
                      </h5>
                    </li>
                    <li className="list-group-ite">
                      Project Department:<strong>{department}</strong>
                    </li>
                    <li className="list-group-ite">
                      {" "}
                      Institution: <strong>{school}</strong>
                    </li>
                    <li className="list-group-ite">
                      {" "}
                      Project Year:{" "}
                      <strong>{moment(year).format("DD/MM/YYYY")} </strong>
                    </li>
                    <li className="list-group-ite">
                      {" "}
                      Uploadby:{" "}
                      <strong>
                        {" "}
                        <Link to={`/profile/${projectUserId}`} className="">
                          {uploadby}
                        </Link>
                      </strong>
                    </li>
                    <li className="list-group-ite">
                      {" "}
                      No of downloads : <strong>{downloads}</strong>
                    </li>
                    <li className="list-group-ite">
                      {" "}
                      Views: <strong>{views.length}</strong>
                    </li>
                    <li className="list-group-ite">
                      {" "}
                      Status: <strong>{status}</strong>{" "}
                    </li>

                    {/* {softUrl == '' ? 'No software' : */}

                    <li className="">
                      <Link
                        to="/signin"
                        onClick={this.VerifyLogin.bind(this)}
                        className="text-center"
                      >
                        Click here to Download
                      </Link>
                    </li>
                  </ul>
                  <hr />
                  <div>
                    <strong className="font-weight-bold  text-center">
                      PROJECT ABSTRACT
                    </strong>{" "}
                    <hr />
                    {summary}
                  </div>

                  <hr />
                  <strong>
                    <a href={`/signin`}>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={this.VerifyLogin.bind(this)}
                      >
                        Download
                      </button>
                    </a>{" "}
                  </strong>

                  <hr />
                  <p className="font-weight-bold text-uppercase">
                    Project Reviews
                  </p>
                  <hr />

                  <Comments userId={userId} id={this.props.match.params.id} />
                </div>
                {/* <div className="card-footer">Like and Share<button className=" btn btn-sm btn-primary"  onClick={this.toggle}>Buy the Project</button>
                  </div> */}
              </div>
            </div>
            {/* <div className=" container col-lg-3 mt-5">
              <DepartmentCategory />
          
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
export default VisitProjectD;
