import React, { Component } from 'react'
import "../assets/style.css";

import {Link} from 'react-router-dom'

import "./userdashboard.css"

import Leftsidebar from './SidesBars/Leftsidebar';
import { userProfile, DeleteProject} from '../apidata/api';
import {  MDBModalBody } from 'mdbreact';



export default class Myproject extends Component {
    constructor(){
        super();
        this.state = {
            id:'',
            avater:'',
            name:'',
            project:[],
            earn:Number,
            username:'',
          info:'',
            modal1: false,
            modal2: false,
            modal3: false,
            modal4: false,
            modal5: false,
            modal6: false,
            

        }
    }
    toggle = nr => () => {
        let modalName = 'modal' + nr;
        this.setState({
            [modalName]: !this.state[modalName]
        })
    }

    async componentDidMount(){
       
const id = JSON.parse(localStorage.getItem('userId'))
        const user = await userProfile(id)
        const earn = user.earn.reduce((a, b) => a + b, 0)
        this.setState({id:user._id, project:user.project,earn:earn,visitId:id ,username:user.name})
     
    
    }

    async handleDelete() {
          if(window.confirm('are you sure you want to delete')){
        const btnn = document.getElementById("delete");
        const pid = btnn.value
        console.log(pid)
        const deleteProject = await DeleteProject(pid)

        this.componentDidMount()
        alert(deleteProject)
          } 



    }


    render() {
        const { id, name, project,earn} = this.state;
       const cardStyle={
           padding:'5px',
           boxShadow:'0.5px 0.5px 4px grey',
         
           
           
            marginBottom:'30px',
          
        
        // color: 'rgb(3, 10, 73)',
        
       
        textAlign: 'center',
          

         ImagesSTyle:{
            width: "90%",
            height: "150px",
            borderRadius: "45%"
        },
         buttonStyle :{
            boxShadow: 'none',
            
             color: '#2196F3',
             borderRadius: "20px" 
            }
       }
      
        return (
          <div>
              <div className="mt-5" id="sideBarHome">
                  <Leftsidebar /> </div>
            <div className="container">
              <div className="row">
               
                        <div className="mt-5 col-lg-3 " id="hidesideBarHome">
                  <Leftsidebar /> </div>

<div className='col-lg-9 mt-5 ' style={{backgroundColor:'#ffffff'}}>
                     
                   
                    <MDBModalBody>
                      <h3 style={{fontStyle:'bold'}}>You have {project.length} project</h3>
                      {project ? (
                        project.map((project, index) => {
                          const {
                            topic,
                            summary,
                            _id,
                            userId,
                            file,
                            approved,
                            price,
                            status,
                            projectapproved,
                          } = project;
                          return (
                            <div key={index}>
                              <p className="well">
                                {index + 1}:-&nbsp;{" "}
                                <Link
                                  to={`/projectD/${_id}`}
                                  className="text-capitalize"
                                >
                                  {topic}{" "}
                                </Link>
                                <br />
                                <small className=" ml-2 text-muted">
                                  {" "}
                                  {summary.substring(0, 50)}{" "}
                                  <Link to={`/projectD/${_id}`}>..details</Link>
                                </small>
                                {userId == id && approved == false ? (
                                  <div>
                                    {status === "request for paid" ||projectapproved==="pending" ? <div className="alert alert-success">Your Project Monetize is Awaiting Approval</div>:
                                    <div>
                                    <Link
                                      to={`/editmonetize/${_id}`}
                                      className="btn btn-sm btn-primary"
                                    >
                                      Monetize
                                    </Link>

                                    <button
                                      id="delete"
                                      value={_id}
                                      className="btn btn-sm btn-danger"
                                      onClick={this.handleDelete.bind(this)}
                                    >
                                      delete
                                    </button>

                                    <a
                                      href={`${file}`}
                                      className=" btn btn-sm btn-primary"
                                      download
                                      target="_blank"
                                    >
                                      Download
                                    </a>
                                    </div>
                                    }
                                  </div>
                                ) : (
                                  <div>
                                    {approved == true &&
                                    projectapproved == "approved" ? (
                                      <div>
                                        <p className="alert alert-success">
                                          <span>
                                            Monetize Project Approved ==
                                          </span>{" "}
                                          <del>N</del>
                                          {price}
                                        </p>

                                        <button
                                          id="delete"
                                          value={_id}
                                          className="btn btn-sm btn-danger"
                                          onClick={this.handleDelete.bind(this)}
                                        >
                                          delete
                                        </button>

                                        <a
                                          href={`${file}`}
                                          className=" btn btn-sm btn-primary"
                                          download
                                          target="_blank"
                                        >
                                          Download
                                        </a>
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                              </p>
                              <hr />
                            </div>
                          );
                        })
                      ) : (
                        <h1>No Project Yet</h1>
                      )}

                      {/* my project modal */}
                    </MDBModalBody>
                    
              

                 
                   </div>
                </div>
              </div>
            </div>
         
        );
    }
}
