import React, { Component } from "react";

import { getAllproject } from "../../apidata/api";
import Loading from '../../Layout/Loader/Loader'
import "../../assets/style.css";

class Easysearch extends Component {
  constructor() {
    super();
    this.state = {
      allproject: [],
      token: "",
      searchText: "",
      loading: true
    };
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleSearch(e) {
    this.setState({ searchText: e.target.value });
  }

  async componentWillMount() {
    const projects = await getAllproject();
    this.setState({
      allproject: projects,
      loading: false
    });
  

    const token = localStorage.getItem("token");
    this.setState({ token });
  }

  render() {
   ;
  
    if(this.state.loading) {
      return <Loading />
    }
    return (
      <div>
        <div className="col-lg- container">
          {/* {<Search /> } */}

         
            <div className="md-form my-0 alert alert-primary">
           We  have {this.state.allproject.length} Project Doc's.
              
            </div>
         
        </div>
      </div>
    );
  }
}

export default Easysearch;
