import React, { Component } from 'react'
// import VisitorNavbar from '../../Layout/Header/Navbar/VisitorNavbar'
// import UsersNavBarHeader from "../../Layout/Header/Navbar/UsersNavBarHeader";
import {Link} from 'react-router-dom'

export default class Aboutus extends Component {
    render() {
          const token = localStorage.getItem('token')
        return (
            <div >
              
                <div  className="container mt-5">
                    <div className="row col-lg-12 col-12 text-center " >
                       
                        
        
                    <p className="mt-5"><p style={{ fontSize: "1.5em", color: "#022B69", fontWeight: "bold" ,textAlign:'center'}}>ProjectVersity</p> 
                        The Projectversity is An Educational application built for the sharing of knowledge, docs among Africans and students worldwide. Hosting a wide range of various Educational research, which serve as means of knowledge preservation and sharing of research knowledge and documents, where you can decide to monetize or not.</p>
                    <p>
                        As a final year students you can access differents projects documents  to reviews and use in your project work. As a graduate you share your work with other students to use.
                    </p>
                    <p className="text-center">At Projectversity You can decide to earn  from their past project paper by monetize it. after monetize request the project owner  earn as people use the research work. <Link to="/signin"> Join projectVersity</Link></p>
                   
                    </div>
                </div>
                
            </div>
        )
    }
}
