import React, { Component } from "react";
import { Link } from "react-router-dom";
import { projectComment, userProfile ,DeleteComment} from "../../apidata/api";
import moment from "moment";
import ProcessLoader from "../../assets/ProcessLoader";


export default class Comment extends Component {
  constructor(props) {
    super();
    this.state = {
      comment: "",
      userId: "",
      name: "",
      commentInfo:"",
      commentId:"",
      commentdisplay: [],
      
      isProcess: false,
    };
  }
  async componentDidMount(props) {
    const userId = await JSON.parse(localStorage.getItem("userId"));
    this.setState({ userId: this.props.userId });

    const comment = await projectComment(this.props.id);
    
    comment.sort().reverse();
    this.setState({ commentdisplay: comment });

    const user = await userProfile(userId);
    this.setState({ name: user.name, userId: user._id });
  }

  // async componentDidUpdate(props) {
  //   const comment = await projectComment(this.props.id);

  //     comment.sort().reverse();
  //     this.setState({ commentdisplay: comment });

  // }
  handleComment(e) {
    this.setState({ comment: e.target.value });
    //    console.log(e.ref.comment.target.value);
  }
  handleUserId(e) {
    this.setState({ userId: e.target.value });
  }
  handlePostComment(e) {
    e.preventDefault();

    this.handleAddComment(this.props.id);
  }
  handleAddComment(id) {
    this.setState({ isProcess: true });
    if(!this.state.userId){
      window.location.href=('/signin')
      this.setState({ isProcess: false });
    }
    if (!this.state.comment) {
      this.setState({ commentInfo: "enter comment", isProcess: false });
    
    } else {
      fetch(`/comment/${id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          comment: this.state.comment,
          userId: this.state.userId,
          name: this.state.name,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState({ comment: "", isProcess: false });
          this.componentDidMount()
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isProcess: false });
        });
    }
    //console.log(this.state)
  }

  async handleDeleteComment(){
    if (window.confirm('are you sure you want to delete')) {
    const btnc = document.getElementById("deletecomment");
    const cid = btnc.value;
    console.log(btnc)
    console.log(cid);
    const deleteComment = await DeleteComment(cid);
     
    this.setState({commentInfo:deleteComment})
    this.componentDidMount()
    }
    
  }
  
  render() {
    const { comment, commentdisplay } = this.state;

    return (
      <div className="container">
        {commentdisplay
          ? commentdisplay.map((pro, index) => {
           
              const { _id, name,  comment, date, userId } = pro;
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor: "#efefef",
                    padding: "0px 5px ",
                    marginBottom: "2px",
                    borderRadius: "10px",
                  }}
                >
                  {comment} <br />{" "}
                  <p style={{ fontSize: "", fontStyle: "italic" }}>
                    <small>
                      <Link to={`/profile/${userId}`}>{name}</Link>
                      &nbsp;&nbsp;&nbsp; {moment(date).format("DD/MM/YYYY")}
                    </small>
                    &nbsp; &nbsp; &nbsp;
                    <span>
                      {userId === this.state.userId ? (
                        <span>
                          <button
                            style={{
                              fontSize: "15px",
                              fontStyle: "italic",

                              color: "red",
                              // borderRadius: "40%",
                              // boxShadow: "2px 2px 4px black",
                              padding: "0px 3px",
                            }}
                            to="#"
                            className="btn btn-sm btn-danger fa fa-trash-o"
                            value={_id}
                            id="deletecomment"
                          
                            onClick={this.handleDeleteComment.bind(this)}
                          >
                           
                          </button>
                        </span>
                      ) : null}
                    </span>
                  </p>
                </div>
              );
            })
          : null}
        {this.state.commentInfo !== "" ? (
          <div className="alert alert-warning">{this.state.commentInfo}</div>
        ) : null}
        <div className="row">
          <div className="form-group col-lg-6  col-6">
            <textarea
              type="text"
              // style={{width:'50%'}}
              id="form10"
              className="md-textarea form-control  m-0"
              rows="1"
              ref="comment"
              name="comment"
              value={comment}
              onChange={this.handleComment.bind(this)}
              placeholder="&#x270d;  add comment"
            />
            <input type="hidden" value={this.state.name} />
            {/* <label htmlFor="form10">Summary</label> */}
          </div>
          <div className="col-lg-4  col-6 ml-0 p-0">
            <button
              type="button"
              // style={{width4:}}
              className="btn btn-primary btn-sm "
              onClick={this.handlePostComment.bind(this)}
            >
              {" "}
              {this.state.isProcess === true ? (
                <ProcessLoader />
              ) : (
                <span> post comment</span>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
