import React, { Component } from 'react'
import { searchWithDepartment } from '../../apidata/api'
import {Link } from 'react-router-dom'
// import VisitorNavbar from '../../Layout/Header/Navbar/VisitorNavbar'
// import UsersNavBarHeader from '../../Layout/Header/Navbar/UsersNavBarHeader'
import Leftsidebar from '../SidesBars/Leftsidebar'
import './searchResult.css'
import Loading from '../../Layout/Loader/Loader'

export default class SearchResult extends Component {
    constructor() {
        super()
        this.state = {
            search: [],
            dep:'',
            loading: true
        }
    }

    async componentDidMount() {
        const info = await searchWithDepartment(this.props.location.search)
      const dept= this.props.location.search.substring(12,)
    //   let regex = /^[a-zA-Z]+$/
    //   console.log(regex.test(dept))
        this.setState({search: info.info, dep:dept, loading: false })
        const token =localStorage.getItem('token')
    }

    componentDidUpdate(prevProps) {
        // will be true
        let ll = this.props.location
        let bl = prevProps.location
        const locationChanged =
            this.props.location !== prevProps.location;
       if(locationChanged){
           this.componentDidMount()
       }
       
        
    }
  
    
    
    render() {
        const {search,dep}= this.state
      let dept = decodeURI(dep).toUpperCase()
        // let tokenn= localStorage.getItem('token')
        
        let styletop={
        marginTop:'5%'
        }
        if(this.state.loading) {
            return <Loading />
        }
        return (
            <div>

              <div className='mt-' >
                {/* {tokenn ?<div id="sideBarHome"> <Leftsidebar  /> </div>:<VisitorNavbar />} */}
                </div>
             
               <hr />
           <div>
            <div className='container mt- ' id="resultt"  >
        <p className="text-center  resultt " > {search && search.length>0 ? search.length: <p>no result</p>} Search Results for &nbsp;{dept} </p>
               
                <div className="card-columns"  >
                    
                { search ?
                    search.map((searc, index) => {
                        const { _id, topic, summary, views, downloads,isVerified } = searc

                        return (
                        
                          <div
                            className="card-co"
                           
                          >
                            <div
                              className=" card card-cascade"
                              style={{
                                marginBottom: "20px",
                                width: "100%",
                                boxShadow: "",
                              }}
                            >
                              {/* <!-- Card title --> */}
                              <div className=" p-2 ">
                                {/* <!-- Title --> */}
                                 <Link to={`/projectD/${_id}`} >
                                <h6
                                  className=" text-left  "
                                  style={{
                                    color: "#000000",
                                    margin: "0px",
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    padding: "0px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {" "}
                                 
                                  {topic}{" "}
                                  {isVerified === true ? (
                                    <span
                                      className="fa fa-check-circle"
                                      aria-hidden="true"
                                       style={{color:'green'}}
                                    ></span>
                                  ) : null}
                                </h6></Link>
                                {/* <!-- Text --> */}
                              </div>

                              {/* <!-- Card content --> */}
                              <div className=" p-1 text-left">
                               

                                <p className="card-text" style={{color:'gray', fontSize:'14px'}}>
                                  {summary.substring(0, 130)}..
                                  <Link to={`/projectD/${_id}`}>
                                    {/* <button className="btn  btn-sm btn-outline " style={{ 'backgroundColor': '#fff', border: '1px  groove gray', color: '#000' }}> detail</button> */}
                                    Learn More
                                  </Link>
                                </p>
                              </div>
                                
                              {/* <!-- Card content --> */}

                              {/* <Link to={`/comment/${_id}`}>
          <button className="btn  btn-sm btn-primary " >comment</button>
        </Link> */}
                              {/* <!-- Card footer --> */}
                              <div className="  text-center  ">
                                <ul className="list-unstyled list-inline font-small">
                                  <li className="list-inline-item pr-2">
                                    <a href="#" className="">
                                      <i className="mdi mdi-cloud-download pr-1"></i>
                                      {downloads.length}
                                    </a>
                                  </li>

                                  <li className="list-inline-item pr-2">
                                    <a href="#" className="">
                                      <i className="mdi mdi-eye-outline pr-1">
                                        {" "}
                                      </i>
                                      {views.length}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {/* <!-- Card --> */}
                          </div>
                        );
                    }) : <h1>no project available</h1>

                }
                </div>
                </div>
            </div>
            </div>
        )
    }
}
