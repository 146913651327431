import React, { Component } from "react";

import Projects from "./listProjects/Moreprojectslist";
import "../assets/style.css";


import RightSidebar from "./SidesBars/rightSidebar";
// import UsersNavBarHeader from "../Layout/Header/Navbar/UsersNavBarHeader";
// import VisitorNavbar from '../Layout/Header/Navbar/VisitorNavbar'
import Search from "../Pages/listProjects/easysearch";
// import SearchBox from "./searchResult/searchResult";
// import SearchResult from './searchResult/searchResult'


export default class Moreprojects extends Component {
  constructor() {
    super();
    this.state = {
      
      id: "",
      name: "",
      userAvater: ""
    };
  }

//   async componentWillMount() {
    
    

//     const id = await window.localStorage.getItem("userId");
//     if (id) {
//       await this.setState({ id: id });
     
//     } else {
//       this.props.history.push("/");
//     }
//   }
//   async componentDidMount() {
//     const token = await JSON.parse(localStorage.getItem("token"));
//     const id = await JSON.parse(localStorage.getItem("userId"));

//   }
  render() {
   
    //  const token = localStorage.getItem('token')


    return (
      <div>
       

      
       
        <div className="container">
          <div className="row  ">
            
           

            <div className="col-lg-9 ">
              
             
              <Projects />
            </div>

            <div
              className="col-lg-3 mt-5"
              style={{
                position: "",
                left: ""
              }}
            >
              <Search  />
             
              <RightSidebar  />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
