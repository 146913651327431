import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
// import Loginheader from '../../Layout/Header/Navbar/usersHeader'
import uservater from "../../assets/img/useravater.jpg";
import { getUserData } from "../../apidata/api";
import { userProfile } from "../../apidata/api";
import { DeleteProject } from "../../apidata/api";
import Leftsidebar from "../SidesBars/Leftsidebar";
import Addproject from "../Addproject/Addproject";
import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBModalFooter,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import Projects from "../listProjects/projects";
import ChangePass from "./ChangePass";
import Loading from "../../Layout/Loader/Loader";

class UserProfile extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      school: "",
      phone: "",
      department: "",
      gender: "",
      bio: "",
      confirmdel: "",
      city: "",
      id: "",
      visitId: "",
      avater: "",
      info: "",
      project: [],
      modal: false,
      loading: true,
    };
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem("token"));
    const visitId = JSON.parse(localStorage.getItem("userId"));
    this.setState({ visitId: visitId });

    if (token) {
      const user = await userProfile(this.props.match.params.id);

      if (user.message === "jwt expired") {
        alert("You have to log re-Login");
        this.props.history.push("/signin");
      } else {
        this.setState({
          name: user.name,
          email: user.email,
          id: user._id,
          school: user.school,
          phone: user.phone,
          department: user.department,
          bio: user.bio,
          gender: user.gender,
          city: user.city,
          avater: user.avater,
          info: user.message,
          project: user.project,
          loading: false,
        });
      }
    }

    if (!token) {
      this.props.history.push("/");
    }
  }
  // async componentDidUpdate(){
  //   const user = await userProfile(this.props.match.params.id);
  //   console.log(user)
  //   this.setState({

  //     project: user.project
  //   });

  // }

  async handleDelete() {
    const btnn = document.getElementById("delete");
    const pid = btnn.value;
    //  console.log(pid)
    const deleteProject = await DeleteProject(pid);
    this.componentDidMount();
  }
  //  async handleConfirmDel(){

  //    let deletedd = await document.getElementById("deletedd");
  //        deletedd.style.display='none';
  //    let dispdelete =  await document.getElementById("dispdelete");
  //    dispdelete.style.display = 'block';

  // }
  //   async handleNoDel() {

  //     let deletedd = await document.getElementById("deletedd");
  //     deletedd.style.display = 'inline';
  //     let dispdelete = await document.getElementById("dispdelete");
  //     dispdelete.style.display = 'none';

  //   }
  render() {
    //  name=name.toUpperCase()
    const {
      name,
      email,
      id,
      info,
      phone,
      school,
      department,
      city,
      bio,
      avater,
      project,
      visitId,
    } = this.state;

    let StrongSt = {
      textAlign: "left",
    };
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div>
        {/* <div className="mt-5" id="sideBarHome">
          <Leftsidebar />
        </div> */}
        <div className="container ">
          <div className="row">
            <div className="col-md-3 col-lg-3 mt-5" id="hidesideBarHome">
              <Leftsidebar />
            </div>

            {/* <!-- Card profile--></div> */}
            <div className="col-lg-5 col-md-6 col-sm-6   mt-5 ">
              <div className="card mt-3 p-4 " style={{ width: "700px" }}>
                <div className="row">
                  <div className="col-3 col-md-3">
                    {/* <!-- Card image --> */}
                    <div className="vew overlay text-center">
                      {avater == null ? (
                        <div className="">
                          {" "}
                          <img
                            className="card-img-top rounded-circle "
                            src={uservater}
                            width="150px"
                            // style={{ boderRadius: "50%" }}
                          />
                          {/* user have not upload profile picture */}
                          {id == visitId ? (
                            <Link to={`/updateprofile/${id}`}>
                              <i className="fa fa-upload pl-2"></i> photo
                            </Link>
                          ) : null}
                          {/* usernot upload profile picture */}
                        </div>
                      ) : (
                        <div>
                          <img
                            className="card-img-top rounded-circle  "
                            src={avater}
                            style={{
                              // borderRadius: "50%",
                              width: "120px",
                            }}
                          />
                          <a href={`${avater}`}>
                            <div className="mask rgba-white-slight"></div>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-9 col-md-9 ">
                    {/* <!-- Card content --> */}
                    <div className="card-body text-left py-0 ">
                      {/* <!-- Title --> */}
                      <h4 className="card-title  text-capitalize font-weight-bold">
                        {name}
                      </h4>
                      {/* <!-- Text --> */}
                      <small className="">{bio}</small>
                      <div className="card-text ">
                        <ul
                          className="list-grou"
                          style={{ listStyleType: "none" }}
                        >
                          <li className="list-group-ite">
                            <strong>
                              {" "}
                              <i className="fa fa-envelope pr-2"></i>&nbsp;
                            </strong>
                            {email}
                          </li>
                          <li className="list-group-ite">
                            <strong>
                              <i className="fa fa-phone pr-2"></i>&nbsp;
                            </strong>
                            {phone}
                          </li>
                          <li className="list-group-ite">
                            <strong>
                              <i className="fa fa-university pr-2"></i>&nbsp;
                            </strong>
                            {school}
                          </li>
                          <li className="list-group-ite">
                            {" "}
                            <strong>
                              <i className="fa fa-building pr-2"></i>&nbsp;
                            </strong>
                            {department}
                          </li>
                          <li className="list-group-ite">
                            {" "}
                            <strong>
                              <i className="fa fa-map-marker pr-2"></i>&nbsp;
                            </strong>
                            {city}
                          </li>
                          <li className="list-grou m-">
                            {" "}
                            <strong></strong>
                          </li>
                        </ul>
                      </div>
                      {id == visitId ? (
                        <div>
                          <Link
                            to={`/updateprofile/${id}`}
                            className="text-center ml-4"
                          >
                            {" "}
                            <button className="btn btn-outline btn-sm text-primary text-center ">
                              <i className="fa fa-edit "></i> update profile
                            </button>
                          </Link>{" "}
                          <button
                            className="btn  btn-sm btn-outline text-primary "
                            onClick={this.toggle}
                          >
                            Change Password
                          </button>{" "}
                        </div>
                      ) : null}
                      <hr />
                    </div>
                  </div>
                </div>

                <h4 className="text-muted row ">
                  {id === visitId ? (
                    <>
                      {" "}
                      <span className="col-6 col-md-6">
                        Your upload projects
                      </span>
                      <span className="col-6 col-md-6">
                        <Link
                          to={`/userdash`}
                          className="btn btn-sm btn-primary"
                        >
                          manage projects
                        </Link>
                      </span>
                    </>
                  ) : (
                    `${name} projects`
                  )}
                </h4>
                <hr />

                {project.length > 0 ? (
                  project.map((project, index) => {
                    const {
                      topic,
                      school,
                      department,
                      userId,
                      _id,
                      isVerified,
                      summary,
                    } = project;
                    return (
                      <div className="well" key={index}>
                        <div>
                          {index + 1}:-&nbsp;{" "}
                          <Link
                            to={`/projectD/${_id}`}
                            className="text-capitalize py-2 "
                          >
                            {topic}{" "}
                          </Link>
                          {isVerified === true ? (
                            <span
                              className="fa fa-check-circle"
                              aria-hidden="true"
                              style={{ color: "green" }}
                            ></span>
                          ) : null}
                          <br />
                          {userId == visitId ? (
                            <div>
                              {/* <Link
                                  to={`/editmonetize/${_id}` }
                                  className="btn btn-sm btn-primary"
                                >
                                Monetize
                                </Link>

                                <button
                                  id="delete"
                                  value={_id}
                                  className="btn btn-sm btn-danger"
                                  onClick={this.handleDelete.bind(this)}
                                >
                                  delete
                                </button> */}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    {id === visitId ? (
                      <p>
                        {" "}
                        you have not upload any project yet{" "}
                        <Link to={`/addproject/${id}`}>
                          click to upload
                        </Link>{" "}
                      </p>
                    ) : (
                      <p>No project yet</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <!-- Card --> */}

          <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
            <MDBModalHeader toggle={this.toggle}>
              <h4> Change Password </h4>{" "}
            </MDBModalHeader>
            <MDBModalBody>
              <ChangePass />
            </MDBModalBody>
          </MDBModal>
        </div>
      </div>
    );
  }
}

export default UserProfile;
