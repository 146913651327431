import React, { Component } from "react";
// import VisitorNavbar from '../../Layout/Header/Navbar/VisitorNavbar'
// import UsersNavBarHeader from "../../Layout/Header/Navbar/UsersNavBarHeader";
import { Link } from "react-router-dom";

export default class Disclaimer extends Component {
  render() {
    const token = localStorage.getItem("token");
    return (
      <div>
        <div className="container mt-5">
          <div className="row col-lg-12 col-12 text-left ">
            <p className="mt-5">
              <p
                style={{
                  fontSize: "1.5em",
                  color: "#022B69",
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Disclaimer: Projectversity - Research Documentation Platform
              </p>
              This document serves as a disclaimer governing the use of
              materials and resources available on the Projectversity platform.
              By accessing and utilizing the content provided on Projectversity,
              you agree to the terms outlined in this disclaimer.
            </p>
            <p>
              As a final year students you can access differents projects
              documents to reviews and use in your project work. As a graduate
              you share your work with other students to use.
            </p>
            <p className="text-cent">
              1. <b>Research Purposes Only:</b> - All documents, research
              papers, and materials available on Projectversity are intended
              solely for educational and research purposes. Users are expected
              to use these resources responsibly and in accordance with academic
              integrity standards.
              <br />
              2. <b>No Endorsement or Guarantee:</b> - Projectversity does not
              endorse, guarantee, or make any representations regarding the
              accuracy, completeness, or reliability of the materials available
              on the platform. Users are responsible for verifying the
              authenticity and appropriateness of any content they choose to
              download. <br />
              3. <b>No Plagiarism or Unauthorized Use:</b> - Users are expressly
              prohibited from engaging in plagiarism or any unauthorized use of
              the materials obtained from Projectversity. Projectversity is not
              responsible for any consequences resulting from users' failure to
              adhere to ethical and legal standards related to academic
              integrity. <br />
              4. <b>User Responsibility:</b> - Users are solely responsible for
              ensuring that their use of the materials complies with applicable
              laws, academic regulations, and ethical standards. Projectversity
              is not liable for any misuse or misconduct by users. <br />
              5. <b>No Legal Liability:</b> - Projectversity, its creators,
              administrators, and affiliates shall not be held legally liable
              for any damages, losses, or consequences arising from the use,
              misuse, or reliance on the materials provided on the platform.{" "}
              <br />
              6. <b>Updates and Changes:</b> - Projectversity reserves the right
              to update, modify, or change this disclaimer at any time. Users
              are encouraged to review the disclaimer regularly for any updates.
              By using Projectversity, you acknowledge that you have read,
              understood, and agreed to the terms of this disclaimer. ---
              <Link to="/signin"> Join projectVersity</Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
