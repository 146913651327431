import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { getAllproject } from "../../apidata/api";
export default class RecentProject extends Component {
    constructor() {
        super();
        this.state = {
            all: []
        };
    }

    async componentWillMount() {
        const data = await getAllproject();
        this.setState({ all: data });
       
    }
    render() {
        const all = this.state.all;
        let a = all.slice(0, 6);
        return (
            <div>
                <div className={`card ${this.props.className}`} style={this.props.noshadow}>
                    <h5 className="card-header ">Recent projects</h5>
                    <ul className="list-group">
                        {a ? (
                            a.map((pro, ind) => {
                                const { _id, topic, summary } = pro;
                                return (
                                    <li className="list-group-item" key={ind} id="recentprojectlist"  >
                                        <Link to={`/projectD/${_id}`} style={{textTransform:'capitalize'}}> {topic} </Link>
                                    </li>
                                );
                            })
                        ) : (
                                <div> No recent project</div>
                            )}
                    </ul>
                </div>
            </div>
        )
    }
}
