import React, { Component } from 'react'
import { MDBRow, MDBCol, MDBView, MDBCard, MDBCardBody, MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact'
import SideBarNav from '../layout/sideNavigation'
import './admin.css'

import {Link} from "react-router-dom"
import {allUsers} from '../../components/apidata/api'

export default class UsersTable extends Component {
  constructor(){
    super()
    this.state={
      users:[]

    }
  }




  async componentWillMount(){
    const users = await allUsers()
   
    if(users){
        this.setState({users:users})
    }
  }
  
  render() {
    return (
      <div>
        < div className = "flexible-content" >
      <SideBarNav />
        <div id = "content"
      className = "p-2" >
      <MDBRow>
      <MDBCol md="12">
        <MDBCard className="mt-5">
          <MDBView className="gradient-card-header blue darken-2">
            <h4 className="h4-responsive text-white">USERS DATA</h4>
          </MDBView>
          <MDBCardBody>
            
           
            < MDBTable scrollY maxHeight = '400px'
            hover >
              <MDBTableHead color='dark'>
                <tr>
                  <th>S/N</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>phone</th>
                  <th>school</th>
                  <th>department</th>
                  <th>city</th>
                  <th>Actions</th>

                </tr>
              </MDBTableHead>
              <MDBTableBody>

                 {this.state.users.map( (user, index) => {
                   const {name,email,phone,school,city,_id,department}=user
                  return( <tr key={index}>
                  <td>{index+1}</td>
                  <td>{name}</td>
                  <td>{email}</td>
                  <td>{phone}</td>
                  <td>{school}</td>
                  <td>{department}</td>
                  <td>{city}</td>
                  <td><Link className='btn btn-sm btn-warning'   to={`/admin/view/user/${_id}`} >view</Link> </td>
                
                  
                 
                  
                  
                
                </tr>
                )
                  })
                  }
              </MDBTableBody>
            </MDBTable>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
    </div> 
    </div>

      </div>
    )
  }
}



