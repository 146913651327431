import React from "react";
import VisitorNavbar from "./Navbar/VisitorNavbar";
import AdmitopNabar from '../../../admincomponents/layout/topNavigation'
import UserNavBar from "./Navbar/UsersNavBarHeader";

import "./Header.css";
const Header = () => {
  const token = window.localStorage.getItem("token");
  const admintoken = window.localStorage.getItem("admintoken");
  if (token) {
    return (
      <div>
        <UserNavBar />
      </div>
    );
  }
  else if(admintoken) {
    return(
    <div className="">
      <AdmitopNabar/>
   
    </div>
  );}else{ 
    return(<div>
     <VisitorNavbar/>

  </div>

  )

  }
};

export default Header;
