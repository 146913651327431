import React, { Component } from 'react'
import{Link} from 'react-router-dom'
import './Hmenu.css'

export default class Hmenu extends Component {
    constructor() {
        super()
        this.state = {
            id:"",

        }
    }
   

   
     async componentWillMount() {
    const id = await JSON.parse(localStorage.getItem("userId"));

    
    if (id) {
      this.setState({ id: id });
    }
    
    
}
    render() {
         const {id} = this.state;
        const style = {
            position: 'fixed',
            zIndex: '1',
            backgroundColor: '#ffffff', boxShadow: '0px 2px 5px gray'

        }
        return (
            <div >
                <div className=" c" >

                    <p style={style} className="p-2 hmenu"> <Link to={`/home`} > Home </Link> |
                            <Link to="/listdepartment">
                               Project Category </Link>
                            
                                  | <Link to={`/addproject/${id}`} className=''>
              Upload{" "}
            </Link>
            |
            <Link to={`/profile/${id}`}  >
              Profile{" "}
            </Link>|
            <Link to={`/userdash`}  >
              Dashboard{" "}
            </Link>|
             <Link to={`/`} >My Project  </Link>|
             <Link to={`/`} >Notifications </Link>
             |
             <Link to={`/user/cashout`} >Earning  </Link>|

              <Link to="/complain"  >
              Contact Us{" "}
            </Link>
            
                        
                       </p>


                  
                </div>
            </div>
        )
    }
}
