import React, { Component } from 'react'
import { adminGetAllBlogPost } from "../../apidata/api"
import { Link } from 'react-router-dom'
export default class SidebarPost extends Component {
    constructor() {
        super()
        this.state = {
            allpost: []
        }
    }
    async componentDidMount() {
        const allpost = await adminGetAllBlogPost()
        if (allpost) {
            this.setState({ allpost: allpost })
            
        }
    }
    render() {
        const { allpost } = this.state
        const all = allpost;
        let a = all.slice(0, 6);
        return (
            <div>
                <div className="card my-4">
                    <h5 className="card-header">Search</h5>
                    <div className="card-body">
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search for..."
                            />
                            <span className="input-group-a">
                                <button className="btn btn-primary btn-sm" >
                                    Go!
                      </button>
                            </span>
                        </div>
                    </div>
                </div>


                {/* Side Widget */}
                <div className="card my-4">
                    <h5 className="card-header">Recent Post</h5>
                    <div className="card-body">
                        <ul className="list-group">
                            {a ? (
                                a.map((blog, ind) => {
                                    const { _id, title } = blog;
                                    return (
                                        <li className="list-group-item" key={ind} >
                                            <Link to={`/projectD/${_id}`}> {title} </Link>
                                        </li>
                                    );
                                })
                            ) : (
                                    <div> No recent project</div>
                                )}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
