import React from "react";

const Paginate = ({ postsPerPage, totalPosts, paginate }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <nav className=" mt-5 text-center container row mr-2">
     
      <ul className="pagination col-12">
        
        {pageNumbers.map((number) => {
          return (
            <div className=" text-center ">
            <li key={number} className="page-item">
              <a
                href="#"
                className="page-link text-center "
                style={{
                  backgroundColor: "white",
                  color: "blue",
                  border: "1px solid blue",
                }}
                onClick={() => paginate(number)}
              >
                {number}
              </a>
            </li>
            </div>
          );
        })}
      </ul>
    </nav>
  );
};

export default Paginate;
