import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Addproject from "./components/Pages/Addproject/Addproject";
import Signup from "./components/Pages/Signup/Signup";
import Signin from "./components/Pages/Signin/SignInPage";
import LandingPage from "./components/Pages/LandingPage/LandingPage";
import AllprojectsUser from "./components/Pages/listProjects/allprojectslist";
import ProjectD from "./components/Pages/projectDetails/projectD";
import UserProfile from "./components/Pages/user/userProfile";
import UploadDoc from "./components/Pages/Addproject/UploadDoc";
import Userhome from "./components/Pages/userhome";
import UpdateProfile from "./components/Pages/user/updateProfile";
import Userdashboard from "./components/Pages/userdashboard";
import Comment from "./components/Pages/projectDetails/Comment";
import ProjectEditMonetize from "./components/Pages/projectDetails/ProjectEditMonetize";
import VisitorProject from "./components/Pages/listProjects/VisitorProject";
import DepartmentList from "./components/Pages/projectDetails/DepartmentList";
import Blogs from "./components/Pages/blog/bloghome";
import BlogPost from "./components/Pages/blog/blogpost";

// pays
import Paystack from "./components/Pages/projectDetails/paystack";
// admin call
import DashboardPage from "./admincomponents/pages/DashboardPage";
import ProfilePage from "./admincomponents/pages/ProfilePage";
import UsersTable from "./admincomponents/pages/UsersTable";
import SignupAdmin from "./admincomponents/pages/SignupAdmin/SignupAdmin";
import SignInPageAdmin from "./admincomponents/pages/SigninAdmin/SignInPageAdmin";
import AllProject from "./admincomponents/pages/Allprojects";
import FreeProject from "./admincomponents/pages/FreeProject";
import PaidProject from "./admincomponents/pages/PaidProject";
import PendingMonetize from "./admincomponents/pages/PendingMonetize";
import BuyersTable from "./admincomponents/pages/Buyers";
import CashoutTable from "./admincomponents/pages/CashoutRequest";
import PaidCashout from "./admincomponents/pages/PaidCashout";
import AllCashoutTable from "./admincomponents/pages/AllCashout";
import AllComplain from "./admincomponents/pages/AllComplain";
import AdminContactus from "./admincomponents/pages/Contactus";
import Complain from "./components/Pages/user/complain";
import DepartmentPro from "./components/Pages/projectDetails/DepartmentProject";
import SearchDisplay from "./components/Pages/searchResult/searchResult";
import Cashout from "./components/Pages/user/Cashout/Cashout";
import Contactus from "./components/Pages/contactus";
import Aboutus from "./components/Pages/LandingPage/Aboutus";
import Error404 from "./components/Pages/Error/error404";
import VisitProjectD from "./components/Pages/projectDetails/VisitProjectD";
import CreatePost from "./admincomponents/pages/Blog/CreatePost";
import AllPost from "./admincomponents/pages/Blog/AllPost";
import EditPost from "./admincomponents/pages/Blog/EditPost";
import SingleUser from "./admincomponents/pages/SingleUser";
import SearchUser from "./admincomponents/pages/SearchUser";
import Faq from "./components/Pages/documentation/Faq";
import Team from "./components/Pages/team";
import TandC from "./components/Pages/documentation/TandC";
import Donation from "./components/Pages/documentation/donation";
import ConfirmMail from "./components/Pages/Signup/ConfirmMail";
import newProject from "./admincomponents/pages/newProject";
import ProjectView from "./admincomponents/pages/ProjectView";
import Myproject from "./components/Pages/Myproject";
import Moreprojects from "./components/Pages/Moreprojects";
import ForgetPass from "./components/Pages/ForgetPass";
import NewUserRegSuccess from "./components/Pages/Signup/registerSuccess";
import Privacypolicy from "./components/Pages/documentation/Privacypolicy";
import Disclaimer from "./components/Pages/documentation/Disclaimer";

class Router extends Component {
  render() {
    return (
      <div>
        <Switch>
          {/* user */}
          <Route path="/" strict exact={true} component={LandingPage} />
          <Route path="/signin" strict exact={true} component={Signin} />
          <Route path="/signup" strict exact={true} component={Signup} />
          <Route
            path="/fgpassword"
            strict
            exact={true}
            component={ForgetPass}
          />
          <Route
            path="/notveriuser"
            strict
            exact={true}
            component={NewUserRegSuccess}
          />

          <Route
            path="/confirmation/:email/:token"
            strict
            exact={true}
            component={ConfirmMail}
          />
          <Route
            path="/profile/:id"
            strict
            exact={true}
            component={UserProfile}
          />
          <Route
            path="/updateprofile/:id"
            strict
            exact={true}
            component={UpdateProfile}
          />
          <Route path="/complain" exact={true} component={Complain} />
          <Route path="/user/cashout" exact={true} strict component={Cashout} />
          <Route path="/contactus" exact={true} strict component={Contactus} />
          <Route path="/aboutus" exact={true} strict component={Aboutus} />
          <Route
            path="/disclaimer"
            exact={true}
            strict
            component={Disclaimer}
          />
          <Route path="/team" exact={true} strict component={Team} />

          {/* project */}
          <Route
            path="/editmonetize/:id"
            exact={true}
            component={ProjectEditMonetize}
          />
          <Route
            path="/addproject/:id"
            strict
            exact={true}
            component={Addproject}
          />
          <Route
            path="/projects"
            strict
            exact={true}
            component={AllprojectsUser}
          />
          <Route
            path="/moreprojects"
            strict
            exact={true}
            component={Moreprojects}
          />
          <Route path="/myproject" strict exact={true} component={Myproject} />
          <Route
            path="/projectD/:id"
            strict
            exact={true}
            component={ProjectD}
          />
          <Route path="/upload/:id" strict exact={true} component={UploadDoc} />
          <Route
            path="/department/:dp"
            strict
            exact={true}
            component={DepartmentPro}
          />
          <Route path="/home" strict exact={true} component={Userhome} />
          <Route
            path="/userdash"
            exact={true}
            strict
            component={Userdashboard}
          />
          <Route path="/comment/:id" strict exact={true} component={Comment} />
          <Route path="/paypro" exact={true} component={Paystack} />
          <Route
            path="/exploreprojects"
            exact={true}
            component={VisitorProject}
          />
          <Route path="/search" strict exact={true} component={SearchDisplay} />
          <Route
            path="/listdepartment"
            strict
            exact={true}
            component={DepartmentList}
          />
          <Route
            path="/visitprojectd/:id"
            strict
            exact={true}
            component={VisitProjectD}
          />
          <Route path="/blogs" strict exact={true} component={Blogs} />
          <Route
            path="/blogpost/:id"
            strict
            exact={true}
            component={BlogPost}
          />
          {/* support route */}
          <Route path="/faq" strict exact={true} component={Faq} />
          <Route path="/terms" strict exact={true} component={TandC} />
          <Route
            path="/privacypolicy"
            strict
            exact={true}
            component={Privacypolicy}
          />

          <Route path="/support" strict exact={true} component={Donation} />
          {/* admin Route */}
          <Route
            path="/admindashboard"
            exact={true}
            component={DashboardPage}
          />
          <Route path="/admin/profile" component={ProfilePage} />
          <Route path="/admin/users" component={UsersTable} />
          <Route path="/admin/view/user/:id" component={SingleUser} />
          <Route path="/admin/search/user" component={SearchUser} />
          <Route path="/adminsignup" component={SignupAdmin} />
          <Route path="/adminsignin" component={SignInPageAdmin} />
          <Route path="/admin/projects" component={AllProject} />
          <Route path="/admin/freep" component={FreeProject} />
          <Route path="/admin/newproject" component={newProject} />
          <Route path="/admin/view/project/:id" component={ProjectView} />
          <Route path="/admin/paidp" component={PaidProject} />
          <Route path="/admin/pendingp" component={PendingMonetize} />
          <Route path="/admin/buyers" component={BuyersTable} />
          <Route path="/admin/cashout" component={CashoutTable} />
          <Route path="/admin/approvedcashout" component={PaidCashout} />
          <Route path="/admin/allcashout" component={AllCashoutTable} />
          <Route path="/admin/complain" component={AllComplain} />
          <Route path="/admin/contactus" component={AdminContactus} />
          {/* blog */}
          <Route path="/admin/createblog" component={CreatePost} />
          <Route path="/admin/blogposts" component={AllPost} />
          <Route path="/admin/post/edit/:id" component={EditPost} />

          <Route path="*" strict exact={true} component={Error404} />
        </Switch>
      </div>
    );
  }
}

export default Router;
