import React, {
    Component
} from 'react';
import logo from "../../components/assets/img/projectbuklogo.png";
import {
    MDBListGroup,
    MDBListGroupItem,
    MDBIcon,
    MDBDropdown,MDBDropdownItem,MDBDropdownMenu,MDBDropdownToggle,
   
} from 'mdbreact';
import { NavLink, Link } from 'react-router-dom';

import '../pages/admin.css'
import './sideNav.css';

// const TopNavigation = () =>

class TopNavigation extends Component {
   state = {
       collapse: false,
       id: '',
       name: '',
       admin: []
   }

   onClick = () => {
       this.setState({
           collapse: !this.state.collapse,
       });
   }

   toggle = () => {
       this.setState({
           dropdownOpen: !this.state.dropdownOpen
       });
   }
   async componentWillMount() {
    const token = localStorage.getItem('adminuserId')
     if(!token){
       window.location.href='/adminsignin'
    // this.props.history.push('/adminsignin')
     }
}

//  async componentDidMount(){
      
//  }
    render(){
       
    return (
 
        <div>
         

                  
        <div className= "adminsidebar-fixed position-fixed"
  /*style = {
      {
          backgroundColor: '#0E1E25'
      }
  }*/  id="sidebaradmin">
            {/* <a href="#!" className="logo-wrapper waves-effect">
                <img alt="MDB React Logo" className="img-fluid" src={logo}/>
            </a> */}
                  <Link
          className="navbar-brand mb-5  "
          to="/home"
          style={{
            fontSize: "2em",
            color: "#022B69",
            fontWeight: "bold"
          }}
         
        >
          ProjectVersity&#x26d6;
        </Link>
          
            <MDBListGroup className="list-group-flush">
                <NavLink exact={true} to="/admindashboard" activeClassName="activeClass">
                    <MDBListGroupItem>
                        <MDBIcon icon="chart-pie" className="mr-3"/>
                        Dashboard
                    </MDBListGroupItem>
                </NavLink>
                 

                
                 <MDBDropdown>
      <MDBDropdownToggle caret color="primary">
           <MDBIcon icon="user" className="mr-3 "/>
     User
      </MDBDropdownToggle>
      <MDBDropdownMenu basic>
        <MDBDropdownItem><NavLink to="/admin/users" activeClassName="activeClass">
               
                       
                        Users
              
               


                </NavLink></MDBDropdownItem>
        <MDBDropdownItem><Link to='/admin/search/user'>Search</Link></MDBDropdownItem>
        
      </MDBDropdownMenu>
    </MDBDropdown>
               
               
                 <MDBDropdown>
      <MDBDropdownToggle caret color="primary">
       Contactus
      </MDBDropdownToggle>
      <MDBDropdownMenu basic>
        <MDBDropdownItem><NavLink to="/admin/complain" activeClassName="activeClass">
                         Complain
               


                </NavLink></MDBDropdownItem>
        <MDBDropdownItem><Link to='/admin/contactus'>Contactus</Link></MDBDropdownItem>
        
      </MDBDropdownMenu>
    </MDBDropdown>
                
                
                
                
                
                
                 <MDBDropdown>
      <MDBDropdownToggle caret color="primary">
      Projects
      </MDBDropdownToggle>
      <MDBDropdownMenu basic>
          <MDBDropdownItem>
               <NavLink to = "/admin/newproject"
              activeClassName = "activeClass" >


                  New Projects


                  </NavLink></MDBDropdownItem >
                  <MDBDropdownItem >
              <NavLink to="/admin/pendingp" activeClassName="activeClass">
                   
                       PendingMonetize
              
               


                </NavLink></MDBDropdownItem>
        <MDBDropdownItem>
                <NavLink to="/admin/paidp" activeClassName="activeClass">
                   

                        PaidProject
                   
               


                </NavLink></MDBDropdownItem>
        <MDBDropdownItem><NavLink to="/admin/freep" activeClassName="activeClass">
                   
                       
                        FreeProjects
                  
        
                </NavLink></MDBDropdownItem>
        <MDBDropdownItem> <NavLink to="/admin/projects" activeClassName="activeClass">
                   
                        
                        Projects
                    
        
                </NavLink></MDBDropdownItem>
                 <MDBDropdownItem >
             <NavLink to="/admin/buyers" activeClassName="activeClass">
                  
                     
                        Project Buyers
                     </NavLink>
               
</MDBDropdownItem>

              
        
      </MDBDropdownMenu>
    </MDBDropdown>
                   
                <MDBDropdown>
      <MDBDropdownToggle caret color="primary">
       Cashout
      </MDBDropdownToggle>
      <MDBDropdownMenu basic>
        <MDBDropdownItem> <NavLink to="/admin/cashout" activeClassName="activeClass">
                 
                        
                        CashoutRequst
               
               


                </NavLink></MDBDropdownItem>
        <MDBDropdownItem><NavLink to="/admin/approvedcashout" activeClassName="activeClass">
                  
                       
                        ApprovedCashout
             
               


                </NavLink></MDBDropdownItem>
        <MDBDropdownItem>
                <NavLink to = "/admin/allcashout"
                activeClassName = "activeClass" >
                 
                       
                        AllCashout
                    
               


                </NavLink></MDBDropdownItem>
        
       
      </MDBDropdownMenu>
    </MDBDropdown>
               



                

               
             <MDBDropdown>
      <MDBDropdownToggle caret color="primary">
        Blog
      </MDBDropdownToggle>
      <MDBDropdownMenu basic>
        <MDBDropdownItem><Link to='/admin/createblog'>Createpost</Link></MDBDropdownItem>
        <MDBDropdownItem > <Link to='/admin/blogposts' > AllPost </Link></MDBDropdownItem >
                
      </MDBDropdownMenu>
    </MDBDropdown>

                
             
            
            </MDBListGroup>
        </div>
        </div>
    );
    }
}

export default TopNavigation;