import React, { Component } from "react";
import SideBarNav from "../layout/sideNavigation";
import {

  DeleteProject,
  getSingleProjectA,
  projectViews,AdminData
} from "../../components/apidata/api";
import { Link } from "react-router-dom";
import Axios from "axios";
import moment from 'moment';

export default class ProjectView extends Component {
  constructor() {
    super();
    this.state = {
   name:"",
      project: null,
      topic: "",
      department: "",
      school: "",
      year: Date,
      summary: "",
      date: Date,
      id: "",
      projectdoc: "",
      buyUser: "",
      buyProject: "",
      buyStatus: "",

      views: "",
      uploadby: "",
      userId: "",
      projectUserId: "",
      downloads: "",
      status: "",
      approved: Boolean,
      softUrl: "",
      price: "",
   info:'',
      
      isLoading: false,
      loading: true,
    };
  }
  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem("admintoken"));
    const visitId = JSON.parse(localStorage.getItem("adminuserId"));
    this.setState({ visitId: visitId });
   

    const projectviews = await projectViews(this.props.match.params.id);

    this.setState({ views: projectviews.length });
  const admin = await AdminData(visitId);
     
      if (admin) {
        this.setState({
          name: admin.name,
        });
       
      }
    
   
    if (token) {
      //call a function in api file
      const project = await getSingleProjectA(this.props.match.params.id);

      await this.setState({
        topic: project.topic.toUpperCase(),
        school: project.school.toUpperCase(),
        department: project.department.toUpperCase(),
        year: project.year,
        id: project._id,
        projectdoc: project.file,
        summary: project.summary,
        uploadby: project.uploadedby,
        projectUserId: project.userId,
        views: project.views,
        downloads: project.downloads.length,
        status: project.status,
        approved: project.approved,
        softUrl: project.softUrl,
        price: project.price,
        projectapproved: project.projectapproved,
        loading: false,
        isVerified:project.isVerified
      });
    }
    // const project= await this.getSingleProject(this.props.match.params.id)
    // this.setState({project})
    // console.log(this.state.project + 'ok')
    // }
    else {
      //alert('You need to login to view project Details')
      const project = await getSingleProjectA(this.props.match.params.id);
      await this.props.history.push(`/visitprojectd/${project._id}`);
    }
    
  }
  async handleDeleteProject() {
   
    if (window.confirm("are you sure you want to delete")) {
      const deletePost = await DeleteProject(this.props.match.params.id);

      alert(deletePost);
      if (deletePost) {
        this.props.history.push("admin/newproject");
      }
    }
  }
  

  async SubmitVerifyProject(e) {
    e.preventDefault();

    this.setState({ isLoading: true });

    try {
      const id = this.state.id;
 const verify = {
   verifiedby: this.state.name,
   isVerified: true,

   notification: `${this.state.topic}  has been veirified `,
   userid: this.state.projectUserId,
 };
      await Axios.put(`/verify/project/${id}`,verify)

        .then((response) => {
          console.log(response);
          alert(response.data.message);
          this.setState({ info: response.data.message });
          this.setState({ isLoading: false });
          this.componentDidMount();
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          this.setState({ info: err.message });
          console.log(err);
        });
    } catch (error) {
      this.setState({ isLoading: false });
      return error.message;
    }
  }

  render() {
  const {
      topic,
      school,
      year,
      department,
     isVerified,
      projectdoc,
      uploadby,
      summary,
      views,
      projectUserId,
     
      downloads,
      status,
     
      price,
     
    } = this.state;
    
    return (
      <div className="flexible-content">
        <SideBarNav />
        <div id="content" className="p-2 container row">
          <div className="mx- col-lg-8 col-12 mt-5">
            <div className="  card   ">
              <div className="text-light">
                {this.state.info === "" ? <div>{this.state.info}</div> : null}

                {isVerified === true ? (
                  <button className="btn btn-sm btn-success" disabled>
                    <span className="fa fa-star" aria-hidden="true">
                      Verified Project
                    </span>
                  </button>
                ) : (
                  <button
                    className="btn btn-sm btn-success"
                    onClick={this.SubmitVerifyProject.bind(this)}
                  >
                    <span>Verify Project</span>
                    {this.state.isLoading == true ? (
                      <span>processing..</span>
                    ) : null}
                  </button>
                )}

                <button
                  className="btn btn-sm btn-danger"
                  id="delete"
                  onClick={this.handleDeleteProject.bind(this)}
                >
                  delete
                </button>
              </div>

              <div className="card-header p-2 text-center">
                <h4>
                  {/* <strong>{topic}</strong>
                   */}
                  Project Details
                </h4>
                {isVerified === true ? (
                  <span
                    className="fa fa-star alert alert-info p-2"
                    aria-hidden="true"
                  >
                    verified
                  </span>
                ) : (
                  <span  >
                    <small className="alert alert-warning p-2"> Not verify</small>
                  </span>
                )}
              </div>

              <div className="card-body">
                <ul className="list-group" style={{ listStyleType: "none" }}>
                  <li className="list-group-ite">
                    {" "}
                    <h5>
                      {" "}
                      Project Topic:<strong>{topic}</strong>
                    </h5>
                  </li>
                  <li className="list-group-ite">
                    Project Department:<strong>{department}</strong>
                  </li>
                  <li className="list-group-ite">
                    {" "}
                    Institution: <strong>{school}</strong>
                  </li>
                  <li className="list-group-ite">
                    {" "}
                    Project Year:{" "}
                    <strong>{moment(year).format("DD/MM/YYYY")} </strong>
                  </li>
                  <li className="list-group-ite">
                    {" "}
                    Uploadby:{" "}
                    <strong>
                      {" "}
                      <Link to={`/profile/${projectUserId}`} className="">
                        {uploadby}
                      </Link>
                    </strong>
                  </li>
                  <li className="list-group-ite">
                    {" "}
                    No oF downloads : <strong>{downloads}</strong>
                  </li>
                  <li className="list-group-ite">
                    {" "}
                    Views: <strong>{views.length}</strong>
                  </li>
                  <li className="list-group-ite">
                    {" "}
                    Status: <strong>{status}</strong>{" "}
                  </li>

                  {/* {softUrl == '' ? 'No software' : */}

                  <li className="">
                    <Link to={`${projectdoc}`} className="text-center">
                      Click here to Download
                    </Link>
                  </li>
                </ul>
                <hr />
                <div>
                  <strong className="font-weight-bold  text-center">
                    PROJECT ABSTRACT
                  </strong>{" "}
                  <hr />
                  {summary}
                </div>

                <hr />
                <strong>
                  <Link to={`${projectdoc}`}>
                    <button className="btn btn-primary btn-sm">Download</button>
                  </Link>{" "}
                </strong>

                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
