import React, { Component } from "react";
import {
  MDBRow,
  MDBCol,
  MDBView,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";
import SideBarNav from "../layout/sideNavigation";
import "./admin.css";
import moment from "moment";

import { getAllBuyers } from "../../components/apidata/api";

export default class BuyersTable extends Component {
  constructor() {
    super();
    this.state = {
      buyers: [],
      
    };

  }

  async componentDidMount() {
    const buyer = await getAllBuyers();
  
    if (buyer) {
      this.setState({ buyers: buyer });
    }
 
    
  }
  render() {
    return (
      <div>
        <div className="flexible-content">
          <SideBarNav />
          <div id="content" className="p-2">
            <MDBRow>
              <MDBCol md="12">
                <MDBCard className="mt-5">
                  <MDBView className="gradient-card-header blue d">
                    <h4 className="h4-responsive text-white">Buyers DATA</h4>
                  </MDBView>
                  <MDBCardBody>
                    <MDBTable striped scrollY
                      maxHeight="500px"
                      hover
                      className="text-dark" >
                      <MDBTableHead >
                        <tr>
                          <th>S/N</th>
                          <th>date</th>
                          <th>Name</th>
                          <th>project</th>
                          <th>amountpaid</th>
                          <th>status</th>
                          <th>reference</th>
                           <th>trans</th>
                            <th>user</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {this.state.buyers.map((buyer, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{moment(buyer.date).format('DD/MM/YY')}</td>
                                  <td>{buyer.name}</td>
                                  <td>{buyer.project}</td>
                                  <td>{buyer.amountpaid}</td>
                                  <td>{buyer.status}</td>

                                  <td>{buyer.reference}</td>
                                  <td>{buyer.trans}</td>
                                  <td>{buyer.user}</td>
                            </tr>
                          );
                        })}
                      
                      </MDBTableBody>
                    </MDBTable>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </div>
        </div>
      </div>
    );
  }
}
