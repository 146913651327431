import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { getAllproject, getDistinctDepartment, } from "../../apidata/api";
import './Leftsidebar.css'


export default class DepartmentCategory extends Component {
    constructor() {
        super();
        this.state = {
            all: [],
            departmentlists: [],
        };
    }

    async componentWillMount() {
        const data = await getAllproject();
        this.setState({ all: data });
       
    }
    async componentDidMount() {
        const departmentlist = await getDistinctDepartment()
        console.log("dep " + departmentlist)
        departmentlist.sort((a, b) => a.localeCompare(b))
        this.setState({ departmentlists: departmentlist })
    }

    render() {
        const all = this.state.all;
        let a = all.slice(0, 18);
        const {departmentlists}= this.state
        return (
            <div>
                <div className={`card ${this.props.className}`} style={this.props.noshadow}>
                    <h5 className="card-header  ">Projects Categories</h5>
                    {departmentlists  ? departmentlists.slice(0,7).map((dp, index) => {

                        return (
                            <div className="contai">
                                <ul key={index} style={{ listStyleType: 'none' }} className="list-group"><li className="list-group-item text-left" id="recentprojectlist" style={{color:'#022b69'}} >
                                    <Link  style={{color:'#022b69'}} to={`/department/${dp}`}>{dp.toUpperCase()} </Link></li>
                                    
                                    </ul>
                                
                            </div>
                        )
                            
                    })  : <h4>No department yet</h4>}
                    <ul style={{ listStyleType: 'none' }} className="list-group">                    < Link to="/listdepartment" className="list-group-item "> MORE DEPARTMENT <i className=' fa fa-chevron-circle-right'></i></Link>
                    </ul>


                </div>
            </div>
        )
    }
}
