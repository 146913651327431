import React, { Component } from "react";
import "./projectD.css";
import Axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  getSingleProjectA,
  projectViews,
  projectDownloads,
  projectComment,
  getAllBuyers,
  getSingleBuyer,
} from "../../apidata/api";
import Comments from "./Comment";
import Leftsidebar from "../SidesBars/Leftsidebar";
import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBModalFooter,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import Paystack from "./paystack";
// import RightSidbar from "../SidesBars/rightSidebar";
import DepartmentCategory from "../SidesBars/DepartmentCategory";
//import the library
import Loading from "../../Layout/Loader/Loader";

class ProjectD extends Component {
  constructor() {
    super();
    this.state = {
      project: null,
      topic: "",
      department: "",
      school: "",
      year: Date,
      summary: "",
      date: Date,
      id: "",
      projectdoc: "",
      buyUser: "",
      buyProject: "",
      buyStatus: "",

      views: "",
      uploadby: "",
      userId: "",
      projectUserId: "",
      downloads: "",
      status: "",
      approved: Boolean,
      softUrl: "",
      price: "",
      modal: false,
      loading: true,
    };
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  async componentWillMount() {
    const userId = await JSON.parse(localStorage.getItem("userId"));
    this.setState({ userId: userId });

    // const projectviews = await projectViews(this.props.match.params.id)
    // this.setState({views: projectviews.length})
  }
  //geting data from api
  async componentDidMount() {
    const userId = await JSON.parse(localStorage.getItem("userId"));
    let buy = await getSingleBuyer(this.props.match.params.id, userId);

    await this.setState({
      buyUser: buy.user,
      buyProject: buy.project,
      buyStatus: buy.status,
    });

    const projectviews = await projectViews(this.props.match.params.id);

    this.setState({ views: projectviews.length });

    const token = await JSON.parse(localStorage.getItem("token"));
    if (token) {
      //call a function in api file
      const project = await getSingleProjectA(this.props.match.params.id);

      await this.setState({
        topic: project.topic.toUpperCase(),
        school: project.school.toUpperCase(),
        department: project.department.toUpperCase(),
        year: project.year,
        id: project._id,
        projectdoc: project.file,
        summary: project.summary,
        uploadby: project.uploadedby,
        projectUserId: project.userId,
        views: project.views,
        downloads: project.downloads.length,
        status: project.status,
        approved: project.approved,
        softUrl: project.softUrl,
        price: project.price,
        projectapproved: project.projectapproved,
        isVerified: project.isVerified,
        loading: false,
      });
    }
    // const project= await this.getSingleProject(this.props.match.params.id)
    // this.setState({project})
    // console.log(this.state.project + 'ok')
    // }
    else {
      //alert('You need to login to view project Details')
      const project = await getSingleProjectA(this.props.match.params.id);
      await this.props.history.push(`/visitprojectd/${project._id}`);
    }
  }

  async Downloads() {
    const projectDownload = await projectDownloads(this.props.match.params.id);
    this.setState({ downloads: projectDownload.length });
  }
  async ClosePayment() {
    this.toggle();
    this.componentDidMount();
  }
  render() {
    const {
      topic,
      school,
      year,
      department,
      userId,
      projectdoc,
      uploadby,
      summary,
      views,
      projectUserId,
      projectapproved,
      downloads,
      status,
      approved,
      softUrl,
      price,
      buyProject,
      buyUser,
      id,
      isVerified,
      buyStatus,
    } = this.state;

    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div>
        <div className="container" style={{ fontFamily: "roboto" }}>
          <div className="row">
            <div className="col-lg-3 col-md-3 mt-5">
              <Leftsidebar />
            </div>

            <div className="col-lg-6 col-12 col-md-6 mt-5">
              <div className="  card  mt-3 ">
                <div className="card-body">
                  <ul className="list-group" style={{ listStyleType: "none" }}>
                    <li className="list-group-ite">
                      {" "}
                      <h5>
                        {" "}
                        <strong>{topic}</strong>&nbsp;
                        {isVerified === true ? (
                          <span
                            className="fa fa-check-circle"
                            aria-hidden="true"
                            style={{ color: "green" }}
                          ></span>
                        ) : null}
                      </h5>
                    </li>
                    <li className="list-group-ite">
                      Project Department:<strong>{department}</strong>
                    </li>
                    <li className="list-group-ite">
                      {" "}
                      Institution: <strong>{school}</strong>
                    </li>
                    <li className="list-group-ite">
                      {" "}
                      Project Year:{" "}
                      <strong>{moment(year).format("DD/MM/YYYY")} </strong>
                    </li>
                    <li className="list-group-ite">
                      {" "}
                      Uploadby:{" "}
                      <strong>
                        {" "}
                        <Link to={`/profile/${projectUserId}`} className="">
                          {uploadby}
                        </Link>
                      </strong>
                    </li>
                    <li className="list-group-ite">
                      {" "}
                      No oF downloads : <strong>{downloads}</strong>
                    </li>
                    <li className="list-group-ite">
                      {" "}
                      Views: <strong>{views.length}</strong>
                    </li>
                    <li className="list-group-ite">
                      {" "}
                      Status: <strong>{status}</strong>{" "}
                    </li>

                    <li className="list-group-ite">
                      {" "}
                      Project Software: <strong>{softUrl}</strong>{" "}
                    </li>
                    {/* {softUrl == '' ? 'No software' : */}

                    {approved === true && projectapproved == "approved" ? (
                      <div>
                        {buyUser == userId &&
                        buyProject == id &&
                        buyStatus === "success" ? (
                          <div>
                            {" "}
                            <li className="list-group-ite">
                              {" "}
                              Price:{" "}
                              <strong>
                                <del>N</del>
                                {price}
                              </strong>
                            </li>
                            <li className="">
                              {" "}
                              You've Bought this project before &nbsp;
                              <strong>
                                <a href={`${projectdoc}`} target="_blank">
                                  <span onClick={this.Downloads.bind(this)}>
                                    Download it Again
                                  </span>
                                </a>{" "}
                              </strong>
                            </li>{" "}
                          </div>
                        ) : (
                          <li className="">
                            {" "}
                            This project is a paid project &nbsp;
                            <Link
                              to="#"
                              onClick={this.toggle}
                              className="text-center"
                            >
                              Click here to Download
                            </Link>
                          </li>
                        )}
                      </div>
                    ) : (
                      <div>
                        {" "}
                        <li className="list-group-ite">
                          This Project is a Free project:
                          <strong>
                            <a href={`${projectdoc}`} target="_blank">
                              <span onClick={this.Downloads.bind(this)}>
                                Download for free
                              </span>
                            </a>{" "}
                          </strong>
                        </li>{" "}
                      </div>
                    )}
                    {projectUserId === userId ? (
                      <div>
                        {" "}
                        Its Your Project
                        <strong>
                          {" "}
                          <a href={`${projectdoc}`} target="_blank">
                            <span> &nbsp;Download it</span>{" "}
                          </a>{" "}
                        </strong>{" "}
                        &nbsp;
                        {projectUserId === userId && status !== "paid" ? (
                          <span>
                            {" "}
                            You can also
                            <strong>
                              <Link to={`/editmonetize/${id}`}>
                                <span> &nbsp;Monetize It</span>{" "}
                              </Link>{" "}
                            </strong>{" "}
                          </span>
                        ) : null}
                      </div>
                    ) : null}

                    <li>
                      isVerified :
                      {isVerified ? (
                        <span
                          className="fa fa-star alert alert-info p-2"
                          aria-hidden="true"
                        >
                          Verified
                        </span>
                      ) : (
                        <span>
                          <small className="alert alert-warning p-2">
                            Not Verified
                          </small>
                        </span>
                      )}
                    </li>
                  </ul>
                  <hr />
                  <div>
                    <strong className="font-weight-bold  text-center">
                      PROJECT ABSTRACT
                    </strong>{" "}
                    <hr />
                    {summary}
                  </div>

                  <hr />
                  {approved === true && projectapproved == "approved" ? (
                    <div>
                      {buyUser == userId &&
                      buyProject == id &&
                      buyStatus === "success" ? (
                        <div>
                          <strong>
                            <a href={`${projectdoc}`} target="_blank" download>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={this.Downloads.bind(this)}
                              >
                                Download project Again
                              </button>
                            </a>{" "}
                          </strong>
                        </div>
                      ) : (
                        <div className="">
                          <button
                            className="btn  btn-primary "
                            onClick={this.toggle}
                          >
                            Buy the project{" "}
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <strong>
                      <a href={`${projectdoc}`} target="_blank">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={this.Downloads.bind(this)}
                        >
                          Download for free
                        </button>
                      </a>{" "}
                    </strong>
                  )}
                  <hr />
                  <p className="font-weight-bold text-uppercase">
                    Project Reviews
                  </p>
                  <hr />

                  <Comments userId={userId} id={this.props.match.params.id} />
                </div>
                {/* <div className="card-footer">Like and Share<button className=" btn btn-sm btn-primary"  onClick={this.toggle}>Buy the Project</button>
                  </div> */}

                {/* payment modal */}
                <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                  <MDBModalHeader toggle={this.toggle}>
                    <h4> Project Payment </h4>{" "}
                  </MDBModalHeader>
                  <MDBModalBody>
                    <Paystack
                      price={price}
                      id={this.props.match.params.id}
                      projectUserId={projectUserId}
                      topic={topic}
                      projectdoc={projectdoc}
                    />
                  </MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn
                      className="btn btn-sm"
                      color="secondary"
                      onClick={this.ClosePayment.bind(this)}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModal>
              </div>
            </div>
            <div className=" contai col-lg-3 col-md-3 mt-5">
              <DepartmentCategory />
              {/* <RightSidbar/> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ProjectD;
