import React, { Component } from 'react'
import {adminGetSingleBlogPost} from '../../apidata/api'
import { Link } from 'react-router-dom'
import VisitorNavbar from '../../Layout/Header/Navbar/VisitorNavbar'

import moment from 'moment'
import UsersNavBarHeader from "../../Layout/Header/Navbar/UsersNavBarHeader";
import SidebarPost from "./SidebarPost";
export default class Blogpost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            author: "",
            title: "",
            info: "",
            content: "",
            id: '',
            isLoading: ''
        };
    }

    async componentDidMount() {


        const post = await adminGetSingleBlogPost(this.props.match.params.id)
        console.log(post)
        if (post) {
            this.setState({
                author: post.author, title: post.title, content: post.content, id: post._id,date:post.date, avater:post.avater
            })
        }
    }
    render() {
        const {author,title, date,content, avater}= this.state
        const token = localStorage.getItem('token')
        return (
            <div>
                {token ? <UsersNavBarHeader /> : <VisitorNavbar />}
                <div class="container">

    <div class="row mt-5">
                {/* Post Content Column */}
                <div className="col-lg-8">
                    {/* Title */}
                    <h1 className="mt-4">{title}</h1>
                    {/* Author */}
                    <p className="lead">
                        by
            <Link to="#">{author}</Link>
                    </p>
                    <hr />
                    {/* Date/Time */}
                            <p>Posted on {moment(date).format("DD/MM/YYYY")}</p>
                    <hr />
                    {/* Preview Image */}
                    <img className="img-fluid rounded" src={avater} alt="" />
                    <hr />
                    {/* Post Content */}
        <p className="lead">{content}</p>
                        
                    <hr />
                    {/* Comments Form */}
                    <div className="card my-4">
                        <h5 className="card-header">Leave a Comment:</h5>
                        <div className="card-body">
                            <form>
                                <div className="form-group">
                                    <textarea className="form-control" rows={3} defaultValue={""} />
                                </div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>
                        </div>
                    </div>
                    {/* Single Comment */}
                   
                    
                </div>
                {/* Sidebar Widgets Column */}
                <div className="col-md-4">
                    <SidebarPost/>
                                   </div>
                {/* /.row */}
                {/* /.container */}
                    </div>
                </div>

            </div>
        )
    }
}
