import React, { Component } from "react";
import RecentProject from './RecentProject'
import DepartmentCategory from './DepartmentCategory'

export default class rightSidbar extends Component {
  

 
  render() {
    const noshadow = {
      boxShadow: "none",
      padding: "4px!important",
      
    };
  
 

   
    return (
      <div className="container">
        <RecentProject noshadow={noshadow} />
        <DepartmentCategory noshadow={noshadow}/>
      </div>
    );
  }
}
