import React, { Component } from 'react'
import ProcessLoader from "../assets/ProcessLoader"
import {Link} from 'react-router-dom'
import {  MDBBtn } from 'mdbreact';
import axios from 'axios'
export default class ForgetPass extends Component {
     constructor() {
    super();
    this.state = {
     
      isLoading: false,
      info: "",
      emailforgetpass: "",
      forgetinfo: "",
      error: false,
      token: false,
      mood:'',
      isProcess:false,
      isPasswordShown: false,
    };

   
    this.handleEmailForgetPass = this.handleEmailForgetPass.bind(this);
  }

  
  handleEmailForgetPass(e) {
    this.setState({ emailforgetpass: e.target.value });
  }

  async handleSubmitForgetPass() {
    this.setState({isProcess:true})
    if (!this.state.emailforgetpass) {
      this.setState({ forgetinfo: "enter  your email", isProcess: false});
    } else {
      const mail = {
        email: this.state.emailforgetpass,
      };
      axios
        .post("/forgot/password", mail)
        .then((res) => {
          this.setState({ forgetinfo: res.data.message, isProcess: false, mood:res.data.status });
         
          if (res.data.message !== "") {
            setTimeout(() => {
              this.setState({ modal: false });
            }, 2500);
          }
          console.log(res);
        })

        .catch((err) => {
          console.log(err);
           this.setState({ forgetinfo: err, isProcess: false });
         
        });
    }
  }

    render() {
      const token = localStorage.getItem('token')
        return (
          <div>
          
         
          <div className="container text-center mt-5" style={{ width: "100%" }}>
            <div className="form-group mt-5">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter your email"
                  required
                  value={this.state.emailforgetpass}
                  onChange={this.handleEmailForgetPass}
                />
              </div>

              {this.state.forgetinfo !== "" ? ( 
                <div className="alert alert-info">
                  {this.state.forgetinfo}{" "}
                 </div>
              ) : null}
                
              {this.state.mood==='success'?(
              
            <MDBBtn
                className="btn btn-sm text-white"
                color="primary">
                <Link to='/signin' className='text-white'>Signin Now</Link>
              </MDBBtn>):<button
                className="btn btn-sm btn-primary"
                onClick={this.handleSubmitForgetPass.bind(this)}
              >
              
                {this.state.isProcess === true ? (
                  <ProcessLoader />
                ) : (
                  <span> Send Me password</span>
                )}
              </button> }


              
          </div>
           </div>
        );
    }
}
