import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Leftsidebar.css";
import { userProfile } from "../../apidata/api";
import {  MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import moment from 'moment'
import SearchBox from "../searchResult/SearchBox";

export default class Leftsidebar extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      name: "",
      avater: "",
      modal:false,
      usernotif:[],
    };
    this.handleprofile = this.handleprofile.bind(this);
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // async componentDidMount() {
  //   //  const today = new Date
  //   //  let todaysdate = today.toDateString()
  //   //  this.setState({ todaysdate: todaysdate })
  // }
  async componentWillMount() {
    const id = await JSON.parse(localStorage.getItem("userId"));

    
    if (id) {
      this.setState({ id: id });
    }
    
    
    
  
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      const user = await userProfile(id);
    
      if (user.message === "jwt expired") {
        alert("You have to log re-Login");
        this.props.history.push("/signin");
      } else {
        this.setState({ name: user.name, avater: user.avater, usernotif: user.notification});
      }
    }
    
  }
  

  async handleprofile() {
    
  }
  async handleLogout() {
    await localStorage.removeItem("token");
    await window.localStorage.clear();
    await this.setState({ usertoken: null });
  }

  render() {
    const { id, name, avater, usernotif } = this.state;
   
    const sidebarStyle = {
      leftsidbar: {
        marginTop: "0% !important",
        textAlign: "left",
        borderRadius: "8px",
        backgroundColor: "#fff",
        /* background-image: url('../assets/img/project.jpeg'); */
        //
        color: "rgb(3, 10, 73)",
        // boxShadow: "0.2px 0.2px 4px grey",
        padding: "8px",
        // width:'90%',
      },
      imgStyle: {
        width: "40px",
        height: "40px",
        cursor: "pointer",
        boxShadow: "0.3px 0.3px 1px gray",
        borderRadius: "50px",
      },

      listItem: {
        ":hover": {
          backgroundColor: "red",
        },
        border: "0px",
        fontWeight: "bold",
        // ':hover':{
        //     backgroundColor:'red'
        // },
        listStyleType: "none",
        padding: "10px",
      },
      listLink: {
        boxSizing: "border-box",
        padding: "5px 8px",
        display: "block",
      },
      smalldevice: {
        display: "none",
        textAlign: "left",
    position:"fixed",
        backgroundColor: "#fff",
        /* background-image: url('../assets/img/project.jpeg'); */
        width: "100%",
        zIndex:'1',
        color: "rgb(3, 10, 73)",
        boxShadow: "0.2px 0.2px 4px grey",
        // padding: "8px",
      },
      LinkMobile:{
         padding:'0px 6px',
        //  border:'1px solid grey',
         margin:'1px',
      
         borderRadius:'50%',
      }
    };
 const token = localStorage.getItem('token')
 if(token){
    return (
      <div className="container">
        <div style={sidebarStyle.smalldevice} id="smalldevice">
          <div className="row"> 
          <button
            className="navbar-toggler  col-2"
            type="button"
            // data-toggle="collapse"
            data-toggle="dropdown"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span
              className="navbar-toggler-icon"
              style={{ color: "#0056B5", width: "auto" }}
            >
              &#9776; 
            </span>
          </button>
          
          <div className="  lighten-4 pt-2 col-10"   style={{fontSize:'16px'}}>
              <Link className="" to='/home' style={sidebarStyle.LinkMobile} title="Home">  <i className="fa fa-home"></i></Link>
               
               
              <Link to="/userdash" style={sidebarStyle.LinkMobile}><i className="fa fa-tachometer" aria-hidden="true" title="Dashboard"></i> </Link> 
              <Link to="/projects" style={sidebarStyle.LinkMobile}><i className="fa fa-book" aria-hidden="true" title="projects"></i> </Link> 
              
              <Link to={`/addproject/${id}`} style={sidebarStyle.LinkMobile}><i className="fa fa-cloud-upload" aria-hidden="true" title="Upload Project"> </i></Link>
              <Link to={`/listdepartment`} style={sidebarStyle.LinkMobile}><i className="fa  fa-list-alt" aria-hidden="true"> </i></Link>
              <Link className="" to={`/profile/${id}`} style={sidebarStyle.LinkMobile} title="profile"> <i className="fa fa-user"> </i> </Link>
             
              <Link className="" to='#' onClick={this.toggle} style={sidebarStyle.LinkMobile}> <i className="fa fa-bell" title="notification"> </i> </Link>
             
              <Link to="#" className="navbar-toggler "
                data-toggle="collapse"
             
                data-target="#MobileSearch"
                aria-expanded="false"><i className="fa fa-search"></i></Link>
              
                       

              <nav className="navbar navbar-light justify-content-between collapse navbar-collapse " id="MobileSearch">
               
                {/* <form className="form-inline" style={{width:'80%'}}>
                  <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                  <button className="btn btn-outline-primary  btn-sm my-sm-0" type="submit">Search</button>
                </form> */}
                <SearchBox />
               
              </nav>
          </div>
         
         
          </div>
         
          <div
            className="collapse
            navbar-collapse"
            id="navbarNavDropdown"
          >
           
            <div className="text-center ">
              <Link to={`/profile/${id}`} title="Your profile">
                <img src={avater} style={sidebarStyle.imgStyle} alt='user avater'/>

                <h5 className="text-capitalize">{name}</h5>
              </Link>
            </div>
            <div>
              <ul className="list-group">
                <li className="list-group-item" style={sidebarStyle.listItem}>
                  {" "}
                  <Link
                    to="/home"
                    className="list-link"
                    style={sidebarStyle.listLink}
                  >
                    {" "}
                    <i className="fa fa-book "></i> Explore Project{" "}
                  </Link>
                </li>

                <li className="list-group-item" style={sidebarStyle.listItem}>
                  {" "}
                  <Link
                    to={`/addproject/${id}`}
                    className="list-link"
                    style={sidebarStyle.listLink}
                  >
                    <i className="fa fa-upload"></i> Upload Project{" "}
                  </Link>{" "}
                </li>
                <li className="list-group-item" style={sidebarStyle.listItem}>
                  <Link
                    to="/listdepartment"
                    className="list-link"
                    style={sidebarStyle.listLink}
                  >
                    {" "}
                    <i className="fa fa-book "></i> Project Dept{" "}
                  </Link>{" "}
                </li>
                <li className="list-group-item" style={sidebarStyle.listItem}>
                  {" "}
                  <Link
                    to="/userdash"
                    className="list-link"
                    style={sidebarStyle.listLink}
                  >
                    {" "} <i className="fa fa-tachometer"></i> 
                Dashboard
                </Link>{" "}
                </li>
                <li className="list-group-item" style={sidebarStyle.listItem}>
                  {" "}
                  <Link
                    to={`/profile/${id}`}
                    className="list-link"
                    style={sidebarStyle.listLink}
                  >
                    {" "}
                  Profile{" "}
                  </Link>{" "}
                </li>

                <li className="list-group-item" style={sidebarStyle.listItem}>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className=" dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={sidebarStyle.listLink}
                    >
                      Support
                  </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link className="dropdown-item" to="/complain">
                        Report Issue
                    </Link>
                      
                      <Link className="dropdown-item" to="/contactus">
                        Contact us
                    </Link>
                    </div>
                  </div>
                </li>
                <li style={sidebarStyle.listItem}>
                  <Link
                    to="/"
                    className="list-link"
                    style={sidebarStyle.listLink}
                    onClick={this.handleLogout.bind(this)}
                  >
                    {" "}
                  Logout{" "}
                  </Link>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* end of small device"*/}


{/* desktop view */}
<div className="container">
        <div
          style={sidebarStyle.leftsidbar}
          className="sidebar-fixed position-fixed "
          id="sidebar"
        >
          <div className="text-center">
            <Link to={`/profile/${id}`} title="Your profile">
              <img src={avater} style={sidebarStyle.imgStyle} alt='user avater'/>

              <h5 className="text-capitalize">{name}</h5>
            </Link>

            <hr />
          </div>
          <div>
            <ul className="list-group">
              <li className="list-group-ite" style={sidebarStyle.listItem}>
                {" "}
                <Link
                  to="/home"
                  className="list-link"
                  style={sidebarStyle.listLink}
                >
                  {" "}
                  <i className="fa fa-book "></i> Explore Project{" "}
                </Link>
              </li>

              <li className="list-group-ite" style={sidebarStyle.listItem}>
                {" "}
                <Link
                  to={`/addproject/${id}`}
                  className="list-link"
                  style={sidebarStyle.listLink}
                >
                  <i className="fa fa-upload"></i> Upload Project{" "}
                </Link>{" "}
              </li>
              <li className="list-group-ite" style={sidebarStyle.listItem}>
                <Link
                    to="/listdepartment"
                  className="list-link"
                  style={sidebarStyle.listLink}
                >
                  {" "}
                  <i className="fa fa-book "></i>  Project Dept{" "}
                </Link>{" "}
              </li>
              <li className="list-group-ite" style={sidebarStyle.listItem}>
                {" "}
                <Link
                    to="/userdash"
                  className="list-link"
                  style={sidebarStyle.listLink}
                >
                  {" "}
                    <i className="fa fa-tachometer"></i> Dashboard
                </Link>{" "}
              </li>
              <li className="list-group-ite" style={sidebarStyle.listItem}>
                {" "}
                <Link
                  to={`/profile/${id}`}
                  className="list-link"
                  style={sidebarStyle.listLink}
                >
                  {" "}
                    <i className="fa fa-user"></i>  Profile{" "}
                </Link>{" "}
              </li>

              <li className="list-group-item" style={sidebarStyle.listItem}>
                <div className="dropdown">
                  <Link
                    to="#"
                    className=" dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={sidebarStyle.listLink}
                  >
                      <i className="fa fa-wrench"></i> Support
                  </Link>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Link className="dropdown-item" to="/complain">
                      Report Issue
                    </Link>
                   
                    <Link className="dropdown-item" to="/contactus">
                      Contact us
                    </Link>
                  </div>
                </div>
              </li>
              <li style={sidebarStyle.listItem}>
                <Link
                  to="/"
                  className="list-link"
                  style={sidebarStyle.listLink}
                  onClick={this.handleLogout.bind(this)}
                >
                  {" "}
                  Logout{" "}
                </Link>{" "}
              </li>
            </ul>
          </div>
          {/* notification */}
            
        </div>
        </div>
        <MDBModal toggle={this.toggle} isOpen={this.state.modal} fullHeight >
          <MDBModalHeader toggle={this.toggle}>Notifications</MDBModalHeader>
          <MDBModalBody className="text-center">
            
              {usernotif ? usernotif.map((notif, index) => {
                const { notification, date } = notif
                return (
                  <div key={index}>
                    <ul style={{ listStyleType: 'none' }} className="list-group"><li className="list-group-ite text-left alert alert-success"> <Link to='/userdash'>{notification}
                    </Link> &nbsp; <span>on &nbsp;<small>{moment(date).format('DD/MM/YY')}</small></span>
                    </li></ul>
                  </div>
                )
              }).reverse() : <p>No any notification</p>}
     
            
          
          </MDBModalBody>
        </MDBModal>
      </div>
    );
    }else return( <div style={{display:'none'}}></div>)
  }
}
