import React, { Component } from "react";

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import moment from "moment";

import { userProfile } from "../../../apidata/api";
import { Link } from "react-router-dom";
import SearchBox from "../../../Pages/searchResult/SearchBox";
// import "./SidesBars/Leftsidebar.css";

export default class UsersNavBarHeader extends Component {
  constructor() {
    super();
    this.state = {
      logOut: "",
      usertoken: "",
      id: "",
      modal: false,
      userdata: [],
      usernotif: [],
      notification: "",
      day: Date,
    };
    this.handleLogout = this.handleLogout.bind(this);
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  async componentWillMount() {
    const id = await JSON.parse(localStorage.getItem("userId"));
    const userdata = await userProfile(id);

    this.setState({ userdata: userdata, usernotif: userdata.notification });
    if (id) {
      this.setState({ id: id });
    }
  }
  async handleLogout() {
    await localStorage.removeItem("token");
    await window.localStorage.clear();
    await this.setState({ usertoken: null });
  }

  render() {
    const { id, usernotif } = this.state;

    const style = {
      backgroundColor: "#fff",
      padding: "0px",
      zIndex: "2",
      top: "0px",
      width: "100%",
      marginTop: "0px",
      position: "fixed",
      borderWidth: "0px  0px 2px 0px",
      borderStyle: "inset",
      borderColor: "#1b1f5c",
      top: "0px",
      color: "#022B69",

      // "#1b1f5c
    };
    const linkcolor = {
      color: "#000",
    };
    const dropdown = {
      color: "black",
    };

    return (
      <div className="navbar navbar-expand-sm  mb-5" style={style}>
        <Link
          className="navbar-brand ml-5  "
          to="/home"
          style={{
            fontSize: "1.5em",
            color: "#022B69",
            fontWeight: "bold",
          }}
        >
          ProjectVersity&#x26d6;
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          // data-toggle="collapse"
          data-toggle="dropdown"
          data-target="#navbarSupportedContent-4"
          aria-controls="navbarSupportedContent-4"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span
            className="navbar-toggler-icon"
            style={{
              color: "#0056B3",
              backgroundColor: "#ffffff",
              margin: "5px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            {" "}
            &#9776;
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent-4">
          <ul className="navbar-nav ml-auto">
            <SearchBox />
            {/* <li className="nav-item active">
              <Link className=" nav-link" style={linkcolor} to="/home">
                Home
              </Link>
              <span className="sr-only">(current)</span>
            </li> */}
            <li className="nav-item active">
              <Link className=" nav-link" style={linkcolor} to="/projects">
                Projects
              </Link>
            </li>
            <li className="nav-item ">
              <Link
                className=" nav-link"
                to={`/addproject/${id}`}
                style={linkcolor}
              >
                Upload
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link " style={linkcolor} to="/userdash">
                Dashboard
              </Link>
            </li>
            <li>
              <MDBBtn color="primary" size="sm" onClick={this.toggle}>
                {" "}
                <i className="fa fa-bell"></i>
              </MDBBtn>
            </li>
            <li className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                id="navbarDropdownMenuLink-4"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={linkcolor}
              >
                <i className="fa fa-user" /> Profile{" "}
              </Link>
              <div
                className="dropdown-menu dropdown-menu-right dropdown-info"
                aria-labelledby="navbarDropdownMenuLink-4"
              >
                <Link
                  to={`/profile/${id}`}
                  className="dropdown-item list-link"
                  href="#"
                  style={dropdown}
                >
                  My account
                </Link>
                <hr style={{ padding: "0px", margin: "0px" }} />
                <Link
                  to="/"
                  className=" dropdown-item list-link "
                  onClick={this.handleLogout}
                >
                  &nbsp;Logout
                </Link>
              </div>
            </li>
          </ul>
        </div>

        {/* <div className="clearfix"></div> */}
        {/* notification */}
        <MDBModal
          toggle={this.toggle}
          isOpen={this.state.modal}
          fullHeight
          position="right"
        >
          <MDBModalHeader toggle={this.toggle}>Notifications</MDBModalHeader>
          <MDBModalBody className="text-center">
            {/* {usernotif ? (
              usernotif
                .sort()
                .reverse()
                .map((notif, index) => {
                  const { notification, date } = notif;
                  return (
                    <div key={index}>
                      <ul
                        style={{ listStyleType: "none" }}
                        className="list-group"
                      >
                        <li className="list-group-ite text-left alert alert-success">
                          {" "}
                          <Link to="/userdash">{notification}</Link> &nbsp;{" "}
                          <span>
                            on &nbsp;
                            <small>{moment(date).format("DD/MM/YY")}</small>
                          </span>
                        </li>
                      </ul>
                    </div>
                  );
                })
            ) : (
              <p>No any notification</p>
            )} */}

            {usernotif ? (
              usernotif
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((notif, index) => {
                  const { notification, date } = notif;
                  return (
                    <div key={index}>
                      <ul
                        style={{ listStyleType: "none" }}
                        className="list-group"
                      >
                        <li className="list-group-itm text-left alert alert-success">
                          <Link to="/userdash">
                            {notification} &nbsp;
                            <span>
                              on &nbsp;
                              <small style={{ color: "black" }}>
                                {moment(date).format("DD/MM/YY")}
                              </small>
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  );
                })
            ) : (
              <p>No notifications available</p>
            )}

            {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}
