import React, { Component } from 'react'
import '../../assets/smalloader.css'
import axios from 'axios'
import { userProfile } from '../../apidata/api'
import Leftsidebar from '../SidesBars/Leftsidebar'
import "./complain.css"

export default class complain extends Component {
    constructor(){
        super()
        this.state={
            subject:'',
            complain:'',
             info:'',
             isLoading:false,
             username:'',
             userId:''
        }
    }
 async componentWillMount(){
     let id = JSON.parse(localStorage.getItem("userId"))
     if (!id) {
         alert('Login Before Report Issues')
         this.props.history.push('/')
     }
     const user = await userProfile(id)

     if (user) {
         this.setState({ username: user.name, userId: user._id })
     }
 }
    
 async componentDidMount(){
        
         
        
     }


async handleComplainPost(e){
    e.preventDefault()
    this.setState({ isLoading: true });


    try {

       
        const complains = {
          userId: this.state.userId,
            username: this.state.username,
           
            subject: this.state.subject,
            complain:this.state.complain
        };
        axios.post(`/api/v1/complain`, complains)

            .then(response => {
                console.log(response)
                this.setState({ info: response.data.message })
                this.setState({ isLoading: false });
                setTimeout(() => {
                    this.props.history.push('/userdash')
                }, 1000)
            })
            .catch(err => {
                this.setState({ isLoading: false });
                this.setState({ info: err.message })
                console.log(err)
            })
    } catch (error) {
        this.setState({ isLoading: false });
        return error.message
    }

}

    async handleSubject(e){
        this.setState({subject:e.target.value})
    }
    async handleComplainDetail(e) {
        this.setState({ complain: e.target.value })
    }
    render() {
       
        return (

            <div>

                <div className="mt-5" id="sideBarHome">
    <Leftsidebar/>
</div>
            
            <div className='container'>
                <div className=" row mt-5">
<div className="col-lg-3" id="hidesideBarHome">
    <Leftsidebar/>
</div>

               
                <div className=" container col-lg-6 col-11 mt-5 p-2">
                    <form >
                    <h5 className="complainheading"> Feel free to report, make any complains or inquiry</h5>
                    <div className="form-group">

                       <label htmlFor="subject">Subject:</label> <input className="form-control" type="text" placeholder=" subject e.g issue with project download" onChange={this.handleSubject.bind(this)} />
                    </div>
                    <div className="form-group">
                            <label htmlFor="subject">Message:</label>
                            <textarea  cols="30" rows="10" className="form-control" placeholder='report any complain/issues' onChange={this.handleComplainDetail.bind(this)}></textarea>


                    </div>
                        {this.state.info !== '' && this.state.info !== null ? <div className="text-center alert alert-info"> {this.state.info}</div> : null}
                    <div className="form-group">

                      
                        {this.state.isLoading === true ? (
                            <div className="text-center" id="loader" />
                        ) : (
                                <button className="btn btn-primary" onClick={this.handleComplainPost.bind(this)}>Submit</button>
                            )}
                    </div>
                        </form>
                </div>
                 </div>
            </div>
            </div>
        )
    }
}
