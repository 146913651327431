import axios from "axios";



//allproject
export async function getAllproject() {
  try {
    const projects = await axios.get("/projects");
  
    return projects.data.info;
    
  } catch (error) {
    return error.message;
  }
}
// free project
export async function getFreeProject() {
  try {
    const freeprojects = await axios.get("/projects/free");

    return freeprojects.data.info;

  } catch (error) {
    return error.message;
  }
}
export async function getRequestMonetizeProject() {
  try {
    const freeprojects = await axios.get("/projects/req");

    return freeprojects.data.info;

  } catch (error) {
    return error.message;
  }
}
// paid project
export async function getPaidProject() {
  try {
    const paidprojects = await axios.get("/projects/paid");
 
    return paidprojects.data.info;

  } catch (error) {
    return error.message;
  }
}

//single project
export async function getSingleProjectA(id) {
  try {
    // const token = await JSON.parse(localStorage.getItem("token"));
    const project = await axios.get(`/project/${id}`);
  
    return project.data.info;
    // console.log(project.data)
  } catch (error) {
    return error;
  }
}

//single user
export async function getUserData(id) {
  try {
    const token = await JSON.parse(localStorage.getItem("token"));
    const info = await axios.get(`user/${id}`, {
      headers: { Authorization: token }
    });
    
    return info.data;
  } catch (error) {
    return error.message;
    // console.log('ee' + error.message)
  }
}
export async function allUsers() {
  try {
    
    const users = await axios.get('/users');
    
    return users.data.info;
  } catch (error) {
    return error.message;
    // console.log('ee' + error.message)
  }
}
//edit project
export async function projectEdit(id) {
  try {
    const userId = await JSON.parse(localStorage.getItem("userId"))
    const editproject = await axios.put(`/update/project/${id}`, {
      id: userId
    });

    return editproject.data.info
  } catch (error) {
    return error.message
  }
}

//get user profile
export async function userProfile(id) {
  try {
    const token = await JSON.parse(localStorage.getItem("token"));
    const profile = await axios.get(`/user/${id}`, {
      headers: { Authorization: token }
    });
    
    return profile.data.info
  } catch (error) {
    return error.message
  }
}
// get project views
export async function projectViews(id) {
  try {
    const userId = await JSON.parse(localStorage.getItem("userId"))
    const viewid={
      viewid: userId
    }
    const projectviews = await axios.put(`/project/views/${id}`,viewid);

    return projectviews.data.views
  } catch (error) {
    return error.message
  }
} 

export async function projectDownloads(id) {
  try {
    const userId = await JSON.parse(localStorage.getItem("userId"))
    const projectdownload = await axios.put(`/project/downloads/${id}`, {
      id: userId
    });
     
    return projectdownload.data.downloads
  } catch (error) {
    return error.message
  }
}



export async function projectComment(id) {
  try {
    const comment = await axios.get(`/comments/${id}`);

    return comment.data.comment;
  } catch (error) {
    return error.message;
  }
}
// delete project 
export async function DeleteProject(id) {
  try {
    const deleteProject = await axios.delete(`/delete/project/${id}`);

    return deleteProject.data.message;
  } catch (error) {
    return error.message;
  }
}
// delete comment
export async function DeleteComment(id) {
  try {
    const deleteComment = await axios.delete(`/comment/${id}`);

    return deleteComment.data.message;
  } catch (error) {
    return error.message;
  }
}


//get admin
export async function AdminData(id) {
  try {
    const admin = await axios.get(`/admin/${id}`);

    return admin.data.info[0]
  } catch (error) {
    return error.message
  }
}


export async function getAllBuyers() {
  try {
    const buyerprojects = await axios.get("/projects/buyers");

    return buyerprojects.data.info;

  } catch (error) {
    return error.message;
  }
}
export async function getAllComplains() {
  try {
    const getcomplains = await axios.get("/api/v1/complains");

    return getcomplains.data.info;

  } catch (error) {
    return error.message;
  }
}
// /api/v1 / complains
// get all contactus

export async function getAllContactus() {
  try {
    const getcontactus = await axios.get("/api/v1/contactus");

    return getcontactus.data.info;

  } catch (error) {
    return error.message;
  }
}

export async function getSingleBuyer(id,user) {
  try {
   
    const singlebuyer = await axios.get(`/project/single/buyer/${id}/${user}`,{params:{name:'kondi',user:user}});

    return singlebuyer.data.info;

  } catch (error) {
    return error.message;
  }
}


export async function getAllCashout() {
  try {
    const cashout = await axios.get("/user/cashout/all");

    return cashout.data.info;

  } catch (error) {
    return error.message;
  }
}
export async function getApprovedCashout() {
  try {
    const appcashout = await axios.get("/user/approvedcashout/get");

    return appcashout.data.info;

  } catch (error) {
    return error.message;
  }
}
export async function getRequestCashout() {
  try {
    const appcashout = await axios.get("/user/cashout/req");

    return appcashout.data.info;

  } catch (error) {
    return error.message;
  }
}
// user get pending cashout request
export async function getUserPendingCashout(Userid) {
  try {
    const pendingcashout = await axios.get(
      `/user/cashout/${Userid}`
    );

    return pendingcashout.data.info;

  } catch (error) {
    return error.message;
  }
}
export async function getUserApprovedCashout(appuser) {
  try {
    const approvedcashout = await axios.get(
      `/user/approved/cashout/${appuser}`
    );

    return approvedcashout.data.info;
  } catch (error) {
    return error.message;
  }
}
// get departemet
export async function getDepartmentCat(department) {
  try {
    const appcashout = await axios.get(`/department/${department}`);

    return appcashout.data.info;
    

  } catch (error) {
    return error.message;
  }
}
// search with department and topic
export async function searchWithDepartment(query) {
  try {
    const info = await axios.get(`/search${query}`)
    return info.data
  } catch (e) {
    return e.message
  }
}



export async function getDistinctDepartment() {
  // /distinct/departments
  try {
    const diffdepartment = await axios.get(`/distinct/departments`)

    return diffdepartment.data.department;


  } catch (error) {
    return error.message;
  }
}


// blog apis
// ------All post
export async function adminGetAllBlogPost() {
  try {
    const allpost = await axios.get("/api/v1/blog/posts");

    return allpost.data.info;

  } catch (error) {
    return error.message;
  }
}

// ---single post
export async function adminGetSingleBlogPost(id) {
  try {
    const singlepost = await axios.get(`/api/v1/blog/post/${id}`);

    return singlepost.data.info;

  } catch (error) {
    return error.message;
  }
}
// --delete post
export async function adminDeleteBlogPost(id) {
  try {
    const singlepost = await axios.delete(`/api/v1/blog/post/delete/${id}`);

    return singlepost.data.message;

  } catch (error) {
    return error.message;
  }
}
export async function adminDeleteUser(id) {
  try {
    const token = JSON.parse(localStorage.getItem("admintoken"));
    const deleteuser = await axios.delete(`/user/delete/${id}`, {
    headers:{
      'x-access-token':token

      }
    }
    );


    return deleteuser.data.message;

  } catch (error) {
    return error.message;
  }
}
// const apiurl="http://localhost:8090/";
export async function getConfirmMail(email,token) {
  try {
   

    const confirmail = await axios.get(`/confirmation/${email}/${token}`);

   
    return confirmail.data;
  } catch (error) {
    return error.message;
  }
}
