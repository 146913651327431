import React, { Component } from "react";
import{adminGetAllBlogPost} from "../../apidata/api"
import {Link} from 'react-router-dom'
import VisitorNavbar from '../../Layout/Header/Navbar/VisitorNavbar'

import moment from 'moment'
import UsersNavBarHeader from "../../Layout/Header/Navbar/UsersNavBarHeader";
import SidebarPost from "./SidebarPost";
export default class Bloghome extends Component {
  constructor(){
    super()
    this.state={
      allpost:[]
    }
  }

  async componentDidMount() {
    const allpost = await adminGetAllBlogPost()
    if (allpost) {
      this.setState({ allpost: allpost })
      const token = localStorage.getItem('token')
      if(token){
        this.setState({token:token})
      }
    }
  }

  render() {
    const {allpost}= this.state
    
    const token = localStorage.getItem('token')
    
    return (
      <div>
        {token? <UsersNavBarHeader/>: <VisitorNavbar/>}

        <div className="container mt-5">
          <div className="row  mt-5">
            {/* Blog Entries Column */}
            <div className="col-md-8">
              <h6 className="my-4 mt-5">
                ProjectBuk Blogs
   
              </h6>
              {allpost? allpost.map((post,index)=>{
const {title,content, avater, date , author, _id}= post
                return (<div className="card mb-4" key={index}>
                  <img
                    className="card-img-top"
                    src={avater}
                    alt="Card image cap"
                    style={{height:'200px'}}
                  />
                  <div className="card-body">
                    <h2 className="card-title">{title}</h2>
                    <p className="card-text">
                      {content.substring(0, 90)}
                    </p>
                    <Link to={`blogpost/${_id}`} className="btn btn-primary">
                      Read More →
                  </Link>
                  </div>
                  <div className="card-footer text-muted">
                    Posted on {moment(date).format("DD/MM/YYYY")} by &nbsp;&nbsp;
                  <Link to="#">{author}</Link>
                  </div>
                </div>


)

              }):<h5>No Blog post yet</h5>}
              {/* Blog Post */}
                            {/* Pagination */}
              <ul className="pagination justify-content-center mb-4">
                <li className="page-item">
                  <Link className="page-link" to="#">
                    ← Older
                  </Link>
                </li>
                <li className="page-item disabled">
                  <Link className="page-link" to="#">
                    Newer →
                  </Link>
                </li>
              </ul>
            </div>
            {/* Sidebar Widgets Column */}
            <div className="col-md-4">
              {/* Search Widget */}
             <SidebarPost/>
            </div>
          </div>
          {/* /.row */}
        </div>
      </div>
    );
  }
}
