import React, { Component } from "react";
import {
  MDBRow,
  MDBCol,
  MDBView,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";
import SideBarNav from "../layout/sideNavigation";
import "./admin.css";
import moment from "moment";
import axios from 'axios'

import { getRequestCashout, AdminData } from "../../components/apidata/api";

export default class CashoutTable extends Component {
  constructor() {
    super();
    this.state = {
      cashout: [],
      id:'',
      isLoading:false,
      user:'',
      info:''
    };
  }

  async componentDidMount() {
    const cashouts = await getRequestCashout();
console.log(cashouts)
    if (cashouts) {
      this.setState({ cashout: cashouts });
    }
    const id = await JSON.parse(localStorage.getItem("adminuserId"));
    const token = JSON.parse(localStorage.getItem("admintoken"));
    if (token) {
      const admin = await AdminData(id);

      if (admin) {
        this.setState({
          name: admin.name,
        });

      }
    }
  }


    async handleApprovedCashout(e) {
        e.preventDefault()
        this.setState({ isLoading: true })
        const id = this.state.id
        console.log(this.state.user)
        const user={
            user:this.state.user,
            approvedby:this.state.name,
            notification:" Your cashout is approved"
        }
       
        axios.put(`/user/approvecashout/${id}`, user)
            .then(response => {
                console.log(response)
                this.setState({ info: response.data.message })
                this.setState({ isLoading: false });
              alert(response.data.message)
                  this.componentDidMount()
               
            })
            .catch(err => {
                this.setState({ isLoading: false });
                this.setState({ info: err.message })
                console.log(err)
            })
    }
    async sendID(e) {
        this.setState({ id: e.target.value })
        console.log(this.state.id + 'id')
    }
    async sendUser(e) {
        this.setState({ user: e.target.value })
        console.log(this.state.user + 'user')
    }




  render() {
    return (
      <div>
        <div className="flexible-content">
          <SideBarNav />
          <div id="content" className="p-2">
            <MDBRow>
              <MDBCol md="12">
                <MDBCard className="mt-5">
                  <MDBView className="gradient-card-header blue d">
                    <h4 className="h4-responsive text-white">Cashout DATA</h4>
                  </MDBView>
                  <MDBCardBody>
                    <MDBTable
                      striped
                      scrollY
                      maxHeight="400px"
                      hover
                      className="text-dark"
                    >
                      <MDBTableHead>
                        <tr>
                          <th>S/N</th>
                          <th>date</th>
                          <th>userID</th>
                          <th>Name</th>
                          <th>acctname</th>
                          <th>acctno</th>
                          <th>bankname</th>
                          <th>cashout Amount</th>
                          <th>Status</th>
                           <th>ADMIN ACTION</th>
                           <th>id</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        { this.state.cashout ? this.state.cashout.map((cashout, index) => {
                            const { user ,_id, date,userName, cashoutamount,acctname, acctno, bankname,cashoutstatus, cashoutapproved}= cashout
                            console.log(cashout.length)
                            if (cashoutstatus==='pending'&& cashoutapproved===false ) {              return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{moment(date).format("DD/MM/YY")}</td>
                              <td>{user}</td>
                              <td>{userName}</td>
                              <td>{acctname}</td>
                              <td>{acctno}</td>

                              <td>{bankname}</td>
                              <td>{cashoutamount}</td>
                                    <td>{cashoutstatus}</td>
                             
                                  <td>
                                        {this.state.info !== "" ? (
                                            <div className="alert alert-info">
                                                {this.state.info}
                                            </div>
                                        ) : null}
                                      {this.state.isLoading == true ? (<div className="text-center" id="loader" />) : (<div>
                                            <span>click to send cashoutid</span>
                                          <input className='btn btn-sm btn-primary' type="text" value={_id} onClick={this.sendID.bind(this)} />
                                          {/* send user */}
                                          <span>click to send userid</span>
                                            <input className='btn btn-sm btn-primary' type="text" value={user} onClick={this.sendUser.bind(this)} />
                                            {/* approved */}
                                          <button onClick={this.handleApprovedCashout.bind(this)} className='btn btn-sm btn-primary'  > Withdraw</button>
                                          <button className='btn btn-sm btn-danger'> Decline</button></div>)}


                                </td>
                                <td>{_id}</td>
                            </tr>
                                )
                                 };
                        }): <h3>NO CASHOUT RECORDS</h3>}
                      </MDBTableBody>
                    </MDBTable>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </div>
        </div>
      </div>
    );
  }
}
