
import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import './Signup.css'
import VisitorNavbar from '../../Layout/Header/Navbar/VisitorNavbar';

class Signup extends Component{
 constructor(){
	 super()
	 this.state={
	        name:'',
			email:'',
            password:'',
            gender:'',
            password1:'',
            info:'',
            agree:'off',
            isLoading:false,
            emailinfo:'',
            nameinfo:'',
             isPasswordShown: false,
		}
     this.handleEmail = this.handleEmail.bind(this)
     this.handleName = this.handleName.bind(this)
     this.handlePassword =this.handlePassword.bind(this)
     this.handleGender= this.handleGender.bind(this)
     this.handlePassword1= this.handlePassword1.bind(this)
     this.handleAgreed = this.handleAgreed.bind(this)
         }
    togglePasswordVisiblity = () => {
        const { isPasswordShown } = this.state;
        this.setState({ isPasswordShown: !isPasswordShown });
    };
         
         handleSubmit=()=>{
             this.setState({isLoading:true})
             if (!this.state.name) {
                 this.setState({ nameinfo: "name empty",isLoading:false })
             }
             else if(!this.state.name|| !this.state.email||!this.state.gender||!this.state.password || !this.state.password1){
                 this.setState({ info: "fill required field", isLoading: false})
             }else if(this.state.agree==='off'){
                 this.setState({ info: 'Check Agreed to the Term & condition', isLoading: false})
             }else if(this.state.password!== this.state.password1){
                 this.setState({info:`password not match`, isLoading:false})

             }else{


            
             
           fetch("/register", { 
               method:'POST',
               headers:{
                   'Accept':'application/json',
                   'Content-Type':'application/json'
               },
               body:JSON.stringify({
                   name:this.state.name,
                   email:this.state.email,
                   password:this.state.password
               })

           } )
           .then(res => res.json())
           .then((res) => {console.log(res)
            this.setState({isLoading:false})
               this.setState({ info: res.message });
//  request to https://oauth2.googleapis.com/token failed, reason: getaddrinfo ENOTFOUND oauth2.googleapis.com
               if (res.info === "successRegister") {
                 window.localStorage.setItem("confirmnewuser", JSON.stringify(res.message));
                 window.localStorage.setItem("newUserEmail",   res.newUserEmail )
                      window.localStorage.setItem("newUserName",   res.newUserName)
                 this.props.history.push("/notveriuser");
               } else {
                 this.setState({ info: res.message });
               }
        })
           .catch(err =>{ console.log(err)
            this.setState({isLoading:false})
        })
            
    }
           //console.log(this.state)

         }
         handleName(e){
             this.setState({name:e.target.value})
             if (this.state.name==='') {
                 this.setState({ nameinfo: "name is empty" })
             }else{
                 this.setState({nameinfo:''})
             }
             
         }
         handleEmail(e){
             this.setState({email:e.target.value})
             
             const validMail=(emailt)=>{
                 
                 let regex = (/\S+@\S+\.\S+/)
                 return regex.test(emailt)

             }
        let validmail= validMail(this.state.email)
            if(!validmail){
                this.setState({emailinfo:'email not valid'})
            }else{
                this.setState({emailinfo:'valid mail'})
            }
         }
         handlePassword(e){
             this.setState({password:e.target.value})
         }
         handlePassword1(e){
            this.setState({password1:e.target.value})
        }
     
        handleGender(e){
            this.setState({gender:e.target.value})
        }
    handleAgreed(e) {
        this.setState({ agree: e.target.value })
        console.log(e.target.value)
    }

	 
  render(){


//     if(this.state.info===''){
//         document.getElementById('info').style.display='none'
//   }
const noshowinfo={
    display:'none'
}
      const { isPasswordShown } = this.state;

    return(
        <div>
        <VisitorNavbar/>
        <div className="container">
            

 <div className="pt-5 ">

        </div>

{/* <!--Form with header--> */}
<div className="card mt-5" id="signup">
    <div className="card-header ">
                  
        <div  className="card-header  text-center p-2 ">
                        <a
                            className="navbar-brand " 
                            href="/"
                            style={{ fontSize: "2em",color: "022B69", fontWeight: "bold" }}
                        >
                            Projectversity&#x26d6;
          </a>
            <h5><i className="fa fa-user-plus"></i> Register</h5>
              <p>Join community of Student </p>
            
        </div>

        {/* <!--Body--> */}
        <div className="card-body">
        <div className="md-form mt-4">
            {/* <i className="fa fa-user prefix"></i> */}
            <input type="text" id="form3" required="required" className="form-control" value={this.state.name} onChange={this.handleName}/>
            <label htmlFor="form3">
                            <span className="fa fa-user"></span> Name &nbsp;&nbsp; &nbsp;  { this.state.nameinfo!=='' ? <span> <small className="text-danger">{this.state.nameinfo}</small></span>:null}</label>
        </div>
        <div className="md-form">
            {/* <i className="fa fa-envelope prefix"></i> */}
            <input type="text" id="form2" className="form-control" value={this.state.email} onChange={this.handleEmail} />
            <label htmlFor="form2">
                            <span className="fa fa-envelope"></span> Email &nbsp;&nbsp; {this.state.emailinfo === 'email not valid' ? <span><small className='text-danger'>{this.state.emailinfo}</small></span> : null} {this.state.emailinfo === 'valid mail' ? <span><small className='text-success'>{this.state.emailinfo}</small></span> : null}</label>
        </div>


       <div className="md-form">

                    <select style={{border:'none', cursor:'pointer'}}  className="form-control " id="form2" value={this.state.gender} onChange={this.handleGender}>
                        <option>Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                            
                    </select>
                    <hr/>
                  </div>
 
   

        <div className="md-form">
            {/* <i class="fa fa-lock prefix"></i> */}
                        <input type={isPasswordShown ? "text" : "password"} id="form4" className="form-control" value={this.state.password} onChange={this.handlePassword}/>
            <label htmlFor="form4">
                            <span className="fa fa-lock  fa fa-fw fa-eye field-icon toggle-password"></span> Password  &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span
                                onClick={this.togglePasswordVisiblity}
                                style={{ cursor: "pointer" }}
                               
                            ></span></label>
        </div>



        
        <div className="md-form">
            {/* <i class="fa fa-lock prefix"></i> */}
                        <input type={isPasswordShown ? "text" : "password"}  id="form10" className="form-control" 
            name="password1" value={this.state.password1} onChange={this.handlePassword1}/>
            <label for="form10">
                            <span className="fa fa-lock"></span> Confirm Password &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span
                                onClick={this.togglePasswordVisiblity}
                                style={{ cursor: "pointer" }}
                                class="fa fa-fw fa-eye field-icon toggle-password"
                            ></span> </label>
        </div>

        <div className="md-form">
        <p className='p-1'>  <input type="checkbox"  className="" required="required" onChange={this.handleAgreed}/> I agree to <Link to='/terms'>terms</Link> and <Link to='/terms'>conditions</Link> of Projectversity </p>
        </div>

      { this.state.info===''||this.state.info===undefined ?
    
   (<div className="alert alert-danger" style={noshowinfo} id="info">){this.state.info}</div>) :
   (<div className="alert alert-danger"id="info">{this.state.info}</div>)} 


                    
                    
                    
                    
                    
                    
 
        <div className="text-center">
  
                       
                    <div>
                            {this.state.isLoading === true ? (
                                (document.getElementById("btnsignup").style.display =
                                    "none") && <div className="text-center" id="loader" />
                            )
                                    // <div > <p className="loader"> </p>
                                    //      <span>Loading</span>
                                    // </div>
                                    :
                                <button className="btn  mdi mdi-account-plus" onClick={this.handleSubmit} style={{ backgroundColor: '#022B69', textTransform: "capitalize" }} id="btnsignup"> &nbsp;Signup    </button> 
                    }
                    </div> 
         
        
        </div>
        <p className="pt-3 text-center"> Aready a member
      <Link to="/signin"> Login</Link>
    </p>
                    </div>

    </div>
</div>


















</div>

</div>
        
	)
}
}


export default Signup