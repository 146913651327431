import React, { Component } from 'react'
import SideBarNav from '../layout/sideNavigation'
import { userProfile, adminDeleteUser } from '../../components/apidata/api'
import {Link} from "react-router-dom"
import Axios from 'axios'
import uservater from '../../components/assets/img/undraw_profile.svg'
export default class SingleUser extends Component {
    constructor(){
        super()
        this.state={
            name: "",
            email: "",
            school: "",
            phone: "",
            department: "",
            gender: "",
            bio: "",
            confirmdel: '',
            city: "",
            id: "",
            visitId: "",
            avater: null,
            info: "",
            project: [],
          isLoading:false,
            loading: true
        }
    }
    async componentDidMount() {

        const token = JSON.parse(localStorage.getItem("admintoken"));
        const visitId = JSON.parse(localStorage.getItem("adminuserId"));
        this.setState({ visitId: visitId })
        console.log(visitId)
        if (token) {
            const user = await userProfile(this.props.match.params.id);
console.log(user)
            if (!user ) {
                alert("You have to log re-Login");
                this.props.history.push("/signin");
            } else {

                this.setState({
                    name: user.name,
                    email: user.email,
                    id: user._id,
                    school: user.school,
                    phone: user.phone,
                    department: user.department,
                    bio: user.bio,
                    gender: user.gender,
                    city: user.city,
                    avater: user.avater,
                    info: user.message,
                    project: user.project,
                    loading: false,
                    suspension:user.suspension
                });
            }
        }
        }
    async handleDeleteUser() {
        const id = this.props.match.params.id
        console.log(id)
        if (window.confirm('are you sure you want to delete')) {
           
            const deletePost = await adminDeleteUser(this.props.match.params.id)
             
            
            alert(deletePost)
            if(deletePost){
                this.props.history.push('/admin/users')
            }
        }
    }
    async SubmitSuspendUser(e) {
        e.preventDefault();

        this.setState({ isLoading: true });


        try {
            const id = this.state.id
            const suspension= {

                suspension: true,
              
            };
          await   Axios.put(`/user/suspension/${id}`, suspension)

                .then((response) => {
                    console.log(response);
                    this.setState({ info: response.data.message });
                    this.setState({ isLoading: false });
                    this.componentDidMount()
                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                    this.setState({ info: err.message });
                    console.log(err);
                });
        } catch (error) {
            this.setState({ isLoading: false });
            return error.message;
        }
    }

    async SubmitActivateUser(e) {
        e.preventDefault();

        this.setState({ isLoading: true });


        try {
            const id = this.state.id
           
          await Axios.put(`/user/activate/${id}`)

                .then((response) => {
                    console.log(response);
                    this.setState({ info: response.data.message });
                    this.setState({ isLoading: false });
                    this.componentDidMount()
                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                    this.setState({ info: err.message });
                    console.log(err);
                });
        } catch (error) {
            this.setState({ isLoading: false });
            return error.message;
        }
    }


    render() {
        const {
            name,
            email,
            id,
            info,
            phone,
            school,
            department,
            city,
            bio,
            avater,
            project,
            visitId,

        } = this.state;
        return (
            <div className="flexible-content">
                <SideBarNav />
                <div id="content" className="p-2 container row">
 
   <div className="card mt-3 col-lg-8 col-md-8 col-12" >
                {/* <!-- Card image --> */}
                <div className="text-light">
                            <button className='btn btn-sm btn-danger' id="delete"  onClick={this.handleDeleteUser.bind(this)}>delete</button>
                  {this.state.suspension == true ? <button className='btn btn-sm btn-success' id="delete" onClick={this.SubmitActivateUser.bind(this)}>activate {this.state.isLoading == true ? <span>...</span> : null}</button> 
                  : <button className='btn btn-sm btn-warning' onClick={this.SubmitSuspendUser.bind(this)}>suspend  {this.state.isLoading == true ? <span>...</span> : null}</button> }  
                </div>
                <div className="view overlay" style={{ height: "10%" }}>
                  {avater == null && id == visitId ? (
                    <div className="text-center">
                      {" "}
                      <img
                        className="card-img-top circle "
                        src={uservater}
                      
                      />
                      <Link
                        to={`/updateprofile/${id}`}
                        className="text-center btn btn-sm btn-primary"
                      >
                        Update profile
                      </Link>{" "}
                    </div>
                  ) : (
                    <div>
                      <img
                        className="card-img-top circle "
                        src={avater}
                        height="300"
                      />
                      <a href={`${avater}`}>
                        <div className="mask rgba-white-slight"></div>
                      </a>
                    </div>
                  )}
                </div>

                {/* <!-- Card content --> */}
                <div className="card-body text-left py-0 ">
                  {/* <!-- Title --> */}
                  <h4 className="card-title text-center text-capitalize ">
                    {name}
                  </h4>
                  {/* <!-- Text --> */}
                  <p className="font-italic text-center">{bio}</p>
                  <div className="card-text ">
                    <ul className="list-group">
                      <li className="list-group-item">
                        <strong>Email:&nbsp;</strong>
                        {email}
                      </li>
                      <li className="list-group-item">
                        <strong>Phone:&nbsp;</strong>
                        {phone}
                      </li>
                      <li className="list-group-item">
                        {" "}
                        <strong>School:&nbsp;</strong>
                        {school}
                      </li>
                      <li className="list-group-item">
                        {" "}
                        <strong>Department:&nbsp;</strong>
                        {department}
                      </li>
                      <li className="list-group-item">
                        {" "}
                        <strong>city:&nbsp;</strong>
                        {city}
                      </li>
                      <li className="list-group m-2">
                        {" "}
                        <strong></strong>
                      </li>
                    </ul>
                  </div>
                  {id == visitId ? (
                    <div>
                      <Link to={`/updateprofile/${id}`} className="text-center">
                        {" "}
                        <button className="btn btn-outline btn-sm text-primary text-center ">
                          update profile
                        </button>
                      </Link>{" "}
                     
                    </div>
                  ) : null}
                  <hr />
                  <h4
                    className="text-muted"
                                      >
                    {id === visitId
                      ? "Your upload projects"
                      : `${name} projects`}
                  </h4>
                  <hr/>

                  {project.length > 0 ? (
                    project.map((project, index) => {
                      const {
                        topic,
                        school,
                        department,
                        userId,
                        _id,
                        summary,
                      } = project;
                      return (
                        <div className="well" key={index}>
                          <div>
                            {index + 1}:-&nbsp;{" "}
                            <Link
                              to={`/projectD/${_id}`}
                              className="text-capitalize "
                            >
                              {topic}{" "}
                            </Link>
                            <br />
                            {userId == visitId ? (
                              <div>
                                <Link
                                  to={`/userdash`}
                                  className="btn btn-sm btn-primary"
                                >
                                  Modify Project
                                </Link>

                                
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      {id === visitId ? (
                        <p>
                          {" "}
                          you have not upload any project yet{" "}
                          <Link to={`/addproject/${id}`}>
                            click to upload
                          </Link>{" "}
                        </p>
                      ) : <p>No project yet</p>}
                    </div>
                  )}
                </div>
              </div>

                </div>
                
            </div>
        )
    }
}
