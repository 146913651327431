import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class SearchBox extends Component {
    constructor() {
        super()
        this.state = {
            text: ''
        }
    }

    handleText(e) {
        this.setState({ text: e.target.value })
         console.log(this.state.text)
    }

    render() {
        return (
            <div>
                <nav className="navbar navbar-light justify-content-start " >

                    <form className="form-inline" style={{width:'100%'}} >
                        <input className="form-control mr-sm-2" type="search"  style={{width:"80%"}} placeholder="Search projects topics" aria-label="Search" value={this.state.text} onChange={this.handleText.bind(this)}  />

                        <Link to={`/search?department=${this.state.text}`}> <button className="btn btn-outline-primary  btn-sm my-sm-0" type="submit">Search</button></Link>
                    </form>
                </nav>
            </div>
        )
    }
}

