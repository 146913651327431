import React, { Component } from 'react'
import { MDBCard, MDBCardBody, MDBIcon, MDBRow, MDBCol, MDBCardText, MDBBadge} from 'mdbreact';
import {
  getFreeProject,
  getPaidProject,
  getAllproject,
  allUsers,
  getAllCashout, getRequestCashout, getApprovedCashout, getRequestMonetizeProject
} from '../../../components/apidata/api'



export default class AdminCardSection1 extends Component {
  constructor(){
    super()
    this.state={
     freeproject:[] ,
     paidproject:[],
     allproject:[],
     allusers:[],
     allcashout:[],
     cashoutrequest:[],
     cashoutapproved:[],
     reqmonetizeproject:[]
    }
  }

  async componentWillMount(){
    const freeproject= await getFreeProject()
    const reqmonetizeproject = await  getRequestMonetizeProject()
    
    const paidproject= await getPaidProject()
      
    const allproject= await getAllproject()

     const allusers =await allUsers()
     const allcashout=await getAllCashout()
     const cashoutrequest= await getRequestCashout()
     const cashoutapproved = await getApprovedCashout()
     this.setState({allproject:allproject})
     this.setState({
       reqmonetizeproject: reqmonetizeproject
     })
      this.setState({paidproject:paidproject})
     this.setState({freeproject:freeproject})
     this.setState({allusers:allusers})
     this.setState({
       allcashout: allcashout
     })
      this.setState({
        cashoutrequest: cashoutrequest
      })
      this.setState({
        cashoutapproved: cashoutapproved
      })
    
    }
  render() {
    const {
      freeproject,
      paidproject,
      allproject,
      allusers,
      allcashout,
      cashoutrequest,
      cashoutapproved,
      reqmonetizeproject
    } = this.state
    return (
      <div>
        <MDBRow className="mb-4">
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
              <div className="admin-up">
              <MDBIcon icon="money-bill-alt" className="primary-color"/>
                <div className="data">
                  <p>ALL PROJECTS</p>
                  <h4>
    <strong>{allproject.length}</strong>
                  </h4>
                </div>
              </div>
              <MDBCardBody>
                <div className="progress">
                  <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="50" className="progress-bar bg-primary" role="progressbar"
                    style={{width: `${allproject.length}%`}}></div>
                </div>
    <MDBCardText> We Have <strong>{allproject.length}</strong> PROJECTS </MDBCardText>
              </MDBCardBody>
            </MDBCard>
        </MDBCol>
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
              <div className="admin-up">
      
                <div className="data">
                  <p>MONETIZe REQPROJECT</p>
                  <h4><MDBBadge color="warning-color" pill className="float-right">
     <strong>{reqmonetizeproject.length}</strong>
     </MDBBadge>
                  </h4>
                </div>
              </div>
              <MDBCardBody>
                <div className="progress">
                  <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" className="progress-bar bg grey" role="progressbar"
                    style={{width: `${reqmonetizeproject.length}%`}}></div>
                </div>
              <MDBCardText> Monetize Request </MDBCardText>
              </MDBCardBody>
            </MDBCard>
        </MDBCol>
         <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
              <div className="admin-up">
      
                <div className="data">
                  <p>CASHOUT REQUEST</p>
                  <h4><MDBBadge color="warning-color" pill className="float-right">
     <strong>{cashoutrequest.length}</strong>
     </MDBBadge>
                  </h4>
                </div>
              </div>
              <MDBCardBody>
                <div className="progress">
                  <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" className="progress-bar bg grey" role="progressbar"
                    style={{width: `${cashoutrequest.length}%`}}></div>
                </div>
              <MDBCardText>Cash out request</MDBCardText>
              </MDBCardBody>
            </MDBCard>
        </MDBCol>
        {/* <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
              <div className="admin-up">
              <MDBIcon icon="chart-pie" className="light-blue lighten-1"/>
                <div className="data">
                  <p>PAID PROJECTS</p>
                  <h4>
    <strong>{paidproject.length}</strong>
                  </h4>
                </div>
              </div>
              <MDBCardBody>
                <div className="progress">
                  <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" className="progress-bar grey darken-2" role="progressbar"
                    style={{width: `${paidproject.length}%`}}></div>
                </div>
                <MDBCardText> We Have <strong>{paidproject.length}</strong> PROJECTS </MDBCardText>
              </MDBCardBody>
            </MDBCard>
        </MDBCol> */}
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
              <div className="admin-up">
             
                <div className="data">
                  <p>All Users</p>
                  <h4>
                    <strong>{allusers.length}</strong>
                  </h4>
                </div>
              </div>
              <MDBCardBody>
                <div className="progress">
                  <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" className="progress-bar bg-primary" role="progressbar"
                    style={{width: `${allusers.length}%`}}></div>
                </div>
               <MDBCardText> We Have <strong>{allusers.length}</strong> Users </MDBCardText>
              </MDBCardBody>
            </MDBCard>
        </MDBCol>
      </MDBRow>

      </div>
    )
  }
}

