import React, { Component } from 'react'
import axios from 'axios'
import { userProfile } from '../apidata/api'
import VisitorNavbar from '../Layout/Header/Navbar/VisitorNavbar'
import UsersNavBarHeader from '../Layout/Header/Navbar/UsersNavBarHeader'

export default class Contactus extends Component {
    constructor() {
        super()
        this.state = {
            subject: '',
        message: '',
            info: '',
            isLoading: false,
            username: '',
           
            email:''
        }
    }

    async componentWillMount() {
        let id = await JSON.parse(localStorage.getItem("userId"))
        if (id) {
            const user = await userProfile(id)

            if (user) {
                await this.setState({ username: user.name, userId: user._id , email:user.email})
            }
        }
        
    }
    async handleMessageUs(e) {
        e.preventDefault()
        this.setState({ isLoading: true });
        console.log( this.state.userId,
             this.state.username,
          this.state.email,
         this.state.subject,
         this.state.message)

        try {


            const messages = {
           
                username: this.state.username,
                email:this.state.email,
                subject: this.state.subject,
                message: this.state.message
            };
            axios.post(`/api/v1/contactus`, messages)

                .then(response => {
                    console.log(response)
                    this.setState({ info: response.data.message })
                    this.setState({ isLoading: false });
                    
                })
                .catch(err => {
                    this.setState({ isLoading: false });
                    this.setState({ info: err.message })
                    console.log(err)
                })
        } catch (error) {
            this.setState({ isLoading: false });
            return error.message
        }

    }

    async handleName(e) {
        this.setState({ username: e.target.value })
    }
    async handleEmail(e) {
        this.setState({ email: e.target.value })
    }
    async handleSubject(e) {
        this.setState({ subject: e.target.value })
    }
    async handleMessageDetail(e) {
        this.setState({ message: e.target.value })
    }
    render() {
        let tokennn = localStorage.getItem('token')
        const{username , email} =this.state
        return (
            <div>
                {tokennn ? <UsersNavBarHeader/> : <VisitorNavbar/>}
           
            <div className="container mt-5">
                <div className="row mt-5">
                    <div className="col-lg-6 p-5">
                        <form >
                            <h5 className=""> Feel free to message us</h5>
                            <div className="form-group">
                                <label htmlFor="name">Name:</label>
                                    <input className="form-control" value={username} placeholder="your name" type="text" onChange={this.handleName.bind(this)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email:</label>
                                    <input className="form-control" value={email} placeholder="your email" type="text" onChange={this.handleEmail.bind(this)} />
                            </div>
                            <div className="form-group">

                                <label htmlFor="subject">Subject:</label> <input className="form-control" type="text" placeholder=" subject e.g issue with project download" onChange={this.handleSubject.bind(this)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="subject">Message:</label>
                                <textarea name="" id="msg" cols="30" rows="6" className="form-control" placeholder='report any message/issues' onChange={this.handleMessageDetail.bind(this)}></textarea>


                            </div>
                            {this.state.info !== '' && this.state.info !== null ? <div className="text-center alert alert-info"> {this.state.info}</div> : null}
                            <div className="form-group">


                                {this.state.isLoading === true ? (
                                    <div className="text-center" id="loader" />
                                ) : (
                                        <button className="btn btn-primary" onClick={this.handleMessageUs.bind(this)}>Send Message</button>
                                    )}
                            </div>
                        </form>

                    </div>
                    <div className="col-lg-6 col-md-6 p-5 mt-2">
                    <ul className="list-group">
                            <li className="list-group-item"><i className="fa fa-envelope p-2"></i> Projectversity.com@gmail.com</li>
                            <li className="list-group-item"><i className="fa fa-phone p-2"></i>+2348038863055 or +2349055932268</li>
                            <li className="list-group-item"><i className="fa fa-map-marker p-2"></i> Abuja, Nigeria</li>
                    </ul>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}
