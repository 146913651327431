import React, { Component } from "react";
import Particlejs from "./LandingPageHeadSection";
// import Particles from "react-particles-js";
import { Link } from "react-router-dom";
import "./landing.css";
import RecentProjectLanding from "./RecentProjectLanding";
import DepartmentCategory from "./CategoryLanding";

// images import
import "./landing.css";

const particleOption = {
  particles: {
    number: {
      value: 80,
      color: "white",

      density: {
        enable: true,
        value_area: 800,
      },
    },
    color: {
      value: "#022B69",
    },
  },
};
const ClearToken = async () => {
  // localStorage.removeItem("token")
  // localStorage.clear()
};

export { ClearToken };
class LandingPage extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
    };
  }

  async componentWillMount() {
    const userId = await localStorage.getItem("userId");
    if (userId) {
      this.props.history.push("/home");
    } else {
      this.props.history.push("/");
    }

    // ClearToken()
  }

  render() {
    const cardiMiddle = {
      backgroundColor: "#fff",
      color: "#03023b",
      // boxShadow: "2px 2px 15px #f5f5f5",
      borderRadius: "10px",
    };

    const italic = {
      fontStyle: "italic",
      // fontWeight:'lighter'
    };
    const pp = {
      lineHeight: "1.8em",
      ":hover": {
        color: "red",
      },
    };
    const particle = {
      zIndex: "-1",

      position: "absolute",
      /* background-image: linear-gradient(to right, #341f97, #341f97); */
      //  backgroundAttachment: 'fixed;',
      backgroundColor: "#03023b",
      width: "100%",
      height: "100%",
      color: "white",
    };
    const bold = {
      fontWeight: "bold",
    };
    const Step = {
      fontFamily: "poppins",
      borderWidth: "0px 0px 0px 2px",
      borderColor: "#03023b",
      borderStyle: "solid",
      paddingLeft: "5px",
    };
    return (
      <div>
        <div>
          <Particlejs />
        </div>
        <div>
          {" "}
          <RecentProjectLanding />
        </div>
        {/* content */}
        <section className="cont">
          {/* background: "" */}
          <div
            style={{
              color: "white",
              textAlign: "left",
              paddingBottom: "20px",
              backgroundColor: "rgb(2, 43, 105)",
            }}
            id="Particles"
          >
            {/* <Particles  style={particle} params={{ particleOption }} /> */}
            <div className="container" style={{ marginTop: "10px" }}>
              <div className="text-center p-5 sharep ">
                <h3 className="share">Share Your Creation with the World</h3>
                <p className="sharenet">We create, We Share &amp; We Network</p>
                <hr style={{ color: "white", backgroundColor: "#ffffff" }} />
              </div>

              <div className="row">
                <div className="col-lg-4 col-sm-12 col-md-4">
                  <div className="mt-2" style={cardiMiddle}>
                    {/* Content  style={{backgroundColor:'#F5F5F5'}}*/}
                    <div className="text-black text-left d-flex align-items-center rgba-gray-strong  p-4 px-4">
                      <div>
                        <h4 className=" prohead p-2 text-center ">
                          <strong>Share Your Project</strong>
                        </h4>
                        <p className="p-1 protext" style={pp}>
                          ProjectVersity hosted over thousand of Student project
                          accross Nigeria University join the community and
                          access project documentations on ProjectVersity
                        </p>
                        {/* <Link className="text-center" to="/signup">
                        <p className="text-left learnm">LEARN MORE </p>
                      </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-2 col-md-1"></div> */}
                <div className="col-lg-4 col-sm-12 col-md-4">
                  <div className="mt-2" style={cardiMiddle}>
                    {/* Content */}
                    <div className="text-black text-left d-flex align-items-center rgba-gray-strong p-4 px-4">
                      <div>
                        <h4 className="prohead p-2">
                          <strong>
                            By The Students{" "}
                            <span style={italic}>For The Students</span>
                          </strong>
                        </h4>
                        <p className="p-1 protext" style={pp}>
                          ProjectVersity is project docs Sharing platform
                          contribute by the Students to help other students get
                          access to research paper at ease.
                        </p>

                        {/* <Link className="text-center" to="/signin">
                          <p className="text-left learnm">LEARN MORE </p>
                      </Link> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-12 col-md-4">
                  <div className="mt-3" style={cardiMiddle}>
                    {/* Content  style={{backgroundColor:'#F5F5F5'}}*/}
                    <div className=" text-black text-left d-flex align-items-center rgba-gray-strong p-4 px-4">
                      <div>
                        <h4 className=" prohead p-2 ">
                          <strong>Earn With Your Projects</strong>
                        </h4>
                        <p className="p-1 protext " style={pp}>
                          You have the ability to upload your project, research
                          documents, or papers and monetize them. Earn income as
                          others purchase your work.{" "}
                        </p>
                        {/* <Link className="text-center" to="/signup">
                          <p className="text-left learnm">LEARN MORE </p>
                      </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* end of  */}
              </div>
            </div>
          </div>
        </section>

        {/* why register */}

        <section
          id="main-features"
          className="section main-feature-gray mt-2 pt-2  "
        >
          <div className="container">
            <div className="row text-left">
              <div
                className="col-md-7 col-sm-12 col-12 wow fadeInLeft"
                data-wow-duration="1000ms"
                data-wow-delay="300ms"
              >
                <div className="feature-item">
                  {/* <img src={Phone} alt="" style={{width:'300px', height:"555px"}}/> */}
                  <DepartmentCategory
                    style={{ boxShadow: "0px 0px 0px white" }}
                  />
                </div>
              </div>

              <div className="col-xs-11 col-md-5 col-12 col-sm-12 grid flex-column p-5">
                <h3 className="u-text-center-s" style={bold}>
                  Upload your project file in a minutes
                </h3>
                <div className="steps-area">
                  <div className="step__item">
                    <div className="step__item-content">
                      <span style={bold} className="mt-5">
                        {" "}
                        1 &nbsp;
                      </span>
                      <span style={bold}>Create an account</span>
                      <p style={Step}>
                        Sign up for an account with your name, email and phone
                        number.
                      </p>
                    </div>
                  </div>
                  <div className="step__item">
                    <span className="step__item-number"></span>
                    <div className="step__item-content mt-5">
                      <span style={bold} className="mt-5">
                        {" "}
                        2 &nbsp;
                      </span>
                      <span style={bold}>Explore project Document</span>
                      <p style={Step}>
                        Explore thounsand of final year projects documents and
                        download at ease.
                      </p>
                    </div>
                  </div>
                  <div className="step__item">
                    <span className="step__item-number"></span>
                    <div className="step__item-content mt-5">
                      <span style={bold} className="mt-5">
                        {" "}
                        2 &nbsp;
                      </span>
                      <span style={bold}>Add a project Document</span>
                      <p style={Step}>
                        Upload project documention of your work and share it
                        with the community.
                      </p>
                    </div>
                  </div>
                  <div className="step__item">
                    <span className="step__item-number"></span>
                    <div className="step__item-content mt-5">
                      <span style={bold} className="mt-5">
                        {" "}
                        3 &nbsp;{" "}
                      </span>
                      <span style={bold}>Grow Your Earn</span>
                      <p style={Step}>
                        Connect &amp; Earn through your educational paper works.
                      </p>
                    </div>
                  </div>
                </div>
                <Link className="button button--large" to="/signup">
                  Sign up now
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section
          style={{
            backgroundColor: "rgb(2, 43, 105)",
            fontWeight: "bold",
            color: "#fff",
          }}
        >
          <div className="container p-5">
            <div className="row text-center p-2">
              <div className="col-lg-12">
                <h3 className=" text-center p-2">
                  Support Keep ProjectVersity Alive{" "}
                </h3>

                <Link
                  to="/support"
                  className="btn btn-lg  btn-outline-white  "
                  style={{ borderRadius: "10px" }}
                >
                  Donate
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* <Footer/> */}
      </div>
    );
  }
}

export default LandingPage;
