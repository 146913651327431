import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  //   NavLink,
  MDBIcon,
  MDBNavbarBrand,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from "mdbreact";
import { NavLink, Link } from "react-router-dom";
import { AdminData } from "../../components/apidata/api";

class TopNavigation extends Component {
  state = {
    collapse: false,
    id: "",
    name: "",
    admin: [],
  };

  onClick = () => {
    this.setState({
      collapse: !this.state.collapse,
    });
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  async componentWillMount() {
    const id = await JSON.parse(localStorage.getItem("adminuserId"));
    console.log(id);
    this.setState({
      id: id,
    });
    const token = JSON.parse(localStorage.getItem("admintoken"));
    if (token) {
      const admin = await AdminData(id);
      console.log("res " + admin.name);
      if (admin) {
        this.setState({
          name: admin.name,
          admin: admin,
        });
      }
    }
  }
  async handleLogout() {
    await localStorage.removeItem("admintoken");
    await window.localStorage.clear();
  }

  render() {
    return (
      <div>
        <MDBNavbar className="flexible-navbar mt-" light expand="md" scrolling>
          <div href="/">
            <MDBNavbarBrand>ProjectBuk</MDBNavbarBrand>
            <strong>
              {" "}
              <span>Admin LogOn: &nbsp;{this.state.name}</span>{" "}
            </strong>
          </div>
          <MDBNavbarToggler onClick={this.onClick} />
          <MDBCollapse isOpen={this.state.collapse} navbar>
            <MDBNavbarNav left>
              <MDBNavItem>
                <NavLink
                  rel="noopener noreferrer"
                  className=""
                  to="/admin/pendingp"
                >
                  {" "}
                  Request Monetize{" "}
                </NavLink>
              </MDBNavItem>
            </MDBNavbarNav>
            <MDBNavbarNav right>
              <MDBNavItem>
                <NavLink
                  to="/adminsignin"
                  onClick={this.handleLogout.bind(this)}
                  className="btn btn-danger btn-sm"
                >
                  Logout
                </NavLink>
              </MDBNavItem>
            </MDBNavbarNav>
            <div className="" id="mobilenav">
              <Link
                exact={true}
                to="/admindashboard"
                activeClassName="activeClass"
              >
                Dashboard
              </Link>{" "}
              &nbsp;||
              <MDBDropdown>
                <MDBDropdownToggle caret color="primary">
                  <MDBIcon icon="user" className="mr-3 " />
                  User
                </MDBDropdownToggle>
                <MDBDropdownMenu basic>
                  <MDBDropdownItem>
                    <NavLink to="/admin/users" activeClassName="activeClass">
                      Users
                    </NavLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <Link to="/admin/search/user">Search</Link>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
              <MDBDropdown>
                <MDBDropdownToggle caret color="primary">
                  Contactus
                </MDBDropdownToggle>
                <MDBDropdownMenu basic>
                  <MDBDropdownItem>
                    <Link to="/admin/complain" activeClassName="activeClass">
                      Complain
                    </Link>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <Link to="/admin/contactus">Contactus</Link>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
              <MDBDropdown>
                <MDBDropdownToggle caret color="primary">
                  Projects
                </MDBDropdownToggle>
                <MDBDropdownMenu basic>
                  <MDBDropdownItem>
                    {" "}
                    <Link to="/admin/newproject" activeClassName="activeClass">
                      New Projects
                    </Link>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <Link to="/admin/pendingp" activeClassName="activeClass">
                      PendingMonetize
                    </Link>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <Link to="/admin/paidp" activeClassName="activeClass">
                      PaidProject
                    </Link>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <NavLink to="/admin/freep" activeClassName="activeClass">
                      FreeProjects
                    </NavLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    {" "}
                    <Link to="/admin/projects" activeClassName="activeClass">
                      Projects
                    </Link>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <NavLink to="/admin/buyers" activeClassName="activeClass">
                      Project Buyers
                    </NavLink>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
              <MDBDropdown>
                <MDBDropdownToggle caret color="primary">
                  Cashout
                </MDBDropdownToggle>
                <MDBDropdownMenu basic>
                  <MDBDropdownItem>
                    {" "}
                    <Link to="/admin/cashout" activeClassName="activeClass">
                      CashoutRequst
                    </Link>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <Link
                      to="/admin/approvedcashout"
                      activeClassName="activeClass"
                    >
                      ApprovedCashout
                    </Link>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <Link to="/admin/allcashout" activeClassName="activeClass">
                      AllCashout
                    </Link>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
              <MDBDropdown>
                <MDBDropdownToggle caret color="primary">
                  Blog
                </MDBDropdownToggle>
                <MDBDropdownMenu basic>
                  <MDBDropdownItem>
                    <Link to="/admin/createblog">Createpost</Link>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    {" "}
                    <Link to="/admin/blogposts"> AllPost </Link>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>
          </MDBCollapse>
        </MDBNavbar>

        {/* mobile nav */}
      </div>
    );
  }
}

export default TopNavigation;
