import React from "react";
import "./ProcessLoader.css";
export default function processloading() {
  return (
    <div>
     
      <p id="h1">Processing...</p>
    </div>
  );
}
