import React, { Component } from 'react';
import { MDBCol, MDBCard, MDBCardBody, MDBCardHeader, MDBRow, MDBListGroup, MDBListGroupItem, MDBBadge, MDBIcon } from 'mdbreact';
import { Bar, Pie } from 'react-chartjs-2';
import {
    getFreeProject,
    getPaidProject,
    getAllproject,
    allUsers, getAllCashout, getRequestCashout, getApprovedCashout
} from '../../../components/apidata/api'

class ChartSection1 extends Component {
    constructor() {
        super()
        this.state = {
            freeproject: [],
            paidproject: [],
            allproject: [],
            allusers: [],
            allcashout: [],
                cashoutrequest: [],
                cashoutapproved: []
        }
    }

    async componentWillMount() {
        const freeproject = await getFreeProject()

        const paidproject = await getPaidProject()

        const allproject = await getAllproject()

        const allusers = await allUsers()
        const allcashout = await getAllCashout()
        const cashoutrequest = await getRequestCashout()
        const cashoutapproved = await getApprovedCashout()
        this.setState({
            allproject: allproject.length
        })
        this.setState({
            paidproject: paidproject.length
        })
        this.setState({
            freeproject: freeproject.length
        })
        this.setState({
            allusers: allusers.length
        })
        this.setState({
            allcashout: allcashout
        })
        this.setState({
            cashoutrequest: cashoutrequest
        })
        this.setState({
            cashoutapproved: cashoutapproved
        })

    }

    render(){
        const {
            freeproject,
            paidproject,
            allproject,
            allusers,
            allcashout, cashoutrequest, cashoutapproved
        } = this.state
        const dataBar = {
            labels: ['free ,paid project and users',],
            datasets: [
            {
                label: 'freeP',
                data: [freeproject],
                backgroundColor: 'rgba(245, 74, 85, 0.5)',
                borderWidth: 1
            }, {
                label: 'paidP',
                data: [paidproject],
                backgroundColor: 'rgba(90, 173, 246, 0.5)',
                borderWidth: 1
            }, {
                label: 'users',
                data: [allusers],
                backgroundColor: 'rgba(245, 192, 50, 0.5)',
                borderWidth: 1
            }
            ]
        };

        const barChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
            xAxes: [{
                barPercentage: 1,
                gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)'
                }
            }],
            yAxes: [{
                gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)'
                },
                ticks: {
                beginAtZero: true
                }
            }]
            }
        }

        const dataPie = {
            labels: ['free projects', 'paid Projects', ],
            datasets: [
            {
                data: [freeproject, paidproject ],
                backgroundColor: ['#F7464A', '#46BFBD'],
                hoverBackgroundColor: ['#FF5A5E', '#5AD3D1']
            }
            ]
        }
        return (
            <MDBRow className="mb-4">
                <MDBCol md="8"className="mb-4">
                    <MDBCard className="mb-4">
                        <MDBCardBody>
                            <Bar data={dataBar} height={500} options={barChartOptions} />
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol md="4" className="mb-4">
                    <MDBCard className="mb-4">
                        <MDBCardHeader>Pie chart</MDBCardHeader>
                        <MDBCardBody>
                            <Pie data={dataPie} height={300} options={{responsive: true}} />
                        </MDBCardBody>
                    </MDBCard>
                    <MDBCard className="mb-4">
                        <MDBCardBody>
                            <MDBListGroup className="list-group-flush">
                               
                               
                                <MDBListGroupItem>
                                    users
                                    <MDBBadge color="primary-color" pill className="float-right">
                                         {allusers}
                                    </MDBBadge>
                                </MDBListGroupItem>
                                 <MDBListGroupItem>
                                    allproject
                                    <MDBBadge color="primary-color" pill className="float-right">
                                         {
                                             allproject
                                         }
                                    </MDBBadge>
                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                 Paidproject
                                    <MDBBadge color="primary-color" pill className="float-right">
                                         {
                                             paidproject
                                         }
                                    </MDBBadge>
                                </MDBListGroupItem>
                                   <MDBListGroupItem>
                                 Freeproject
                                    <MDBBadge color="primary-color" pill className="float-right">
                                         {
                                             freeproject
                                         }
                                    </MDBBadge>
                                </MDBListGroupItem>
                                 <MDBListGroupItem>
                                    cashoutrequest
                                    <MDBBadge color="primary-color" pill className="float-right">
                                        {cashoutrequest.length}
                                    </MDBBadge>
                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    allcashout
                                    <MDBBadge color="primary-color" pill className="float-right">
                                        {allcashout.length}
                                    </MDBBadge>
                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    approvedcashout
                                    <MDBBadge color="primary-color" pill className="float-right">
                                        {cashoutapproved.length}
                                    </MDBBadge>
                                </MDBListGroupItem>
                            </MDBListGroup>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        )
    }
}

export default ChartSection1;

