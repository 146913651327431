import React from "react";
import { Link } from "react-router-dom";

import Login from "../Signin/Signin";


import "./LandingPageHeadSection.css";
// import VisitorNavbar from "../../Layout/Header/Navbar/VisitorNavbar";

const LandingPageHeadSection = () => {
  return (
    <div>
      {/* <VisitorNavbar/> */}
    <div className="landingHead">
     
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-7 col-12 col-md-12" style={{ marginTop: "5%" }}>
            <section>
              <div className="pSection">
                <h1 className="caption">
                  Open-Source <br />
                  Projects Documents Sharing
                </h1>

                <h3 className="captionword">
                  Create, collaborate & Share Your Projects{" "}
                </h3>
                <div className="row ">


              
                <Link to='/signup' className="col-md-10 col-lg-12 col-10 ">
                <button
                  id="landingregisterbtn"
                      className="btn btn-blue btn-rounded btn-block my-4 waves-effect z-depth-0  mdi mdi-account-check py-4 landingloginbtn "
                 
                  
                 
                >
                  {" "} &nbsp; Create Account 
                 
                </button>
                    
                </Link>

                    <div className="mobile  container row  mt-2"  >
                       
                      <Link to="/signup" className="col-4 text-center " >
                        <button
                          id="landingloginbtn"
                          className="btn btn-sm  mdi mdi-account-check   "
                             style={{backgroundColor:'#022B69', color:'white'}}


                        >
                          {" "} &nbsp;Explore & Contibute

                </button>
                      </Link>
                      {/* <Link to="/signin" className="col-4" >
                        <button
                          id="landingloginbtn"
                          className="btn btn-sm  mdi mdi-account-check   "
                         style={{backgroundColor:'#1b1f5c', color:'white'}}


                        >
                          {" "} &nbsp;Login

                </button>
                      </Link> */}

                    </div >

                    
                </div>
              </div>
            </section>
          </div>
            <div className="col-lg-5 col-md-0 hidelogin" >
            <section id="Login">
              <Login className=""/>
            </section>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default LandingPageHeadSection;
