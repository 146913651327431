import React, { Component } from "react";
// import UsersNavBarHeader from "../../Layout/Header/Navbar/UsersNavBarHeader";
// import VisitorNavbar from '../../Layout/Header/Navbar/VisitorNavbar'
class Faq extends Component {

render() {
const token = localStorage.getItem('token')
    return (<div> 
      {/* {token? <UsersNavBarHeader/>: <VisitorNavbar/>} */}

    <div className="container text-center " style={{marginTop:'80px'}}>
      
      {/*Accordion wrapper*/}
      <div className="accordion md-accordion " id="accordionEx" role="tablist" aria-multiselectable="true">
        {/* Accordion card */}
        <div className="card mt-5">
          {/* Card header */}
          <div className="card-header " role="tab" id="headingOne1">
            <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
              <h5 className="mb-0 ">
                What Is ProjectVersity < i className = "fas fa-angle-down rotate-icon" />
              </h5>
            </a>
          </div>
          {/* Card body */}
          <div id="collapseOne1" className="collapse show" role="tabpanel" aria-labelledby="headingOne1" data-parent="#accordionEx">
            <div className="card-body">
               ProjectVersity is online platform that allows student to access schools project at ease
            </div>
          </div>
        </div>
        {/* Accordion card */}
        {/* Accordion card */}
        <div className="card">
          {/* Card header */}
          <div className="card-header" role="tab" id="headingTwo2">
            <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
              <h5 className="mb-0">
                Who Use ProjectVersity < i className = "fas fa-angle-down rotate-icon" / >
              </h5>
            </a>
          </div>
          {/* Card body */}
          <div id="collapseTwo2" className="collapse" role="tabpanel" aria-labelledby="headingTwo2" data-parent="#accordionEx">
            <div className="card-body">
              ProjectVersity is
              for all Students especially the final years students that are writing ptojects.this platform bring ease way to access project document across Nigeria  Higher Institution
            </div>
          </div>
        </div>
        {/* Accordion card */}
        {/* Accordion card */}
        <div className="card">
          {/* Card header */}
          <div className="card-header" role="tab" id="headingThree3">
            <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree3" aria-expanded="false" aria-controls="collapseThree3">
              <h5 className="mb-0">
               Is the Project in ProjectVersity Free < i className = "fas fa-angle-down rotate-icon" />
              </h5>
            </a>
          </div>
          {/* Card body */}
          <div id="collapseThree3" className="collapse" role="tabpanel" aria-labelledby="headingThree3" data-parent="#accordionEx">
            <div className="card-body">
             Yes most of the project in ProjectVersity is free but User can decide to monetize his / her project to earn also.
            </div>
          </div>
        </div>
        {/* Accordion card */}
      </div>
      {/* Accordion wrapper */}
</div>
</div>
    )
  }
}

export default Faq;