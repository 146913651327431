    import React from 'react'
    // import React, { Component } from 'react'
    
    
    
import {getConfirmMail} from '../../apidata/api'
import {Link} from 'react-router-dom'
import Axios from 'axios'


export default  function  ConfirmMail(props) {
  if (props.match.path === "/confirmation/:email/:token}") {
   let res= getConfirmMail(this.props.match.params.email, this.props.match.params.token)
     

  }
  return (
    <div>
 
    <div className="container ">
       <div className="pt-5 ">

        </div>
      <header className="card text-center ">
             <div className="card-header text-left">
                <a
                            className="navbar-brand " 
                            href="/"
                            style={{ fontSize: "2em",color: "022B69", fontWeight: "bold" ,width:'400px'}}
                        >
                            Projectversity&#x26d6;
          </a>
             </div>
        <h3>

          <strong>Account confirmed!</strong>
             
        </h3>
        <div>

        </div>
         <strong><Link  
         to={"/signin"} style={{color:'green', width:'300'}} className="nav-link "> <button  className="btn btn-primary"  >
        Please Login </button>
      </Link></strong>
      </header>
      
    </div>
  
    </div>
  )
}

// export default class ConfirmMail extends Component {

//     constructor() {
//   super()
//     this.state = {
//      response:'',
//      info:'',
//      email:'',
//      isLoading:false,
//      resendinfo:''
//     };
//     }
//     async componentDidMount(props){
//  console.log(this.props)
//   const confirmMail = await getConfirmMail(this.props.match.params.email, this.props.match.params.token)
//   if(confirmMail){
//       this.setState({response:confirmMail.message, info:confirmMail.info, email:this.props.match.params.email})
    
//   }
//   console.log(confirmMail);
  
//     }
//     async resendLink(e){
//       e.preventDefault()

     
//        try {
//        this.setState({ isLoading: true });
      
//      ; const data={
//         email:this.state.mail
//       }
//        Axios.post(`/resendlink`, data)

//       .then((response) => {
        
//         this.setState({ response: response.data.message, info:response.data.info });
//            this.setState({ isLoading: false });
//            alert(response.data.message);
          
//       })
//       .catch((err) => {
//            this.setState({ isLoading: false });
//         this.setState({ info: err.message });
//         console.log(err);
//       });
//      }
//      catch (error) {
//        this.setState({ isLoading: false });
//        return error.message;
//      }
//   }

    
//     render() {
//         const{response,info}= this.state
//         return (
//           <div className="container text-center" style={{ width: "100%" }}>
//             <div>
//               {" "}
//               <Link
//                 className="navbar-brand mr-1"
//                 to="/"
//                 style={{ fontSize: "2em", color: "022B69", fontWeight: "bold" }}
//               >
//                 ProjectVersity&#x26d6;
//               </Link>
//             </div>

//             <div className=" card text-center mt-2">
//               {info === "login" || "resend" ? (
//                 <div>
//                   <div className=" card-body alert alert-info">{response}</div>
//                   <div className="card-footer right">
//                     <Link className="btn btn-blue  btn-sm " to="/signin">
//                       Login
//                     </Link>{" "}
//                   </div>
//                 </div>
//               ) : null}
//               {info === "resend" ? (
//                 <div>
//                   <div className=" card-body alert alert-info">{response}</div>
//                   <div className="card-footer right">
//                     <button className="btn btn-blue  btn-sm " onClick={this.resendLink.bind(this)}>
//                       Resend{" "}
//                       {this.state.isLoading === true ? (
//                         <span>ing....</span>
//                       ) : null}
//                     </button>{" "}
//                   </div>
//                 </div>
//               ) : null}
//               {info === "signup" ? (
//                 <div>
//                   <div className=" card-body alert alert-info">{response}</div>
//                   <div className="card-footer right">
//                     <Link className="btn btn-blue  btn-sm " to="/signup">
//                       SignUp
//                     </Link>{" "}
//                   </div>
//                 </div>
//               ) : null}
             
//             </div>
//           </div>
//         );
//     }
// }
